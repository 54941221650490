import { Dialog, Slide, Theme, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

interface Props {
	fullScreen?: boolean;
	open: boolean;
	children: ReactNode;
	closeDialog?(): void;
	containerClass?: string;
	style?: React.CSSProperties;
}

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DialogBase = (props: Props): ReactElement => {
	const classes = useStyles();
	const theme = useTheme();
	const isFullscreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog
			style={props.style}
			onClose={props.closeDialog}
			fullScreen={props.fullScreen ? props.fullScreen : isFullscreen}
			open={props.open}
			TransitionComponent={Transition}
			classes={{ paper: clsx(classes.paperContainer, props.containerClass) }}
		>
			{props.children}
		</Dialog>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		paperContainer: {
			[theme.breakpoints.up('md')]: {
				minWidth: 620,
				maxWidth: 620,
				minHeight: 550,
				maxHeight: 550
			}
		}
	};
});

export default DialogBase;
