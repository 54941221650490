import { useQuery } from '@apollo/client';
import { ButtonBase, CardActionArea, IconButton, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiRefresh } from '@mdi/js';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import P3 from '../../../../core/components/typography/p3';
import P4 from '../../../../core/components/typography/p4/p4';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { formatCurrencyValueFromServer } from '../../../../core/utilites/currenty';
import { GetLatestCustomerOrdersDocument } from '../../../../generated/graphql';
import OverviewContainer from '../common/overviewContainer';
import OverviewHeader from '../common/overviewHeader';

interface Props {
	c?: any;
}

const LatestOrders = (props: Props): ReactElement => {
	const classes = useStyles();
	const { data, refetch, loading } = useQuery(GetLatestCustomerOrdersDocument, {
		fetchPolicy: 'network-only'
	});

	return (
		<OverviewContainer>
			<OverviewHeader
				loading={loading}
				refetch={(): any => refetch()}
				title="Latest Orders"
				description="10 most recent orders to hit the system"
			/>

			<div className={classes.results}>
				{data?.order.map((orderEntry) => {
					return (
						<ButtonBase
							key={orderEntry.id}
							className={classes.order}
							component={Link}
							to={`../../teams/${orderEntry.team_store.team.id}/
								${orderEntry.team_store.id}
							/orders`}
						>
							<div>
								<Typography variant="h5">{orderEntry.customer_name}</Typography>
								<P3 className={classes.p3}>
									{orderEntry.team_store.team.name} - {orderEntry.team_store.name}
								</P3>
							</div>
							<div>
								<Typography variant="h4">{formatCurrencyValueFromServer(orderEntry.total || 0)}</Typography>
								<P4 className={classes.p4}>{orderEntry.order_items_aggregate.aggregate?.count} items</P4>
							</div>
						</ButtonBase>
					);
				})}
			</div>
		</OverviewContainer>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		results: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '10px',
			overflowY: 'scroll',
			maxHeight: '500px'
		},
		order: {
			borderRadius: 4,
			padding: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			textAlign: 'left'
		},
		p3: {
			color: SYSTEM_COLORS.GRAY_LIGHT
		},
		p4: {
			textAlign: 'right'
		}
	};
});

export default LatestOrders;
