import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { IconButton, SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiDotsVertical } from '@mdi/js';
import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import AddImageDialog from '../../../../../../../../core/components/connected/store/sections/products/components/addImageDialog';
import { SYSTEM_COLORS } from '../../../../../../../../core/config/colors';
import { dragReorder } from '../../../../../../../../core/utilites/dragReorder';
import {
	GetStoreProductOptionValuesDocument,
	StoreProductHasOptionValueFragment,
	UpdateStoreProductOptionValueImageUrlByPkDocument
} from '../../../../../../../../generated/graphql';
import OptionValue from '../optionValue/optionValue';

interface Props {
	storeProductId: string;
	optionValueId: string;
}

const OptionValueList = (props: Props): ReactElement => {
	const classes = useStyles();
	const [updateProductOption] = useMutation(UpdateStoreProductOptionValueImageUrlByPkDocument);
	const [availableOptionItems, setAvailableOptions] = useState<StoreProductHasOptionValueFragment[]>([]);
	const { data, refetch } = useQuery(GetStoreProductOptionValuesDocument, {
		variables: {
			available_option_id: props.optionValueId,
			store_product_id: props.storeProductId
		}
	});
	const client = useApolloClient();
	useEffect(() => {
		if (data?.store_product_has_options) {
			setAvailableOptions(data?.store_product_has_options);
		}
	}, [data]);

	const handleDragEnd = async (result: any) => {
		const updatedValues = dragReorder<StoreProductHasOptionValueFragment>(
			[...availableOptionItems],
			result.source.index,
			result.destination.index
		);

		let str = '';
		updatedValues.map((u, i) => {
			str += `
			update${i}: update_store_product_has_options_by_pk(pk_columns: {id: "${u.id}"}, _set: {order: ${i}}) {id}`;
		});

		await client.mutate({
			mutation: gql`
				mutation UpdateMultiple {
					${str}
				}
			`
		});

		setAvailableOptions(updatedValues);
	};

	useEffect(() => {
		return () => {
			refetch();
		};
	}, []);

	const updateOptionValue = async (variables: any) => {
		await updateProductOption({
			variables
		});
		refetch();
	};

	return (
		<div className={classes.contentContainer}>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="option-list">
					{(provided): ReactElement => {
						return (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{availableOptionItems.map((ao, i) => {
									return (
										<Draggable key={ao.id} index={i} draggableId={ao.id}>
											{(p) => {
												const elem = (
													<IconButton {...p.dragHandleProps} disableRipple className={classes.dragIcon}>
														<SvgIcon>
															<path d={mdiDotsVertical} />
														</SvgIcon>
													</IconButton>
												);
												return (
													<div {...p.draggableProps} ref={p.innerRef}>
														<OptionValue
															dragElem={elem}
															{...ao}
															key={ao.id}
															updateProductOption={updateOptionValue}
														/>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		contentContainer: {
			marginTop: 15,
			backgroundColor: '#F7F8FF',
			borderRadius: 8
		},
		dragIcon: {
			color: SYSTEM_COLORS.GRAY_LIGHT,
			marginRight: 10
		}
	};
});

export default OptionValueList;
