import { ButtonBase, SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiCamera } from '@mdi/js';
import React, { ReactElement, useState } from 'react';

import AddImageDialog from '../../../core/components/connected/store/sections/products/components/addImageDialog';
import DropImage from '../../../core/components/connected/store/sections/products/components/dropImage';
import P6 from '../../../core/components/typography/p6';

interface Props {
	image_url?: string | null;
	onImageUpdate: (url?: string) => void;
	width?: number | string;
	height?: number | string;
	label?: string;
	style?: any;
}

const imageContainer = ({ image_url, onImageUpdate, width, height, label, style }: Props): ReactElement => {
	const classes = useStyles();
	const [dialogState, setDialogState] = useState(false);

	return (
		<div className={classes.imageContainer} style={{ width: width || 100, height: height || 100, ...style }}>
			{!image_url && (
				<DropImage
					width={width}
					height={height}
					label={label}
					saveUrlOnSuccess={(url: string) => {
						onImageUpdate(url);
					}}
				/>
			)}
			{image_url && (
				<>
					<ButtonBase onClick={(e) => setDialogState(true)} className={classes.addIconButton}>
						{!image_url && (
							<SvgIcon className={classes.addIcon}>
								<path d={mdiCamera} />
							</SvgIcon>
						)}
						{image_url && <img src={image_url} width={width || 100} height={height || 100} />}
					</ButtonBase>
					<ButtonBase
						className={classes.clearImageButton}
						onClick={(): void => {
							onImageUpdate(undefined);
						}}
					>
						<P6>Clear Image</P6>
					</ButtonBase>
				</>
			)}
			<AddImageDialog
				saveUrlOnSuccess={(url: string) => {
					onImageUpdate(url);
					setDialogState(false);
				}}
				open={dialogState}
				handleClose={(): void => {
					setDialogState(false);
				}}
			/>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		imageContainer: {
			margin: 10
		},
		addIconButton: {
			width: '100%',
			height: '100%'
		},
		addIcon: {
			width: 50,
			height: 50
		},
		clearImageButton: {
			display: 'block',
			textAlign: 'center',
			width: '100%'
		}
	};
});

export default imageContainer;
