// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { useMutation } from '@apollo/client';
import { Dialog, Theme, makeStyles } from '@material-ui/core';
import axios from 'axios';
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import React, { ReactElement, useEffect, useState } from 'react';
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

import { CreateImageSignedUrlDocument } from '../../../../../../../../generated/graphql';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import PrimaryButton from '../../../../../../buttons/primaryButton';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface Props {
	open: boolean;
	handleClose(): void;
	saveUrlOnSuccess(url: string): void;
	id?: string;
}

const AddImageDialog = (props: Props): ReactElement => {
	const classes = useStyles();

	const [createSignedURL] = useMutation(CreateImageSignedUrlDocument);
	const [files, setFiles] = useState<any[]>([]);
	useEffect(() => {
		if (!props.open) {
			setFiles([]);
		}
	}, [props.open]);

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader title="Add Image" closeDialog={props.handleClose} />
			<div className={classes.filePondContainer}>
				<FilePond
					className={classes.filePond}
					files={files}
					onupdatefiles={setFiles}
					allowMultiple={false}
					maxFiles={1}
					instantUpload={true}
					server={{
						process: async (fileName, file, metadata, load, error, progress, abort) => {
							// related to aborting the request
							const CancelToken = axios.CancelToken;
							const source = CancelToken.source();

							const data = await createSignedURL({
								variables: {
									fileName: props.id ? `${props.id}/${file.name}` : file.name,
									fileType: file.type
								}
							});

							if (!data.data?.createSignedURL?.success || !data.data?.createSignedURL?.signedRequest) {
								abort();
								return;
							}

							axios({
								method: 'PUT',
								onUploadProgress: (e) => {
									// updating progress indicator
									progress(e.lengthComputable, e.loaded, e.total);
								},
								url: data.data?.createSignedURL?.signedRequest,
								cancelToken: source.token,
								data: file,
								headers: {
									'Content-Type': file.type
								}
							})
								.then((response) => {
									// passing the file id to FilePond
									load(file.name);
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									props.saveUrlOnSuccess(data.data!.createSignedURL!.url!);
								})
								.catch((thrown) => {
									//
								});
							return {
								abort: () => {
									source.cancel('Operation canceled by the user.');
								}
							};
						}
					}}
					name="files"
					labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
				/>
			</div>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		filePondContainer: {
			padding: 25,
			flex: 1
		},
		filePond: {
			flex: 1
		}
	};
});

export default AddImageDialog;
