import { ButtonBase, SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiChevronLeft } from '@mdi/js';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import PrimaryButton from '../../buttons/primaryButton';

interface Props {
	label: string;
	route: string;
}

const Breadcrumb = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<PrimaryButton
				variant="outlined"
				size="small"
				component={Link}
				to={props.route}
				startIcon={
					<SvgIcon>
						<path d={mdiChevronLeft} />
					</SvgIcon>
				}
			>
				{props.label}
			</PrimaryButton>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			marginBottom: 20
		}
	};
});

export default Breadcrumb;
