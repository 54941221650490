import { Avatar, Chip, Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';

// type DeliveryStatus =
// 	| 'Pending'
// 	| 'InfoReceived'
// 	| 'InTransit'
// 	| 'OutForDelivery'
// 	| 'AttemptFail'
// 	| 'Delivered'
// 	| 'AvailableForPickup'
// 	| 'Exception'
// 	| 'Expired';

interface Props {
	aftershipId: string;
	aftershipStatus: string;
}

const getStatus = (status: string) => {
	switch (status) {
		case 'YourSuccessStatus':
		case 'Delivered':
			return 'complete';
		case 'InTransit':
		case 'OutForDelivery':
			return 'pending';
		case 'AttemptFail':
		case 'Exception':
		case 'Expired':
			return 'fail';
		case 'Pending':
		case 'AvailableForPickup':
		case 'InfoReceived':
			return 'unknown';
		default:
			return 'unknown';
	}
};

const getWording = (status: string) => {
	switch (status) {
		case 'InfoReceived':
			return 'Info Received';
		case 'InTransit':
			return 'In Transit';
		case 'YourSuccessStatus':
			return 'Your Success Status';
		case 'OutForDelivery':
			return 'Out For Delivery';
		case 'AttemptFail':
			return 'Attempt Fail';
		default:
			return status;
	}
};

const StatusPill = (props: Props) => {
	const classes = useStyles();

	const statusColor = getStatus(props.aftershipStatus);

	return (
		<a
			href={`https://admin.aftership.com/shipments/${props.aftershipId}`}
			target="_blank"
			rel="noopener noreferrer"
			className={clsx(classes.item, {
				[classes.success]: statusColor === 'complete',
				[classes.unknown]: statusColor === 'unknown',
				[classes.error]: statusColor === 'fail',
				[classes.warning]: statusColor === 'pending'
			})}
			style={{
				textDecoration: 'none',
				display: 'flex',
				alignItems: 'center',
				cursor: 'pointer'
			}}
		>
			<span>{getWording(props.aftershipStatus)}</span>
		</a>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		item: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 4,
			borderRadius: 8,
			width: 160
		},
		warning: {
			backgroundColor: 'rgba(209, 129, 56, .2)',
			'& span': {
				fontWeight: 'bold',
				color: '#D18138'
			},
			'&:hover': {
				backgroundColor: 'rgba(209, 129, 56, .5)'
			}
		},
		success: {
			backgroundColor: 'rgba(0, 128, 0, .2)',
			'& span': {
				fontWeight: 'bold',
				color: '#008000'
			},
			'&:hover': {
				backgroundColor: 'rgba(0, 128, 0, .5)'
			}
		},
		error: {
			backgroundColor: 'rgba(255, 0, 0, .2)',
			'& span': {
				fontWeight: 'bold',
				color: '#FF0000'
			},
			'&:hover': {
				backgroundColor: 'rgba(255, 0, 0, .5)'
			}
		},
		unknown: {
			backgroundColor: 'rgba(128, 128, 128, .2)',
			'& span': {
				fontWeight: 'bold',
				color: '#808080'
			},
			'&:hover': {
				backgroundColor: 'rgba(128, 128, 128, .5)'
			}
		}
	});
});

export default StatusPill;
