import { useMutation } from '@apollo/client';
import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';

import DialogActionBar from '../../../../core/components/blocks/dialogActionBar';
import DialogBase from '../../../../core/components/blocks/dialogBase';
import DialogTitleHeader from '../../../../core/components/blocks/dialogTitleHeader';
import InputField from '../../../../core/components/inputs/inputField';
import { CreateStripeProductDocument } from '../../../../generated/graphql';

interface Props {
	open: boolean;
	handleClose(): void;
}

const DialogCreateStripeProduct = (props: Props): ReactElement => {
	const classes = useStyles();
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [saveOption, { loading }] = useMutation(CreateStripeProductDocument);

	const handleCreate = async () => {
		await saveOption({
			variables: {
				description,
				name
			}
		});
		props.handleClose();
	};

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader closeDialog={props.handleClose} title="Create a Product" />
			<DialogContent>
				<div className={classes.contentContainer}>
					<InputField
						autoFocus
						variant="outlined"
						fullWidth
						label="Product Name"
						onChange={(e): void => {
							setName(e.target.value);
						}}
						value={name}
						helperText="Shows up on the title of the receipt (IE: US Open Merch Tent)"
					/>

					<InputField
						multiline
						autoFocus
						variant="outlined"
						fullWidth
						label="Description"
						onChange={(e): void => {
							setDescription(e.target.value);
						}}
						value={description}
						helperText="Description of the item (IE: Items purchased at the merch tent)"
					/>
				</div>
			</DialogContent>
			<DialogActionBar
				cancelAction={props.handleClose}
				saveLabel="Create"
				saveAction={handleCreate}
				disableSave={name === '' || description === '' || loading}
			/>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		contentContainer: { display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: '30px' }
	};
});

export default DialogCreateStripeProduct;
