import { useMutation, useQuery } from '@apollo/client';
import { DialogContent, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiMagnify } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';

import { InsertShowcaseDocument, SearchForStoresDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import useDebounce from '../../../../../../../hooks/useDebounce';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import PrimaryButton from '../../../../../../buttons/primaryButton';
import InputField from '../../../../../../inputs/inputField';
import P5 from '../../../../../../typography/p5';

interface Props {
	storeId: string;
	refetch(): void;
	open: boolean;
	handleClose(success?: boolean): void;
}

const DialogNewStore = (props: Props): ReactElement => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedValue = useDebounce<string>(searchTerm, 200);
	const { data, refetch } = useQuery(SearchForStoresDocument, {
		variables: {
			_ilike: ''
		}
	});

	const [addShowcase, { loading }] = useMutation(InsertShowcaseDocument);

	useEffect(() => {
		if (debouncedValue.length > 0) {
			refetch({
				_ilike: `%${debouncedValue}%`
			});
		}
	}, [debouncedValue]);

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader
				title="Add Store to Showcase"
				closeDialog={props.handleClose}
				subText="Search for a stores name to add to your showcase list"
			/>
			<DialogContent>
				<div className={classes.searchContainer}>
					<InputField
						disabled={loading}
						onChange={(e) => setSearchTerm(e.target.value)}
						fullWidth
						variant="outlined"
						InputProps={{
							startAdornment: (
								<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
									<path d={mdiMagnify} />
								</SvgIcon>
							)
						}}
						placeholder={`Search`}
						inputProps={{ 'aria-label': 'search' }}
					/>
				</div>
				{debouncedValue !== '' &&
					data?.team_store.map((item) => {
						return (
							<div className={classes.row} key={item.id}>
								<div style={{ flex: 1 }}>
									<Typography variant="body2">{item.name}</Typography>
									<P5>{item.team.name}</P5>
								</div>

								<PrimaryButton
									disabled={loading}
									onClick={async (): Promise<void> => {
										await addShowcase({
											variables: {
												store_owner_id: props.storeId,
												store_showcasing_id: item.id
											}
										});
										props.refetch();
										props.handleClose();
									}}
								>
									Add
								</PrimaryButton>
							</div>
						);
					})}
			</DialogContent>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {},
		row: {
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingTop: 10,
			paddingBottom: 10,
			display: 'flex',
			borderBottom: '1px solid #DFE0EB',
			'&:last-of-type': {
				borderBottom: 'none'
			}
		},
		searchContainer: {
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.GRAYSCALE_GRAY_LIGHTEST,
			padding: '10px 15px'
		},
		price: {
			marginRight: 25
		},
		dialogIcons: {
			color: SYSTEM_COLORS.GRAY
		},
		magnify: {
			marginTop: 2
		}
	};
});

export default DialogNewStore;
