import { useMutation, useQuery } from '@apollo/client';
import { IconButton, Paper, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useMemo, useState } from 'react';

import PaperHeaderContainer from '../../../../core/components/blocks/paperHeaderContainer';
import PrimaryButton from '../../../../core/components/buttons/primaryButton';
import InputField from '../../../../core/components/inputs/inputField';
import P4 from '../../../../core/components/typography/p4/p4';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import {
	AvailableOptionBasicsFragment,
	GetAvailableOptionsDocument,
	InsertProductHasOptionDocument,
	ProductOptionBasicsFragment
} from '../../../../generated/graphql';
import EditOptionDialog from '../editOptionDialog';
import NewOptionDialog from '../newOptionDialog';

interface Props {
	productId: string;
	productOptions?: ProductOptionBasicsFragment[];
	refetchProduct(): void;
}

const AvailableOptions = (props: Props): ReactElement => {
	const classes = useStyles();
	const [dialogState, setDialogState] = useState(false);
	const [editAvailableOption, setEditAvailableOption] = useState<AvailableOptionBasicsFragment | undefined>(undefined);
	const { data, refetch } = useQuery(GetAvailableOptionsDocument);
	const [searchTerm, setSearchTerm] = useState('');

	const [addProductOption, { loading }] = useMutation(InsertProductHasOptionDocument, {
		onCompleted: () => {
			props.refetchProduct();
		}
	});

	const options = data?.available_option || [];

	const handleClose = () => {
		setDialogState(false);
		setEditAvailableOption(undefined);
	};

	const handleSuccessfulClose = (): void => {
		handleClose();
		refetch();
	};

	const usedItems = (props.productOptions || []).map((p) => p.available_option_id);

	const optionsFiltered = useMemo(() => {
		if (searchTerm === '') {
			return options;
		}
		return options.filter((option) => {
			return option.name.toLowerCase().includes(searchTerm.toLowerCase());
		});
	}, [options, searchTerm]);

	return (
		<>
			<Paper className={classes.options}>
				<PaperHeaderContainer>
					<Typography variant="h3">Available Options</Typography>
					<PrimaryButton
						onClick={(): void => {
							setDialogState(true);
						}}
					>
						Add Option
					</PrimaryButton>
				</PaperHeaderContainer>
				<div className={classes.searchContainer}>
					<InputField
						onChange={(e) => setSearchTerm(e.target.value)}
						fullWidth
						variant="outlined"
						InputProps={{
							startAdornment: (
								<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
									<path d={mdiMagnify} />
								</SvgIcon>
							)
						}}
						placeholder={`Search`}
						inputProps={{ 'aria-label': 'search' }}
					/>
				</div>
				{optionsFiltered
					.filter((f) => !usedItems.includes(f.id))
					.map((option) => {
						return (
							<div className={classes.optionContainer} key={option.id}>
								<P4>{option.name}</P4>
								<div className={classes.actions}>
									<IconButton
										size="small"
										disabled={loading}
										onClick={(): void => {
											setEditAvailableOption(option);
										}}
									>
										<SvgIcon className={classes.icon}>
											<path d={mdiPencil} />
										</SvgIcon>
									</IconButton>
									<IconButton
										size="small"
										disabled={loading}
										onClick={(): void => {
											addProductOption({
												variables: {
													available_option_id: option.id,
													product_id: props.productId,
													order: 0
												}
											});
										}}
									>
										<SvgIcon className={classes.icon}>
											<path d={mdiPlus} />
										</SvgIcon>
									</IconButton>
								</div>
							</div>
						);
					})}
			</Paper>

			<NewOptionDialog handleSuccessfulClose={handleSuccessfulClose} open={dialogState} handleClose={handleClose} />
			{editAvailableOption && (
				<EditOptionDialog
					handleSuccessfulClose={handleSuccessfulClose}
					open={true}
					handleClose={(): void => {
						setEditAvailableOption(undefined);
					}}
					availableOption={editAvailableOption}
				/>
			)}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		options: {
			width: '37%'
		},
		optionContainer: {
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: '15px 30px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		actions: {
			'& > :not(:first-child)': {
				marginLeft: 16
			}
		},
		icon: {
			width: 20,
			height: 20
		},
		searchContainer: {
			backgroundColor: SYSTEM_COLORS.GRAYSCALE_GRAY_LIGHTEST,
			padding: '10px 15px'
		},
		price: {
			marginRight: 25
		},
		dialogIcons: {
			color: SYSTEM_COLORS.GRAY
		},
		magnify: {
			marginTop: 2
		}
	};
});

export default AvailableOptions;
