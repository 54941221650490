import { useMutation } from '@apollo/client';
import { ButtonBase, CardActionArea, Collapse, IconButton, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiCamera, mdiClose, mdiPencil } from '@mdi/js';
import React, { ReactElement, useState } from 'react';

import { TeamStorePackageFragment } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import { formatCurrencyValueFromServer } from '../../../../../../../utilites/currenty';
import P4 from '../../../../../../typography/p4/p4';
import P5 from '../../../../../../typography/p5';
import P6 from '../../../../../../typography/p6';
import AddImageDialog from '../../../products/components/addImageDialog';
import DropImage from '../../../products/components/dropImage';

interface Props {
	addPackageImage(id: string, url?: string): void;
	editPackage(pack: TeamStorePackageFragment): void;
	removePackage(packageId: string): void;
	p: TeamStorePackageFragment;
	dragHandle: ReactElement;
}

const Package = (props: Props): ReactElement => {
	const classes = useStyles();
	const [imageDialog, setImageDialog] = useState(false);
	const { p: pack } = props;

	return (
		<>
			<div className={classes.container}>
				{props.dragHandle}
				<div className={classes.imageContainer}>
					{!pack.photo_url && (
						<DropImage
							id={pack.id}
							saveUrlOnSuccess={async (url: string): Promise<void> => {
								props.addPackageImage(pack.id, url);
							}}
						/>
					)}
					{pack.photo_url && (
						<>
							<ButtonBase onClick={(): void => setImageDialog(true)} className={classes.addIconButton}>
								{pack.photo_url && <img src={pack.photo_url} width={100} height={100} />}
							</ButtonBase>
							<ButtonBase
								className={classes.clearImageButton}
								onClick={(): void => {
									props.addPackageImage(pack.id, undefined);
								}}
							>
								<P6>Clear Image</P6>
							</ButtonBase>
						</>
					)}
				</div>
				<div className={classes.contentContainer}>
					<div className={classes.flexMajority}>
						<Typography className={classes.label} variant="body1">
							{pack.label}
						</Typography>
						<P4>{pack.description}</P4>
						<P5 className={classes.p5}>Base: {formatCurrencyValueFromServer(pack.custom_price)}</P5>
					</div>
				</div>
				<div className={classes.actionContainer}>
					<IconButton onClick={(): void => props.editPackage(pack)}>
						<SvgIcon>
							<path d={mdiPencil} />
						</SvgIcon>
					</IconButton>
					<IconButton onClick={(): void => props.removePackage(pack.id)}>
						<SvgIcon>
							<path d={mdiClose} />
						</SvgIcon>
					</IconButton>
				</div>
			</div>

			<AddImageDialog
				key="package"
				saveUrlOnSuccess={(url: string) => {
					props.addPackageImage(pack.id, url);
					setImageDialog(false);
				}}
				open={imageDialog}
				handleClose={(): void => {
					setImageDialog(false);
				}}
			/>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: 10,
			borderRadius: 8,
			padding: 16,
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`
		},
		label: {
			fontWeight: 600
		},
		media: {
			backgroundSize: 'contain',
			width: '100%',
			height: 150
		},
		insertPhoto: {
			display: 'flex',
			alignContent: 'center',
			justifyContent: 'center',
			height: 100,
			flexDirection: 'column'
		},
		imageContainer: {
			marginLeft: 6,
			marginRight: 20
		},
		actionContainer: {
			display: 'flex',
			alignItems: 'center',
			'& > :not(:first-child)': {
				marginLeft: 8
			}
		},
		addIconButton: {
			width: 100,
			height: 100
		},
		addIcon: {
			width: 50,
			height: 50
		},
		p5: {
			color: SYSTEM_COLORS.GRAY_LIGHT
		},
		contentContainer: {
			height: 100,
			flex: 1,
			display: 'flex',
			flexDirection: 'column'
		},
		flexMajority: {
			flex: 1,
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '5px'
		},
		clearImageButton: {
			display: 'block',
			textAlign: 'center',
			width: '100%'
		},
		customizeAction: {
			padding: 10,
			textAlign: 'right'
		}
	};
});

export default Package;
