import { MenuItem, Tab } from '@material-ui/core';

export enum OrderStatus {
	PENDING = 'PENDING',
	PAID = 'PAID',
	IN_DESIGN = 'IN DESIGN',
	IN_PRODUCTION = 'IN PRODUCTION',
	SHIPPED = 'SHIPPED',
	COMPLETE = 'COMPLETE',
	CANCELLED = 'CANCELLED',
	EXPIRED = 'EXPIRED',
	REFUNDED = 'REFUNDED'
}

export const getValidOrderStatuses = (): OrderStatus[] => [
	OrderStatus.PAID,
	OrderStatus.IN_DESIGN,
	OrderStatus.IN_PRODUCTION,
	OrderStatus.SHIPPED,
	OrderStatus.COMPLETE
];

export const formatOrderStatus = (status: OrderStatus): string => {
	switch (status) {
		case OrderStatus.PENDING:
			return 'Pending';
		case OrderStatus.COMPLETE:
			return 'Complete';
		case OrderStatus.CANCELLED:
			return 'Cancelled';
		case OrderStatus.EXPIRED:
			return 'Expired';
		case OrderStatus.REFUNDED:
			return 'Refunded';
		case OrderStatus.PAID:
			return 'Paid';
		case OrderStatus.SHIPPED:
			return 'Shipped';
		case OrderStatus.IN_DESIGN:
			return 'In Design';
		case OrderStatus.IN_PRODUCTION:
			return 'In Production';
		default:
			throw new Error(`Invalid status: ${status}`);
	}
};

export const getOrderStatusByIndex = (orderIndex: number): OrderStatus => {
	const values = Object.values(OrderStatus);
	return values[orderIndex] || null;
};

export const getOrderStatusReadableByIndex = (orderIndex: number): string | null => {
	const values = Object.values(OrderStatus);
	return formatOrderStatus(values[orderIndex] as string as OrderStatus) || null;
};

export function createMenuItemsFromEnum<E extends typeof OrderStatus>(enumObject: E): JSX.Element[] {
	const keys = Object.keys(enumObject);
	const values = Object.values(enumObject);
	const menuItems = keys.map((key, index) => (
		<MenuItem key={key} value={values[index]}>
			{formatOrderStatus(values[index] as string as OrderStatus)}
		</MenuItem>
	));
	return menuItems;
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

export function createTabsFromEnum<E>(enumObject: E, className: string): JSX.Element[] {
	const keys = Object.keys(enumObject);
	const values = Object.values(enumObject);
	const tabs = keys.map((key, index) => (
		<Tab className={className} label={formatOrderStatus(values[index] as string as OrderStatus)} {...a11yProps(index)} />
	));
	return tabs;
}
