import { useMutation } from '@apollo/client';
import { FormControl, Select, TableCell, Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useState } from 'react';

import { UpdateOrderStatusDocument } from '../../../../../../../../../../generated/graphql';
import { OrderStatus, createMenuItemsFromEnum } from '../../../../../../../../../utilites/orderHelpers';

interface Props {
	status: OrderStatus | string;
	orderId: string;
}

const orderStatusMenuItems = createMenuItemsFromEnum(OrderStatus);

const IndicatorStatus = (props: Props): ReactElement => {
	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();
	const [status, setStatus] = useState(props.status);

	const [updateOrderStatus] = useMutation(UpdateOrderStatusDocument);

	useEffect(() => {
		setStatus(props.status);
	}, [props.status]);

	const handleOrderStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
		const prevStatus = status;
		const newOrderStatus = event.target.value as OrderStatus;
		setStatus(newOrderStatus);
		try {
			await updateOrderStatus({
				variables: {
					orderId: props.orderId,
					status: newOrderStatus
				}
			});
			enqueueSnackbar('Successfully changed status', {
				variant: 'success'
			});
		} catch (e) {
			enqueueSnackbar('There was an error with this request, please try again', {
				variant: 'error'
			});
			setStatus(prevStatus);
		}
	};

	return (
		<TableCell className={`${classes.statusCell}`}>
			<FormControl>
				<Select
					className={classes.outlinedSelect}
					variant="outlined"
					value={status}
					onChange={handleOrderStatusChange}
					displayEmpty
					inputProps={{ 'aria-label': 'Status' }}
				>
					{orderStatusMenuItems}
				</Select>
			</FormControl>
		</TableCell>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		outlinedSelect: {
			padding: 0, // adjust this value to reduce the padding size
			'& .MuiSelect-outlined.MuiSelect-outlined': {
				paddingTop: 8,
				paddingLeft: 8,
				paddingBottom: 8,
				minWidth: 160
				// paddingRight: 0 // adjust this value to reduce the right padding size
			}
		},
		statusCell: {
			padding: 4,
			fontWeight: 'bold',
			width: 235
		},
		dynamicSpan: {
			height: 25,
			verticalAlign: '-webkit-baseline-middle',
			display: 'inline-block',
			width: '15px',
			marginRight: theme.spacing(1),
			'&.pending': {
				backgroundColor: '#ffc107'
			},
			'&.complete': {
				backgroundColor: '#4caf50'
			},
			'&.cancelled': {
				backgroundColor: '#f44336'
			},
			'&.expired': {
				backgroundColor: '#9e9e9e'
			},
			'&.refunded': {
				backgroundColor: '#f44336'
			},
			'&.paid': {
				backgroundColor: '#4caf50'
			},
			'&.shipped': {
				backgroundColor: '#4caf50'
			},
			'&.indesign': {
				backgroundColor: '#9e9e9e'
			},
			'&.inproduction': {
				backgroundColor: '#9e9e9e'
			}
		}
	};
});

export default IndicatorStatus;
