import { useMutation } from '@apollo/client';
import { InputAdornment, Switch, Theme, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { ReactElement, useState } from 'react';

import NumberFormatCustom from '../../../../../../core/components/blocks/numberFormatCustom';
import RowToggleWithSwitch from '../../../../../../core/components/blocks/rowToggleWithSwitch';
import PrimaryButton from '../../../../../../core/components/buttons/primaryButton';
import { MarkdownEditor } from '../../../../../../core/components/editor/MarkdownEditor';
import AutoSave from '../../../../../../core/components/inputs/autosave';
import InputField from '../../../../../../core/components/inputs/inputField';
import P6 from '../../../../../../core/components/typography/p6';
import { SYSTEM_COLORS } from '../../../../../../core/config/colors';
import { cleanNumberFormat } from '../../../../../../core/utilites/currenty';
import { TeamStoreProductFragment, UpdateTeamStoreProductByIdDocument } from '../../../../../../generated/graphql';
import DialogAddCustomNameNumber from '../dialogAddCustomNameNumber';

interface Props {
	productId: string;
	dataItem: TeamStoreProductFragment;
}

const Manage = (props: Props): ReactElement => {
	const { dataItem } = props;
	const classes = useStyles();
	const [showCustomNameNumber, setAddCustomNameNumber] = useState(false);

	const [submit] = useMutation(UpdateTeamStoreProductByIdDocument);

	return (
		<>
			<Formik
				initialValues={{
					additional_description: dataItem.additional_description || '',
					label: dataItem.label || '',
					bundle_only: !!dataItem.bundle_only,
					description: dataItem.description || '',
					custom_name_enabled: dataItem.custom_name_enabled,
					custom_number_enabled: dataItem.custom_number_enabled,
					enforce_limit_qty: dataItem.enforce_limit_qty,
					custom_number_price: dataItem.custom_number_price
						? `${(dataItem.custom_number_price / 100).toFixed(2)}`
						: undefined,
					custom_name_price: dataItem.custom_name_price
						? `${(dataItem.custom_name_price / 100).toFixed(2)}`
						: undefined,
					custom_price_override: dataItem.custom_price_override
						? `${(dataItem.custom_price_override / 100).toFixed(2)}`
						: undefined,
					limit_order_qty: dataItem.limit_order_qty
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					const variables = {
						...values,
						custom_number_price: Math.round(parseFloat(cleanNumberFormat(values.custom_number_price || '0')) * 100),
						custom_name_price: Math.round(parseFloat(cleanNumberFormat(values.custom_name_price || '0')) * 100),
						custom_price_override: Math.round(
							parseFloat(cleanNumberFormat(values.custom_price_override || '0')) * 100
						),
						productId: props.productId
					};

					await submit({
						variables
					});
					setSubmitting(false);
				}}
			>
				{({ isSubmitting, values, handleChange, setFieldValue, submitForm }) => {
					return (
						<Form className={classes.formContainer}>
							<AutoSave />
							<div className={classes.inputContainer}>
								<div className={classes.inputSection}>
									<InputField
										name="label"
										value={values.label}
										variant="outlined"
										fullWidth
										label="Product Name"
										onChange={handleChange}
									/>

									<Typography variant="h2">Main Description</Typography>
									<MarkdownEditor
										content={values.description}
										onChange={(md) => setFieldValue('description', md)}
										placeholder={'Product Description'}
									/>

									<Typography variant="h2">Below options Description</Typography>
									<MarkdownEditor
										content={values.additional_description}
										onChange={(md) => setFieldValue('additional_description', md)}
										placeholder={'Additional Product Description'}
									/>

									<div>
										<RowToggleWithSwitch
											className={classes.rowToggle}
											title={'Toggle wether or not this should be available in bundles'}
											handleUpdate={(v) => {
												setFieldValue('bundle_only', v);
											}}
											switchState={values.bundle_only}
											description={
												values.bundle_only ? 'available only in bundles' : 'available in the store'
											}
										/>
									</div>
								</div>
								<div className={classes.gridThree}>
									<div className={classes.switchComponent}>
										<div>
											<Typography variant="h6" className={classes.label}>
												CUSTOM NAME
											</Typography>
											<InputField
												placeholder={'0.00'}
												variant="outlined"
												disabled={!values.custom_name_enabled}
												onChange={(e): void => {
													setFieldValue('custom_name_price', e.target.value);
												}}
												InputProps={{
													inputComponent: NumberFormatCustom,
													startAdornment: (
														<InputAdornment
															position="start"
															className={clsx(
																values.custom_name_enabled ? classes.adornment : null
															)}
														>
															<strong>$</strong>
														</InputAdornment>
													)
												}}
												value={values.custom_name_price}
											/>
										</div>
										<div className={classes.switchAnnotation}>
											<P6>Enable Custom Name</P6>
											<Switch
												size="small"
												name="custom_name_enabled"
												color="primary"
												checked={values.custom_name_enabled}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className={classes.switchComponent}>
										<div>
											<Typography variant="h6" className={classes.label}>
												CUSTOM NUMBER
											</Typography>
											<InputField
												variant="outlined"
												disabled={!values.custom_number_enabled}
												placeholder={'0.00'}
												onChange={(e): void => {
													setFieldValue('custom_number_price', e.target.value);
												}}
												InputProps={{
													inputComponent: NumberFormatCustom,
													startAdornment: (
														<InputAdornment
															position="start"
															className={clsx(
																values.custom_number_enabled ? classes.adornment : null
															)}
														>
															<strong>$</strong>
														</InputAdornment>
													)
												}}
												value={values.custom_number_price}
											/>
										</div>
										<div className={classes.switchAnnotation}>
											<P6>Enable Custom Number</P6>
											<Switch
												size="small"
												color="primary"
												name="custom_number_enabled"
												checked={values.custom_number_enabled}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className={classes.activeContainer}>
										<Typography variant="h6" className={classes.label}>
											CUSTOM PRICE
										</Typography>
										<InputField
											variant="outlined"
											placeholder={'0.00'}
											onChange={(e): void => {
												setFieldValue('custom_price_override', e.target.value);
											}}
											InputProps={{
												inputComponent: NumberFormatCustom,
												startAdornment: (
													<InputAdornment position="start" className={classes.adornment}>
														<strong>$</strong>
													</InputAdornment>
												)
											}}
											value={values.custom_price_override}
										/>
									</div>

									<div className={classes.activeContainer}>
										<Typography variant="h6" className={classes.label}>
											QTY LEFT
										</Typography>
										<InputField
											disabled={!values.enforce_limit_qty}
											variant="outlined"
											onChange={(e): void => {
												if (parseInt(e.target.value, 10) > 0) {
													setFieldValue('limit_order_qty', e.target.value);
												} else {
													setFieldValue('limit_order_qty', 0);
												}
											}}
											value={values.limit_order_qty}
										/>
										<div className={classes.switchAnnotation}>
											<P6>Enforce Limit on Item</P6>
											<Switch
												size="small"
												color="primary"
												name="enforce_limit_qty"
												checked={values.enforce_limit_qty}
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
								<PrimaryButton
									onClick={() => {
										setAddCustomNameNumber(true);
									}}
								>
									Add Custom Name Number Options{' '}
								</PrimaryButton>
							</div>
						</Form>
					);
				}}
			</Formik>
			<DialogAddCustomNameNumber
				handleClose={(): void => {
					setAddCustomNameNumber(false);
				}}
				open={showCustomNameNumber}
				id={props.productId}
			/>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		formContainer: {
			backgroundColor: '#FFF'
		},
		label: {
			color: SYSTEM_COLORS.GRAY_LIGHT,
			marginBottom: 5
		},
		inputContainer: {
			padding: 15
		},
		gridThree: {
			marginTop: 35,
			display: 'grid',
			gridTemplateColumns: 'repeat(4, 1fr)',
			gap: '15px'
		},
		adornment: {
			color: SYSTEM_COLORS.PRIMARY
		},
		textContainer: {},
		withSwitch: {
			textAlign: 'center'
		},
		switchComponent: {
			textAlign: 'right'
		},
		activeContainer: {
			textAlign: 'right'
		},
		switchAnnotation: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'right'
		},
		inputSection: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '35px'
		},
		actionStrip: {
			flex: 1,
			display: 'flex',
			justifyContent: 'flex-end'
		},
		rowToggle: {
			border: 'none',
			padding: '13px 15px !important',
			'&:hover': {
				borderRadius: 8
			}
		},
		buttonViewSource: {
			padding: 5
		}
	};
});

export default Manage;
