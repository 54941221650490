import { useQuery } from '@apollo/client';
import React, { ReactElement, ReactNode, createContext } from 'react';

import { GetTeamStoreDocument, TeamStoreBasicsFragment } from '../../generated/graphql';

interface StoreContext {
	store: TeamStoreBasicsFragment;
	refetch(): void;
}

export const StoreContext = createContext<StoreContext | undefined>(undefined);

interface Props {
	storeId: string;
	children: ReactNode;
}

export const StoreProvider = (props: Props): ReactElement => {
	const { data, loading, refetch } = useQuery(GetTeamStoreDocument, {
		variables: {
			id: props.storeId
		}
	});

	if (loading || !data?.team_store_by_pk) {
		return <section key={props.storeId}>Loading</section>;
	}

	return (
		<StoreContext.Provider
			value={{
				refetch,
				store: data.team_store_by_pk
			}}
		>
			{props.children}
		</StoreContext.Provider>
	);
};

export const useStoreContext = (): StoreContext => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return React.useContext(StoreContext)!;
};

export default StoreProvider;
