import { gql, useApolloClient } from '@apollo/client';

export const dragReorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return [...result];
};

export const useDragReorderEnd = <T>(updateKey: string): ((result: any, options: T[]) => T[]) => {
	const client = useApolloClient();
	const reorderSave = (result: any, options: T[]) => {
		const updatedValues = dragReorder<T>([...options], result.source.index, result.destination.index);

		let str = '';
		updatedValues.map((u: any, i) => {
			str += `
			update${i}: ${updateKey}(pk_columns: {id: "${u.id}"}, _set: {order: ${i}}) {id}`;
		});
		client.mutate({
			mutation: gql`
				mutation UpdateMultiple {
					${str}
				}
			`
		});

		return updatedValues;
	};
	return reorderSave;
};
