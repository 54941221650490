import { useMutation } from '@apollo/client';
import { Avatar, Collapse, DialogContent, Grid, SvgIcon, TextField, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiPrinterPosCheckOutline } from '@mdi/js';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, ReactElement, useState } from 'react';

import { CreateNewShippingLabelDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../../../../../../config/colors';
import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import PrimaryButton from '../../../../../../buttons/primaryButton';
import P3 from '../../../../../../typography/p3';

interface Props {
	orderId: string;
	handleClose(): void;
	open: boolean;
}

interface Address {
	name: string;
	street1: string;
	street2?: string;
	city: string;
	state: string;
	zip: string;
}

const DialogGenShippingLabel = (props: Props): ReactElement => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [manualAddress, setManualAddress] = useState(false);
	const [address, setAddress] = useState<Address>({
		name: '',
		street1: '',
		street2: '',
		city: '',
		state: '',
		zip: ''
	});

	const [generateShippingLabel, { loading }] = useMutation(CreateNewShippingLabelDocument, {
		variables: {
			orderId: props.orderId
		}
	});

	const handleSave = async () => {
		if (loading) {
			return;
		}
		const data = await generateShippingLabel({
			variables: {
				orderId: props.orderId,
				overrideAddress: manualAddress,
				...(manualAddress && address)
			}
		});
		if (data.data?.createNewShippingLabel?.shippingLabelUrl) {
			enqueueSnackbar('Successfully Generated a new Label', {
				variant: 'success'
			});
			window.open(data.data?.createNewShippingLabel?.shippingLabelUrl, 'open');
		} else if (data.data?.createNewShippingLabel?.error) {
			enqueueSnackbar(data.data?.createNewShippingLabel?.error, {
				variant: 'warning'
			});
		}
		props.handleClose();
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setAddress((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	return (
		<DialogBase open={props.open}>
			<DialogTitleHeader closeDialog={props.handleClose} title={manualAddress ? 'Address Override' : 'Are you sure?'} />
			<DialogContent className={classes.dialogContent}>
				<Avatar className={classes.avatar}>
					<SvgIcon>
						<path d={mdiPrinterPosCheckOutline} />
					</SvgIcon>
				</Avatar>
				<Collapse in={manualAddress === false}>
					<Typography variant="h4">Generate Shipping Label?</Typography>
					<P3 className={classes.p3}>
						Are you sure you want to generate a shipping label for this order? This means that, if successful,
						ShipStation will bill your account and generate a new shipping label. The previous shipping label will no
						longer be in the system.
					</P3>
					<PrimaryButton onClick={(): void => setManualAddress(true)}>Manual Address</PrimaryButton>
				</Collapse>
				<Collapse in={manualAddress === true}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								required
								id="name"
								name="name"
								label="Name"
								fullWidth
								value={address.name}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								required
								id="street1"
								name="street1"
								label="Street"
								fullWidth
								value={address.street1}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								required
								id="street2"
								name="street2"
								label="Street 2"
								fullWidth
								value={address.street2}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								required
								id="city"
								name="city"
								label="City"
								fullWidth
								value={address.city}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								required
								id="state"
								name="state"
								label="State"
								fullWidth
								value={address.state}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								required
								id="zip"
								name="zip"
								label="Zip Code"
								fullWidth
								value={address.zip}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
				</Collapse>
			</DialogContent>
			<DialogActionBar
				cancelLabel="Cancel"
				cancelAction={props.handleClose}
				disableSave={loading}
				saveAction={handleSave}
				saveLabel="Yes, Generate It"
			/>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		avatar: {
			width: 52,
			height: 52,
			backgroundColor: getRGBAFromHex(SYSTEM_COLORS.GREEN_DEFAULT, 0.15),
			'& svg': {
				width: 52,
				height: 52,
				color: SYSTEM_COLORS.GREEN_DEFAULT
			},
			marginBottom: 25
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			maxWidth: '500px',
			marginLeft: 'auto',
			marginRight: 'auto',
			justifyContent: 'center',
			textAlign: 'center'
		},
		p3: {
			paddingTop: 15
		}
	};
});

export default DialogGenShippingLabel;
