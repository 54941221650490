import { Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../../../../../../../config/colors';
import { Package, Product } from '../../../../../../../../../hooks/useFormatServerOrder';
import P3 from '../../../../../../../../typography/p3';
import LineItem from '../lineItem';

interface Props {
	handleEdit?(edit: HandleOrderOption): void;
	detailClassName?: string;
	orderItem: Package | Product;
}

interface CustomNameNumber {
	orderItemId: string;
	type: 'custom_name' | 'custom_number';
	currentValue: string;
}

interface CustomOption {
	type: 'option';
	storeProductId: string;
	availableOptionId: string;
	selectedAvailableOptionId: string;
	cartOptionId: string;
}

export type HandleOrderOption = CustomNameNumber | CustomOption;

const Detail = (props: Props): ReactElement | null => {
	const classes = useStyles();
	const { orderItem } = props;

	if (!orderItem.type) {
		return null;
	}
	if (orderItem.type === 'package') {
		const optDesc = orderItem.products.map((product, i) => {
			const { custom_name, custom_number, ...rest } = product.options;
			const desc = Object.values(rest).map((item) => {
				return (
					<LineItem
						handleEdit={
							props.handleEdit
								? (): void =>
										// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
										props.handleEdit!({
											cartOptionId: item.id,
											selectedAvailableOptionId: item.availableOptionValueId,
											availableOptionId: item.availableOptionId,
											storeProductId: product.store_product_id,
											type: 'option'
										})
								: undefined
						}
						value={item.value ? item.value : ' '}
						label={item.label}
						className={classes.itemDetail}
						key={item.availableOptionId}
					/>
				);
			});

			desc.push(
				<LineItem
					handleEdit={
						props.handleEdit
							? (): void =>
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									props.handleEdit!({
										currentValue: product.custom_name ? product.custom_name : ' ',
										orderItemId: product.id,
										type: 'custom_name'
									})
							: undefined
					}
					withLabel
					value={product.custom_name ? product.custom_name : ' '}
					label={'Custom Name'}
					className={classes.itemDetail}
					key={`custom-name-${i}`}
				/>
			);
			desc.push(
				<LineItem
					handleEdit={
						props.handleEdit
							? (): void =>
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									props.handleEdit!({
										currentValue: product.custom_number ? product.custom_number : ' ',
										orderItemId: product.id,
										type: 'custom_number'
									})
							: undefined
					}
					withLabel
					value={product.custom_number ? product.custom_number : ' '}
					label={'Custom Number'}
					className={classes.itemDetail}
					key={`custom-number-${i}`}
				/>
			);

			return (
				<div className={classes.packageProduct} key={`pack_${product.id}`}>
					<P3 className={classes.label}>
						<strong>{product.label}</strong>
					</P3>
					<div className={props.detailClassName}>{<>{desc.filter((a) => a)}</>}</div>
				</div>
			);
		});
		return <>{optDesc.filter((a) => a)}</>;
	} else if (orderItem.type === 'product') {
		const { custom_name, custom_number, ...rest } = orderItem.options;
		const desc = Object.values(rest).map((item) => {
			return (
				<LineItem
					handleEdit={
						props.handleEdit
							? (): void =>
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									props.handleEdit!({
										cartOptionId: item.id,
										selectedAvailableOptionId: item.availableOptionValueId,
										availableOptionId: item.availableOptionId,
										storeProductId: orderItem.store_product_id,
										type: 'option'
									})
							: undefined
					}
					value={item.value ? item.value : ' '}
					label={item.label}
					className={classes.itemDetail}
					key={item.availableOptionId}
				/>
			);
		});

		desc.push(
			<LineItem
				handleEdit={
					props.handleEdit
						? (): void =>
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								props.handleEdit!({
									currentValue: orderItem.custom_name ? orderItem.custom_name : ' ',
									orderItemId: orderItem.id,
									type: 'custom_name'
								})
						: undefined
				}
				withLabel
				value={orderItem.custom_name ? orderItem.custom_name : ' '}
				label={'Custom Name'}
				className={classes.itemDetail}
				key={`custom-name-${orderItem.id}`}
			/>
		);
		desc.push(
			<LineItem
				handleEdit={
					props.handleEdit
						? (): void =>
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								props.handleEdit!({
									currentValue: orderItem.custom_number ? orderItem.custom_number : ' ',
									orderItemId: orderItem.id,
									type: 'custom_number'
								})
						: undefined
				}
				withLabel
				value={orderItem.custom_number ? orderItem.custom_number : ' '}
				label={'Custom Number'}
				className={classes.itemDetail}
				key={`custom-number-${orderItem.id}`}
			/>
		);

		return <div className={props.detailClassName}>{desc.filter((a) => a)}</div>;
	}

	return null;
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		label: {
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			marginBottom: 10
		},
		packageProduct: {
			padding: '10px 0'
		},
		itemDetail: {
			marginRight: 5
		}
	};
});

export default Detail;
