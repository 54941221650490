import { useMutation, useQuery } from '@apollo/client';
import { Chip, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiDotsHorizontal } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import {
	CreateNewReceiptForOrderDocument,
	GetTeamStoreOrderByIdDocument,
	TeamStoreOrderFragment
} from '../../../../../../generated/graphql';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../../../../config/colors';
import useFormatServerOrder from '../../../../../hooks/useFormatServerOrder';
import { formatCurrencyValueFromServer } from '../../../../../utilites/currenty';
import { OrderStatus, getValidOrderStatuses } from '../../../../../utilites/orderHelpers';
import ButtonMenu, { MenuItemSpec } from '../../../../blocks/buttonMenu/buttonMenu';
import PrimaryButton from '../../../../buttons/primaryButton';
import P3 from '../../../../typography/p3';
import EditCustomNameDialog from '../orders/components/orderTable/components/editCustomNameDialog';
import EditCustomNumberDialog from '../orders/components/orderTable/components/editCustomNumberDialog';
import EditCustomOptionDialog from '../orders/components/orderTable/components/editCustomOptionDialog';
import Detail, { HandleOrderOption } from '../orders/components/orderTable/components/orderDetailsDialog/details';
import DialogAdjustOrderTotal from './components/dialogAdjustOrderTotal';
import DialogCancelOrder from './components/dialogCancelOrder';
import DialogCancelOrderItem from './components/dialogCancelOrderItem';
import DialogGenShippingLabel from './components/dialogGenShippingLabel';

interface Props {
	orderId: string;
	// refetchMetrics(): void;
	refetchStore(): void;
}

const ViewOrderRouter = (props: Props): ReactElement | null => {
	const { data, refetch } = useQuery(GetTeamStoreOrderByIdDocument, {
		variables: {
			orderId: props.orderId
		}
	});

	if (!data?.order_by_pk) {
		return null;
	}

	return (
		<ViewOrder
			// refetchMetrics={props.refetchMetrics}
			refetchStore={props.refetchStore}
			refetch={refetch}
			teamStoreOrder={data.order_by_pk}
		/>
	);
};

interface OrderProps {
	// refetchMetrics(): void;
	refetchStore(): void;
	refetch(): void;
	teamStoreOrder: TeamStoreOrderFragment;
}

interface RemoveItemState {
	id: string;
	label: string;
	type: 'product' | 'package';
	orderId: string;
}

const validOrderStatus = getValidOrderStatuses();

const ViewOrder = (props: OrderProps): ReactElement => {
	const classes = useStyles();
	const formattedTeamStoreOrders = useFormatServerOrder(props.teamStoreOrder);
	const [customEdit, setCustomEdit] = useState<HandleOrderOption | undefined>();
	const [cancelOrder, setCancelOrder] = useState(false);
	const [shippingLabelToGenerate, setGenShippingLabel] = useState(false);
	const [adjustOrderTotal, setAdjustOrderTotal] = useState<undefined | string>();
	const [removeOrderItem, setRemoveOrderItem] = useState<undefined | RemoveItemState>();
	const [generateShippingLabel, { loading }] = useMutation(CreateNewReceiptForOrderDocument, {
		variables: {
			orderId: props.teamStoreOrder.id
		}
	});

	useEffect(() => {
		if (!customEdit) {
			props.refetch();
		}
	}, [customEdit, loading]);

	const menuSpec: MenuItemSpec[] = useMemo(() => {
		return [
			{
				key: '1',
				label: 'Adjust Order',
				onClick: () => {
					setAdjustOrderTotal(`${props.teamStoreOrder.total ? props.teamStoreOrder.total / 100 : 0}`);
				}
			},
			{
				key: '2',
				label: 'Cancel Order',
				onClick: () => {
					setCancelOrder(true);
				}
			},
			props.teamStoreOrder.shipping_label_url !== null && {
				key: '3',
				label: 'View Shipping Label',
				onClick: (): void => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					window.open(props.teamStoreOrder!.shipping_label_url!, '_blank', 'noopener noreferrer nofollow');
				}
			},
			{
				key: '4',
				label: 'Generate Shipping Label',
				onClick: (): void => {
					setGenShippingLabel(true);
				}
			}
		].filter(Boolean);
	}, [props.teamStoreOrder]);

	const successfulOrderStatus = validOrderStatus.includes(props.teamStoreOrder.status as OrderStatus);

	return (
		<>
			<div className={classes.orderContainer}>
				<div className={classes.profile}>
					<div>
						<Typography variant="h2">{props.teamStoreOrder.customer_name}</Typography>
						<Typography variant="h6" className={classes.gray}>
							{props.teamStoreOrder.customer_email}
						</Typography>
					</div>
					<div style={{ textAlign: 'right' }}>
						{props.teamStoreOrder.total && (
							<Typography variant="h2">{formatCurrencyValueFromServer(props.teamStoreOrder.total)}</Typography>
						)}
						{props.teamStoreOrder.original_order_price &&
							props.teamStoreOrder.original_order_price !== props.teamStoreOrder.total && (
								<Typography variant="h6" className={clsx(classes.gray, classes.crossThrough)}>
									{formatCurrencyValueFromServer(props.teamStoreOrder.original_order_price)}
								</Typography>
							)}
						Purchased by {props.teamStoreOrder.checkout_type === 'card' ? 'Card' : 'Check'}
					</div>
				</div>
				<div className={classes.orderEdit}>
					<Chip
						className={successfulOrderStatus ? undefined : classes.chipNonSuccess}
						color={successfulOrderStatus ? 'primary' : 'secondary'}
						label={props.teamStoreOrder.status}
					/>

					<div className={classes.buttonContainer}>
						<ButtonMenu iconPath={mdiDotsHorizontal} menuSpec={menuSpec} />
					</div>
				</div>
				<div className={classes.orderContents}>
					{formattedTeamStoreOrders.map((orderItem, i) => {
						return (
							<div className={classes.cartItem} key={`${orderItem.id}-${i}`}>
								{orderItem.image && (
									<div className={classes.imageContainer}>
										<img src={orderItem.image} alt={orderItem.label} />
									</div>
								)}
								<div className={classes.productDetails}>
									<div className={classes.product}>
										<div style={{ flex: 1 }}>
											<Typography variant="h3">{orderItem.label}</Typography>
											<div className={classes.options}>
												<P3 component={'div'}>
													<Detail
														handleEdit={setCustomEdit}
														detailClassName={classes.detailClassName}
														orderItem={orderItem}
													/>
												</P3>
											</div>
										</div>
										<div className={classes.priceContainer}>
											{orderItem.orderFor && <Typography variant="h6">{orderItem.orderFor}</Typography>}
											<Typography variant="h3" className={classes.price}>
												{formatCurrencyValueFromServer(orderItem.price)} - Qty{' '}
												{orderItem.type === 'product' ? orderItem.quantity : '1'}
											</Typography>

											{formattedTeamStoreOrders.length > 1 && (
												<PrimaryButton
													onClick={() => {
														setRemoveOrderItem({
															id: orderItem.id,
															type: orderItem.type,
															orderId: props.teamStoreOrder.id,
															label: orderItem.label
														});
													}}
												>
													Cancel Item
												</PrimaryButton>
											)}
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{customEdit && customEdit.type === 'custom_number' && (
				<EditCustomNumberDialog
					handleClose={async (isSaved): Promise<void> => {
						if (isSaved) {
							await generateShippingLabel();
						}
						setCustomEdit(undefined);
					}}
					open={true}
					orderItemId={customEdit.orderItemId}
					incomingValue={customEdit.currentValue}
				/>
			)}
			{customEdit && customEdit.type === 'custom_name' && (
				<EditCustomNameDialog
					handleClose={async (isSaved): Promise<void> => {
						if (isSaved) {
							await generateShippingLabel();
						}
						setCustomEdit(undefined);
					}}
					open={true}
					orderItemId={customEdit.orderItemId}
					incomingValue={customEdit.currentValue}
				/>
			)}
			{customEdit && customEdit.type === 'option' && (
				<EditCustomOptionDialog
					handleClose={async (isSaved): Promise<void> => {
						if (isSaved) {
							await generateShippingLabel();
						}
						setCustomEdit(undefined);
					}}
					open={true}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					{...customEdit}
				/>
			)}
			<DialogCancelOrder
				orderId={props.teamStoreOrder.id}
				open={cancelOrder}
				handleClose={(): void => {
					setCancelOrder(false);
					props.refetchStore();
					// props.refetchMetrics();
				}}
			/>
			{adjustOrderTotal && (
				<DialogAdjustOrderTotal
					open={true}
					orderId={props.teamStoreOrder.id}
					price={adjustOrderTotal}
					handleClose={(): void => {
						setAdjustOrderTotal(undefined);
						props.refetchStore();
						// props.refetchMetrics();
					}}
				/>
			)}
			{shippingLabelToGenerate && (
				<DialogGenShippingLabel
					open={true}
					orderId={props.teamStoreOrder.id}
					handleClose={(): void => {
						setGenShippingLabel(false);
						props.refetchStore();
					}}
				/>
			)}
			{removeOrderItem && (
				<DialogCancelOrderItem
					orderId={removeOrderItem.orderId}
					orderItemType={removeOrderItem.type}
					handleClose={(): void => {
						setRemoveOrderItem(undefined);
						props.refetchStore();
						// props.refetchMetrics();
					}}
					open={true}
					orderItemId={removeOrderItem.id}
					orderItemLabel={removeOrderItem.label}
				/>
			)}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		orderContainer: {
			background: SYSTEM_COLORS.BACKGROUND,
			borderRadius: 8,
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`
		},
		profile: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			backgroundColor: getRGBAFromHex(SYSTEM_COLORS.SECONDARY, 0.5),
			padding: 15,
			borderTopLeftRadius: 8,
			borderTopRightRadius: 8
		},
		orderContents: {
			borderRadius: 4,
			padding: 15,
			background: SYSTEM_COLORS.WHITE,
			margin: 15,
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '5px'
		},
		orders: {
			flex: 1
		},
		imageContainer: {
			height: 200,
			width: 172,
			padding: 10,
			border: '1px solid rgb(242,242,245)',
			borderRadius: 8,
			'& img': {
				width: 150,
				borderRadius: 8
			}
		},
		product: {
			display: 'flex',
			justifyContent: 'space-between',
			[theme.breakpoints.down('xs')]: {
				display: 'block'
			}
		},

		cartItem: {
			alignItems: 'flex-start',
			display: 'flex',
			padding: '15px 0',
			borderBottom: `1px solid ${getRGBAFromHex(SYSTEM_COLORS.PRIMARY, 0.2)}`,
			'&:last-of-type': {
				borderBottom: 'none'
			}
		},
		price: {
			fontSize: 16,
			marginBottom: 10
		},
		noItems: {
			textAlign: 'center',
			width: '100%',
			marginTop: 50
		},

		options: {
			paddingTop: 15,
			flex: 1
		},
		detailClassName: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: 5
		},
		productDetails: {
			flex: 1,
			paddingLeft: 15,
			paddingRight: 15,
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '10px'
		},
		buttonContainer: {
			'& > :not(:first-child)': {
				marginLeft: 15
			}
		},
		gray: {
			color: SYSTEM_COLORS.GRAY
		},
		orderEdit: {
			backgroundColor: SYSTEM_COLORS.BACKGROUND,
			padding: '10px 15px',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			'& > :not(:first-child)': {
				marginLeft: 8
			}
		},
		chipNonSuccess: {
			backgroundColor: SYSTEM_COLORS.RED_DEFAULT,
			'& span': {
				color: '#fff'
			}
		},
		crossThrough: {
			textAlign: 'right',
			textDecoration: 'line-through'
		},
		priceContainer: {
			textAlign: 'right'
		}
	};
});

export default ViewOrderRouter;
