import { ApolloProvider } from '@apollo/client';
import React, { ReactElement, ReactNode, useMemo } from 'react';

import { useAuth } from '../../providers/authProvider/authProvider';
import { createApolloClient } from './helper';

interface Props {
	children: ReactNode;
}

const ApolloWrapper = (props: Props): ReactElement => {
	const { token } = useAuth();
	const apolloClient = createApolloClient();
	return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};

export default ApolloWrapper;
