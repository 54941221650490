import { Theme, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ElementType, ReactElement, ReactNode } from 'react';

import { getRemFromPx } from '../../../config/theme';

interface Props {
	className?: string;
	children: ReactNode;
	component?: ElementType;
}

const P3 = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<Typography
			component={props.component ? props.component : 'p'}
			className={clsx(classes.p3, props.className ? props.className : null)}
		>
			{props.children}
		</Typography>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		p3: {
			textShadow: 'none',
			fontSize: getRemFromPx(13),
			lineHeight: getRemFromPx(19),
			[theme.breakpoints.up('md')]: {
				fontSize: getRemFromPx(12),
				lineHeight: getRemFromPx(18)
			}
		}
	};
});

export default P3;
