import { CardActionArea, Theme, Typography, makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { SYSTEM_COLORS } from '../../../config/colors';
import ButtonBase from '../../buttons/buttonBase';
import P3 from '../../typography/p3';
import P6 from '../../typography/p6';

interface Props {
	label: string;
	subLabel?: string | null;
	updatedAt?: number | null;
	children?: ReactNode;
	startEl?: ReactNode;
	endEl?: ReactNode;
	to?: string;
}

const RowBlock = (props: Props): ReactElement => {
	const classes = useStyles();
	const { label, subLabel, updatedAt, children, startEl, endEl, ...rest } = props;

	const Component = props.to ? Link : 'div';

	return (
		<div className={classes.rowBlockContainer}>
			{startEl}
			<ButtonBase component={props.to ? Link : undefined} className={classes.mainContent} {...rest}>
				<Typography variant="h5">{label}</Typography>
				{subLabel && <P3 className={classes.p3}>{subLabel}</P3>}
				{updatedAt && <P6>{dayjs(parseInt(`${updatedAt}000`)).format('MMM DD, YYYY')}</P6>}
			</ButtonBase>
			{endEl}
			{children && <div className={classes.rightContent}>{children}</div>}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		p3: {
			color: SYSTEM_COLORS.GRAY_LIGHT
		},
		rowBlockContainer: {
			borderRadius: 4,
			backgroundColor: SYSTEM_COLORS.WHITE,
			padding: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			textAlign: 'left',
			[theme.breakpoints.down('sm')]: {
				display: 'block'
			}
		},
		rightContent: {
			textAlign: 'right',
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 2fr)',
			gap: '15px',
			[theme.breakpoints.down('sm')]: {
				marginTop: 15,
				gap: '5px',
				gridTemplateColumns: 'repeat(1, 1fr)'
			}
		},
		mainContent: {
			display: 'block',
			flex: 1,
			textDecoration: 'none',
			color: 'inherit'
		}
	};
});

export default RowBlock;
