import { Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'timeago.js';

import TableComponent from '../../../../core/components/blocks/tableComponent';
import PrimaryButton from '../../../../core/components/buttons/primaryButton';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { TeamBasicsFragment } from '../../../../generated/graphql';
import DialogEditTeam from '../dialogEditTeam';

interface Props {
	refetchTeams(): void;
	teamList: TeamBasicsFragment[];
}

const parseLocationData = (city?: string, state?: string) => {
	if (city && state) {
		return `${city}, ${state}`;
	} else if (city) {
		return city;
	} else if (state) {
		return state;
	}
	return 'No location set';
};

const TeamTable = (props: Props): ReactElement => {
	const classes = useStyles();
	const [team, setTeam] = useState<TeamBasicsFragment | undefined>(undefined);

	const columns: any = useMemo(() => {
		return [
			{
				id: 'name',
				Header: 'Team',
				accessor: 'name',
				className: classes.teamName,
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return (
						<>
							<Typography
								className={classes.link}
								variant="body2"
								style={{ paddingBottom: 8 }}
								component={Link}
								to={`${row.id}`}
							>
								{row.name}
							</Typography>
							<Typography variant="h6">Updated {format(row.updated_at)}</Typography>
						</>
					);
				}
			},
			{
				id: 'location',
				Header: 'Location',
				className: classes.location,
				accessor: 'city',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return (
						<Typography variant="body2" style={{ paddingBottom: 8 }}>
							{parseLocationData(row.city, row.state)}
						</Typography>
					);
				}
			},
			{
				id: 'activity',
				Header: 'Activity',
				className: classes.location,
				accessor: 'activity',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return <Typography variant="body2">{row.activity ? row.activity : 'no activity'}</Typography>;
				}
			},
			{
				id: 'category',
				Header: 'Category',
				className: classes.location,
				accessor: 'category',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return <Typography variant="body2">{row.category ? row.category : 'no group'}</Typography>;
				}
			},
			{
				id: 'publicId',
				Header: 'Actions',
				className: classes.actions,
				accessor: 'id',
				Cell: (rowData: any): ReactElement => {
					const row: TeamBasicsFragment = rowData.row.original;
					return (
						<PrimaryButton size="small" onClick={(): void => setTeam(row)}>
							Edit
						</PrimaryButton>
					);
				}
			}
		];
	}, [props.teamList]);

	return (
		<>
			<TableComponent data={props.teamList} columns={columns} />
			{team && (
				<DialogEditTeam
					handleClose={(): void => {
						setTeam(undefined);
						props.refetchTeams();
					}}
					team={team}
					open={true}
				/>
			)}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		headerText: {
			fontWeight: 600,
			color: SYSTEM_COLORS.GRAY
		},
		link: {
			color: SYSTEM_COLORS.PRIMARY,
			textDecoration: 'none'
		},
		actions: {},
		location: {},
		teamName: {}
	};
});

export default TeamTable;
