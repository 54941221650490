import { SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiPackageDown, mdiPackageUp, mdiPlus, mdiTshirtCrewOutline } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useMemo, useState } from 'react';

import Breadcrumb from '../../../../core/components/blocks/breadcrumb';
import NoEntryContainer from '../../../../core/components/blocks/containerNoEntries/containerNoEntries';
import ContentSection from '../../../../core/components/blocks/contentSection';
import SecondaryButton from '../../../../core/components/buttons/secondaryButton';
import SegmentedButtonControl from '../../../../core/components/buttons/segmentedButtonControl';
import StoreMetricsForTeamContainer from '../../../../core/components/connected/storeMetrics/StoreMetricsForTeamContainer';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { useTeamContext } from '../../../../providers/teamProvider/teamProvider';
import DialogAdd from './components/dialogAdd';
import StoreList from './components/storeList';

const Stores = (): ReactElement => {
	const classes = useStyles();
	const [dialogAdd, setDialogAdd] = useState(false);
	const [selectedOption, setSelectedOption] = useState(0);
	const teamContext = useTeamContext();

	const handleClose = (): void => {
		setDialogAdd(false);
	};
	const handleOpen = (): void => {
		setDialogAdd(true);
	};

	const options = useMemo(
		() => [
			{
				startIcon: (
					<SvgIcon className={classes.primaryIcon}>
						<path d={mdiPackageUp} />
					</SvgIcon>
				),
				label: 'Active'
			},
			{
				startIcon: (
					<SvgIcon className={classes.warningIcon}>
						<path d={mdiPackageDown} />
					</SvgIcon>
				),
				label: 'Archived'
			}
		],
		[classes]
	);

	return (
		<>
			<Breadcrumb label="Back to Teams!" route={'../../teams'} />

			<StoreMetricsForTeamContainer teamId={teamContext.team.id} />

			<ContentSection
				iconPath={mdiTshirtCrewOutline}
				iconColor={SYSTEM_COLORS.GRAY_LIGHT}
				title={`${teamContext.team.name} - Stores`}
			>
				<section className={classes.sectionContainer}>
					<div className={classes.headerContent}>
						<div className={classes.withShadow}>
							<SegmentedButtonControl
								buttonContainerClass={classes.segmentedButtonControlContainer}
								buttonsConfig={options}
								customButtonClass={classes.customButton}
								selectedIndex={selectedOption}
								setSelectedIndex={setSelectedOption}
							/>
						</div>
						<div className={clsx(classes.withShadow, classes.noMobile, classes.flexIt)}>
							<SecondaryButton
								startIcon={
									<SvgIcon>
										<path d={mdiPlus} />
									</SvgIcon>
								}
								onClick={handleOpen}
							>
								Create
							</SecondaryButton>
						</div>
					</div>
					<div>
						{selectedOption === 0 && <StoreList stores={teamContext.team.team_stores} />}
						{selectedOption === 1 && <StoreList stores={teamContext.team.team_stores} isArchived />}
					</div>
				</section>
			</ContentSection>

			{teamContext.team.team_stores && teamContext.team.team_stores.length === 0 && (
				<NoEntryContainer
					baseColor={SYSTEM_COLORS.SECONDARY}
					title="No Stores exist in for this team"
					iconPath={mdiTshirtCrewOutline}
					description="You currently do not have any products in the catalog, please start adding them."
				/>
			)}
			<DialogAdd open={dialogAdd} refetch={teamContext.refetch} handleClose={handleClose} teamId={teamContext.team.id} />
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		primaryIcon: {
			color: SYSTEM_COLORS.PRIMARY
		},
		warningIcon: {
			color: SYSTEM_COLORS.WARNING
		},
		withShadow: {
			boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.03)',
			borderRadius: 16,
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		flexIt: {
			display: 'flex'
		},
		segmentedButtonControlContainer: {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				display: 'flex'
			}
		},
		customButton: {
			margin: 5,
			padding: '5px 12px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				padding: '10px 12px'
			}
		},
		sectionContainer: {
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.BACKGROUND,
			padding: 15,
			[theme.breakpoints.down('sm')]: {
				padding: 10
			}
		},
		headerContent: {
			paddingRight: 0,
			paddingBottom: 15,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		noMobile: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		}
	};
});

export default Stores;
