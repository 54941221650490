import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { IconButton, SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiDotsVertical } from '@mdi/js';
import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { SYSTEM_COLORS } from '../../../../../../core/config/colors';
import { dragReorder } from '../../../../../../core/utilites/dragReorder';
import {
	AvailableOptionValueAdminBasicsFragment,
	GetAvailableOptionValuesByAvailableOptionIdDocument,
	InsertAvailableOptionValueDocument,
	RemoveAvailableOptionByPkDocument,
	UpdateAvailableOptionStatusByPkDocument,
	UpdateAvailableOptionValueByPkDocument
} from '../../../../../../generated/graphql';
import AddItem from '../addItem';
import AvailableOptionValue from '../availableOptionValue/availableOptionValue';

interface Props {
	availableOptionId: string;
}

const AvailableOptionValues = (props: Props): ReactElement => {
	const classes = useStyles();
	const [availableOptionItems, setAvailableOptions] = useState<AvailableOptionValueAdminBasicsFragment[]>([]);

	const { data, refetch, called } = useQuery(GetAvailableOptionValuesByAvailableOptionIdDocument, {
		variables: {
			_eq: props.availableOptionId
		}
	});

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		if (data?.available_option_value) {
			setAvailableOptions(data?.available_option_value);
		}
	}, [data]);

	const client = useApolloClient();
	const [addItem] = useMutation(InsertAvailableOptionValueDocument);
	const [editItem] = useMutation(UpdateAvailableOptionValueByPkDocument);
	const [editToggle] = useMutation(UpdateAvailableOptionStatusByPkDocument);
	const [removeItem] = useMutation(RemoveAvailableOptionByPkDocument);

	const handleAdd = async (label: string, price: string, prodPrice: string) => {
		await addItem({
			variables: {
				available_option_id: props.availableOptionId,
				label,
				price_modifier: parseInt(price, 10),
				price_modifier_production: parseInt(prodPrice, 10)
			}
		});
		await refetch();
	};

	const handleRemove = async (id: string) => {
		await removeItem({
			variables: {
				id
			}
		});
		await refetch();
	};

	const handleEdit = async (label: string, price: string, prodPrice: string, optionId: string) => {
		await editItem({
			variables: {
				id: optionId,
				label,
				price_modifier: parseInt(price, 10),
				price_modifier_production: parseInt(prodPrice, 10)
			}
		});
		await refetch();
	};
	const handleStatus = async (optionId: string, status: boolean) => {
		await editToggle({
			variables: {
				id: optionId,
				store_default_enabled: status
			}
		});
		await refetch();
	};

	const handleDragEnd = async (result: any) => {
		const updatedValues = dragReorder<AvailableOptionValueAdminBasicsFragment>(
			[...availableOptionItems],
			result.source.index,
			result.destination.index
		);

		let str = '';
		updatedValues.map((u, i) => {
			str += `
			update${i}: update_available_option_value_by_pk(pk_columns: {id: "${u.id}"}, _set: {order: ${i}}) {id}`;
		});
		await client.mutate({
			mutation: gql`
				mutation UpdateMultiple {
					${str}
				}
			`
		});

		setAvailableOptions(updatedValues);
	};

	return (
		<div className={classes.contentContainer}>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="available-list">
					{(provided): ReactElement => {
						return (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{availableOptionItems.map((ao, i) => {
									return (
										<Draggable key={ao.id} index={i} draggableId={ao.id}>
											{(p) => {
												const elem = (
													<IconButton {...p.dragHandleProps} disableRipple className={classes.dragIcon}>
														<SvgIcon>
															<path d={mdiDotsVertical} />
														</SvgIcon>
													</IconButton>
												);
												return (
													<div {...p.draggableProps} ref={p.innerRef}>
														<AvailableOptionValue
															dragElem={elem}
															{...ao}
															key={ao.id}
															handleRemove={(): void => {
																handleRemove(ao.id);
															}}
															toggleStatus={(status: boolean) => {
																handleStatus(ao.id, status);
															}}
															handleEdit={(
																label: string,
																price: string,
																prodPrice: string
															): void => {
																handleEdit(label, price, prodPrice, ao.id);
															}}
														/>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
			<AddItem handleAdd={handleAdd} />
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		contentContainer: {
			marginTop: 15,
			backgroundColor: '#F7F8FF',
			borderRadius: 8
		},
		dragIcon: {
			color: SYSTEM_COLORS.GRAY_LIGHT,
			marginRight: 10
		}
	};
});

export default AvailableOptionValues;
