import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { FormControl, IconButton, MenuItem, Paper, Select, SvgIcon, Tab, Tabs, Theme, makeStyles } from '@material-ui/core';
import { mdiDotsHorizontal, mdiMagnify, mdiPrinterPosCheckOutline } from '@mdi/js';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { CreateBulkShippingLabelsDocument, GetTeamStoreOrdersDocument } from '../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../config/colors';
import {
	OrderStatus,
	createMenuItemsFromEnum,
	createTabsFromEnum,
	getOrderStatusByIndex
} from '../../../../../utilites/orderHelpers';
import ButtonMenu from '../../../../blocks/buttonMenu/buttonMenu';
import PaperHeaderContainer from '../../../../blocks/paperHeaderContainer';
import PrimaryButton from '../../../../buttons/primaryButton';
import InputField from '../../../../inputs/inputField';
import UpdatedTable from './components/orderTable/orderTable';
import useExportMenuAndDialogs from './hooks/useExportMenuAndDialogs';

interface Props {
	storeId: string;
	refetchMetrics?(): void;
}

enum SortDirection {
	Includes = 'includes',
	Excludes = 'excludes'
}

interface SortOptions {
	SHIPPING_LABEL: undefined | SortDirection;
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

type ExportDataState =
	| { type: 'exportData'; value: 'USA' | 'EGYPT' }
	| { type: 'exportDataMaster'; value: 'USA' | 'EGYPT' }
	| { type: 'exportDataForDesignMaster'; value: 'USA' | 'EGYPT' }
	| { type: 'exportDataForShippingOrder'; value: 'USA' | 'EGYPT' }
	| undefined;

const orderStatusMenuItems = createMenuItemsFromEnum(OrderStatus);

const Orders = (props: Props): ReactElement => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState('');

	const [sortOptions, setSortOptions] = useState<SortOptions>({
		SHIPPING_LABEL: undefined
	});
	const [selected, setSelected] = useState<string[]>([]);
	const [status, setStatus] = useState('');
	const client = useApolloClient();

	const [orderStatusType, setOrderStatusType] = React.useState(2);

	const { enqueueSnackbar } = useSnackbar();

	const [createBulkLabels] = useMutation(CreateBulkShippingLabelsDocument);
	const { data, refetch, loading } = useQuery(GetTeamStoreOrdersDocument, {
		variables: {
			_eq: props.storeId,
			status: {
				_eq: getOrderStatusByIndex(1)
			}
		}
	});

	const { menuSpec, ExportDialog } = useExportMenuAndDialogs({
		orderStatusType,
		data,
		storeId: props.storeId
	});

	const handleSortOptionClick = () => {
		if (!sortOptions.SHIPPING_LABEL) {
			setSortOptions({
				SHIPPING_LABEL: SortDirection.Includes
			});
		} else if (sortOptions.SHIPPING_LABEL === SortDirection.Includes) {
			setSortOptions({
				SHIPPING_LABEL: SortDirection.Excludes
			});
		} else if (sortOptions.SHIPPING_LABEL === SortDirection.Excludes) {
			setSortOptions({
				SHIPPING_LABEL: undefined
			});
		}
	};

	useEffect(() => {
		if (selected.length === 0) {
			setStatus('');
		}
	}, [selected]);

	const handleChange = (event: any, newValue: number) => {
		setOrderStatusType(newValue);
		fetchOrderStatus(newValue);
	};

	const fetchOrderStatus = useMemo(
		() => (value?: number) => {
			const fetchWith = value === undefined ? orderStatusType : value;
			if (fetchWith === 0) {
				refetch({
					_eq: props.storeId,
					status: {}
				});
			} else {
				const dbVar = getOrderStatusByIndex(fetchWith - 1);
				refetch({
					_eq: props.storeId,
					status: { _eq: dbVar.toUpperCase() }
				});
			}
		},
		[]
	);

	const tabSection = useMemo(() => {
		return createTabsFromEnum(OrderStatus, classes.tab);
	}, []);

	const handleOrderStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
		const newOrderStatus = event.target.value as OrderStatus;
		setStatus(newOrderStatus);
	};

	const handleBulkShippingLabels = async () => {
		const data = await createBulkLabels({
			variables: {
				orderIds: selected,
				teamStoreId: props.storeId
			}
		});

		enqueueSnackbar('Shipping Generation has Started' + data.data?.createMultipleShippingLabels?.id, {
			variant: 'success'
		});
	};

	const handleBulkApply = async () => {
		let str = `update_order(where: { id: { _in: ${JSON.stringify(
			selected
		)} } }, _set: { status: "${status}" }) {returning { id }} `;
		selected.map((orderId, i) => {
			str += `
			insert${i}: insert_order_status_history(objects: {status: "${status}", order_id: "${orderId}"}) {returning {id}}`;
		});

		try {
			await client.mutate({
				mutation: gql`
				mutation UpdateMultiple {
					${str}
				}
			`
			});
			enqueueSnackbar('Successfully changed status', {
				variant: 'success'
			});
			fetchOrderStatus();
		} catch (e) {
			enqueueSnackbar('There was an error with this request, please try again', {
				variant: 'error'
			});
			setStatus('');
		}
	};

	return (
		<>
			<Tabs className={classes.tabs} value={orderStatusType} onChange={handleChange} aria-label="simple tabs example">
				<Tab className={classes.tab} label="All Orders" {...a11yProps(0)} />
				{tabSection}
			</Tabs>
			<Paper className={classes.paperContainer}>
				<PaperHeaderContainer className={classes.header}>
					<InputField
						value={searchTerm}
						variant="outlined"
						InputProps={{
							startAdornment: (
								<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
									<path d={mdiMagnify} />
								</SvgIcon>
							)
						}}
						onChange={(e): void => {
							setSearchTerm(e.target.value || '');
						}}
						placeholder={`Search`}
						inputProps={{ 'aria-label': 'search' }}
					/>
					<div>
						<div className={classes.iconList}>
							{selected.length > 0 && (
								<>
									<PrimaryButton
										disabled={selected.length === 0}
										onClick={handleBulkShippingLabels}
										className={classes.assignStatus}
									>
										Generate Shipping Labels
									</PrimaryButton>
									<FormControl>
										<Select
											className={classes.outlinedSelect}
											variant="outlined"
											value={status}
											onChange={handleOrderStatusChange}
											displayEmpty
											inputProps={{ 'aria-label': 'Status' }}
										>
											<MenuItem value="">Select a Status</MenuItem>
											{orderStatusMenuItems}
										</Select>
									</FormControl>
									<PrimaryButton
										disabled={status === ''}
										onClick={handleBulkApply}
										className={classes.assignStatus}
									>
										Assign
									</PrimaryButton>
								</>
							)}
							{selected.length === 0 && (
								<>
									<ButtonMenu iconPath={mdiDotsHorizontal} menuSpec={menuSpec} />
									<IconButton onClick={handleSortOptionClick} aria-label="first page">
										<SvgIcon
											className={clsx({
												[classes.includes]: sortOptions.SHIPPING_LABEL === SortDirection.Includes,
												[classes.excludes]: sortOptions.SHIPPING_LABEL === SortDirection.Excludes
											})}
										>
											<path d={mdiPrinterPosCheckOutline} />
										</SvgIcon>
									</IconButton>
								</>
							)}
						</div>
					</div>
				</PaperHeaderContainer>
				{ExportDialog}
				{data?.order && (
					<UpdatedTable
						setSelected={setSelected}
						selectedRows={selected}
						loading={loading}
						searchTerm={searchTerm}
						orders={data.order}
						refetchMetrics={props.refetchMetrics}
						refetchStore={refetch}
					/>
				)}
			</Paper>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		header: {
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: '0px 0 10px'
		},
		dialogIcons: {
			color: SYSTEM_COLORS.GRAY
		},
		magnify: {
			marginTop: 2
		},
		button: {
			marginRight: 15
		},

		iconButton: {
			marginLeft: 8,
			borderRadius: 8,
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: 8
		},
		includes: {
			color: SYSTEM_COLORS.GREEN_DEFAULT
		},
		excludes: {
			color: SYSTEM_COLORS.RED_DEFAULT
		},
		iconList: {
			display: 'flex',
			justifyContent: 'end'
		},
		tabs: {
			maxHeight: 40
			// borderBottom: `2px solid ${theme.palette.primary.main}`
		},
		tab: {
			fontWeight: 500,
			fontSize: '0.8rem',
			textTransform: 'none',
			minWidth: 0,
			marginRight: theme.spacing(2),
			'&$selected': {
				fontWeight: 600
				// borderBottom: `4px solid ${theme.palette.primary.main}`
			}
		},
		paperContainer: {
			padding: 16
		},
		outlinedSelect: {
			padding: 0, // adjust this value to reduce the padding size
			'& .MuiSelect-outlined.MuiSelect-outlined': {
				paddingTop: 13,
				paddingLeft: 8,
				paddingBottom: 13,
				minWidth: 160
				// paddingRight: 0 // adjust this value to reduce the right padding size
			}
		},
		assignStatus: {
			marginLeft: 8
		}
	};
});

export default Orders;
