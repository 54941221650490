import { useMutation } from '@apollo/client';
import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import classes from '*.module.css';
import ChipInput from 'material-ui-chip-input';
import React, { ReactElement, useEffect, useState } from 'react';

import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import P3 from '../../../../../../typography/p3';

interface Props {
	open: boolean;
	incomingTerms: string[];
	handleClose(labels?: string[]): void;
}

const DialogAttributionLabel = (props: Props): ReactElement => {
	const classes = useStyles();
	const [terms, setTerms] = useState<string[]>(props.incomingTerms);
	const handleClose = () => {
		props.handleClose(terms);
	};

	const handleAdd = (chip: any) => {
		setTerms([...terms, chip]);
	};

	const handleDelete = (chip: any, index: number) => {
		const clonedTerms = [...terms];
		clonedTerms.splice(terms.indexOf(chip), 1);
		setTerms(clonedTerms);
	};

	return (
		<DialogBase open={props.open} closeDialog={handleClose}>
			<DialogTitleHeader title="Add Attribution Items" closeDialog={handleClose} />
			<DialogContent>
				<ChipInput
					value={terms}
					onAdd={handleAdd}
					onDelete={handleDelete}
					variant={'outlined'}
					placeholder={'Attribution Labels'}
					disableUnderline={true}
					margin={'dense'}
					fullWidth={true}
				/>
				<P3 className={classes.p3}>
					Add a list of the attribution labels you want to have display on the checkout form for the user to select
				</P3>
			</DialogContent>
			<DialogActionBar cancelAction={props.handleClose} saveAction={handleClose} />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		p3: {
			padding: '5px'
		}
	};
});

export default DialogAttributionLabel;
