import { useMutation, useQuery } from '@apollo/client';
import { FormControl, InputLabel, Paper, Select, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';

import PaperHeaderContainer from '../../../core/components/blocks/paperHeaderContainer';
import PrimaryButton from '../../../core/components/buttons/primaryButton';
import { GetIsTeamShowcaseTypeDocument, UpdateTeamToGroupDocument } from '../../../generated/graphql';

interface Props {
	refetch(): void;
}

const ShowcaseSelector = (props: Props): ReactElement => {
	const classes = useStyles();
	const [selectedTeamStoreId, setSelectedTeamStoreId] = useState('');
	const { data } = useQuery(GetIsTeamShowcaseTypeDocument, {
		variables: {
			_eq: false
		}
	});
	const [updateStore] = useMutation(UpdateTeamToGroupDocument);

	const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
		setSelectedTeamStoreId(event.target.value);
	};

	const handleSelection = async () => {
		await updateStore({
			variables: {
				id: selectedTeamStoreId
			}
		});
		props.refetch();
	};

	return (
		<Paper>
			<PaperHeaderContainer>
				<Typography variant="h3">Team Catalog</Typography>
			</PaperHeaderContainer>
			<div className={classes.body}>
				<Typography variant="body1">
					In order to add showcases, we need to designate a team to act as the catalog owner. Please create a team, come
					back here and designate which team will be that catalog owner.
				</Typography>
				<Typography variant="body2">
					NOTE: this should be a fresh team with no stores as it will impact how those stores act.
				</Typography>
				<div className={classes.inputSelector}>
					<FormControl className={classes.formControl}>
						<InputLabel htmlFor="select-team-store">Team</InputLabel>
						<Select
							native
							value={selectedTeamStoreId}
							onChange={handleChange}
							inputProps={{
								name: 'Team Store',
								id: 'select-team-store'
							}}
						>
							<option aria-label="None" value="" />
							{data?.team.map((team) => {
								return (
									<option aria-label={team.name} value={team.id} key={team.id}>
										{team.name}
									</option>
								);
							})}
						</Select>
					</FormControl>
					<PrimaryButton onClick={handleSelection}>Confirm Selection</PrimaryButton>
				</div>
			</div>
		</Paper>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		body: {
			padding: 15,
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '20px'
		},
		inputSelector: {
			display: 'flex',
			alignItems: 'center'
		},
		formControl: {
			flex: 1,
			marginRight: 25
		}
	};
});

export default ShowcaseSelector;
