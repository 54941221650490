import { Avatar, Paper, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS, getRGBAFromHex } from '../../../config/colors';
import PrimaryButton from '../../buttons/primaryButton';
import P3 from '../../typography/p3';

interface Props {
	title: string;
	description: string;
	iconPath: string;
	baseColor?: string;
	action?(): void;
	actionLabel?: string;
}

const NoEntryContainer = (props: Props): ReactElement => {
	const classes = useStyles({ baseColor: props.baseColor || SYSTEM_COLORS.SECONDARY });

	return (
		<Paper className={classes.noEntryContainer}>
			<Avatar className={classes.loadingAvatar}>
				<SvgIcon>
					<path d={props.iconPath} />
				</SvgIcon>
			</Avatar>
			<Typography variant="h5" className={classes.loadingH5}>
				{props.title}
			</Typography>
			<P3 className={classes.loadingP3}>{props.description}</P3>
			{props.action && <PrimaryButton onClick={props.action}>{props.actionLabel}</PrimaryButton>}
		</Paper>
	);
};

interface StyleProps {
	baseColor: string;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		noEntryContainer: {
			marginTop: 20,
			flexDirection: 'column',
			display: 'flex',
			height: 277,
			textAlign: 'center',
			alignItems: 'center',
			justifyContent: 'center'
		},
		loadingAvatar: {
			backgroundColor: (props: StyleProps): string => getRGBAFromHex(props.baseColor, 0.15),
			'& svg': {
				color: (props: StyleProps): string => props.baseColor
			}
		},
		loadingH5: {
			paddingTop: 17,
			color: SYSTEM_COLORS.GRAY_SHADE
		},
		loadingP3: {
			maxWidth: '80%',
			paddingTop: 5,
			color: SYSTEM_COLORS.GRAY,
			paddingBottom: 15
		}
	};
});

export default NoEntryContainer;
