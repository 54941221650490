import { useQuery } from '@apollo/client';
import { Paper, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PaperHeaderContainer from '../../core/components/blocks/paperHeaderContainer';
import SecondadryButton from '../../core/components/buttons/secondaryButton/secondaryButton';
import { GetProductByIdDocument } from '../../generated/graphql';
import AvailableOptions from './components/availableOptions';
import ManageBundle from './components/manageBundle';
import ManageProduct from './components/manageProduct';
import ProductOptions from './components/productOptions';

const CatalogProduct = (): ReactElement => {
	const { productId } = useParams<'productId'>();
	const { loading, error, data, refetch } = useQuery(GetProductByIdDocument, {
		variables: {
			productId: productId
		}
	});

	useEffect(() => {
		return () => {
			refetch();
		};
	}, []);

	const wrappedRefetch = (): void => {
		refetch({
			productId
		});
	};

	const dataItem = data?.product_by_pk;
	const hasAvailableOptions = !dataItem?.is_bundle && Boolean(dataItem?.product_has_available_options?.length);
	const classes = useStyles({ hasAvailableOptions });

	if (!dataItem || !productId) {
		return <div>loading</div>;
	}

	return (
		<div className={classes.container}>
			<div className={classes.product}>
				<Paper className={classes.paper}>
					<PaperHeaderContainer>
						<Typography variant="h3">Manage Product</Typography>
						<SecondadryButton>Save</SecondadryButton>
					</PaperHeaderContainer>
					{dataItem.is_bundle ? (
						<ManageBundle dataItem={dataItem} productId={productId} />
					) : (
						<ManageProduct dataItem={dataItem} productId={productId} />
					)}
					{!dataItem.is_bundle &&
						dataItem.product_has_available_options &&
						dataItem.product_has_available_options.length > 0 && (
							<Typography variant="h3" className={classes.options}>
								Options
							</Typography>
						)}
					{!dataItem.is_bundle && (
						<div className={classes.optionContainer}>
							<ProductOptions productAvailableOptions={dataItem.product_has_available_options} refetch={refetch} />
						</div>
					)}
				</Paper>
			</div>
			{hasAvailableOptions && (
				<AvailableOptions
					refetchProduct={wrappedRefetch}
					productId={productId}
					productOptions={dataItem.product_has_available_options}
				/>
			)}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		paper: {
			backgroundColor: '#fff'
		},
		container: {
			display: 'flex',
			gap: '24px',
			alignItems: 'flex-start'
		},
		product: {
			flex: '1 1 auto',
			minWidth: 0,
			maxWidth: ({ hasAvailableOptions }: { hasAvailableOptions: boolean }) => (hasAvailableOptions ? '60%' : '100%')
		},
		options: {
			padding: 12,
			backgroundColor: '#FFF'
		},
		optionContainer: {
			borderBottomLeftRadius: 16,
			borderBottomRightRadius: 16,
			backgroundColor: '#f7f8fc'
		}
	};
});

export default CatalogProduct;
