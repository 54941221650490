import React, { ReactElement, useEffect, useState } from "react";
import { Theme, makeStyles } from "@material-ui/core";
import SearchBox from "./searchBox";

export interface SearchSet{
  label: string;
  terms: string[];
  filterType: string;
}

interface Props {
  sets?: SearchSet[];
  onChange?: (sets: SearchSet[])=>void;
}

const SearchSets = ({ sets, onChange }: Props): ReactElement => {
  const classes = useStyles();
  const [searchSets, setSearchSets] = useState(sets ? sets : []);

  useEffect(()=>{
    setSearchSets(sets ? sets : []);
  },[sets]);

  const handleNewChange = (set: SearchSet | null) => {
    if(set) {
      const clonedSets = [...searchSets];
      clonedSets.push(set);
      setSearchSets(clonedSets);
      if (onChange) {
        onChange(clonedSets);
      }
    }
  }

  const handleExistingChange = (index:number, set: SearchSet | null) => {
    const clonedSets = [...searchSets];
    if(set) {
      clonedSets[index] = set;
    }else{
      clonedSets.splice(index,1);
    }
    setSearchSets(clonedSets);
    if (onChange) {
      onChange(clonedSets);
    }
  }

  return (
    <div className={classes.container}>
      {searchSets.map((s,index)=> (
        <SearchBox searchSet={s} onChange={(set)=>handleExistingChange(index, set)} />
      ))}
      <div>
        <SearchBox onChange={handleNewChange} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      marginTop: 20
    }
  };
});

export default SearchSets;
