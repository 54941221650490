import { useQuery } from '@apollo/client';
import { Theme, makeStyles } from '@material-ui/core';
import { mdiTshirtCrewOutline } from '@mdi/js';
import React, { ReactElement, useEffect, useState } from 'react';

import { GetTeamStoreProductsDocument } from '../../../../../../generated/graphql';
import { useStoreContext } from '../../../../../../providers/storeProvider/storeProvider';
import { SYSTEM_COLORS } from '../../../../../config/colors';
import NoEntryContainer from '../../../../blocks/containerNoEntries/containerNoEntries';
import PaperHeaderContainer from '../../../../blocks/paperHeaderContainer';
import PaperHeaderItems from '../../../../blocks/paperHeaderItems';
import PrimaryButton from '../../../../buttons/primaryButton';
import ProductCreator from '../../../../productCreator';
import AddDiscount from './components/addDiscount';
import AddProductDialog from './components/addProductDialog';
import AddProductMetadataDialog from './components/addProductMetadataDialog';
import ProductList from './components/productList';
import RemoveProduct from './components/removeProduct';

interface Props {
	storeId: string;
}

interface OptsText {
	store_discount_rate?: number | null;
	store_discount_type?: string | null;
}

const getDiscountText = (opts: OptsText): string => {
	if (opts.store_discount_type === 'percent') {
		return `Edit ${opts.store_discount_rate}% Discount`;
	} else if (opts.store_discount_type === 'dollar') {
		return `Edit $${opts.store_discount_rate ? opts.store_discount_rate / 100 : 0} Discount`;
	}
	return 'Apply Discount';
};

const normalizeDiscountType = (type?: string | null) => {
	if (type === 'percent' || type === 'dollar') {
		return type;
	}
	return 'none';
};

const Products = (props: Props): ReactElement => {
	const storeContext = useStoreContext();
	const [removeProductId, setRemoveProductId] = useState<string | undefined>();
	const [dialogAdd, setDialogAdd] = useState(false);
	const [discoundDialog, setDiscountDialog] = useState(false);
	const [metadataDialog, setMetadataDialog] = useState(false);
	const { data, refetch } = useQuery(GetTeamStoreProductsDocument, {
		variables: {
			team_store_id: props.storeId
		}
	});

	useEffect(() => {
		refetch();
	}, []);

	const handleClose = (): void => {
		setDialogAdd(false);
	};
	const handleOpen = (): void => {
		setDialogAdd(true);
	};

	const openDiscountDialog = (): void => {
		setDiscountDialog(true);
	};
	const closeDiscountDialog = async (): Promise<void> => {
		await storeContext.refetch();
		setDiscountDialog(false);
	};

	const closeRemoveProduct = async () => {
		await refetch();
		setRemoveProductId(undefined);
	};

	return (
		<>
			<PaperHeaderContainer>
				<PaperHeaderItems
					handleNew={handleOpen}
					header={'Products'}
					newLabel="Add Product"
					altLabel={getDiscountText({
						store_discount_rate: storeContext.store.store_discount_rate,
						store_discount_type: storeContext.store.store_discount_type
					})}
					handleAltAction={openDiscountDialog}
				>
					<PrimaryButton
						variant="outlined"
						onClick={(): void => {
							setMetadataDialog(true);
						}}
					>
						Apply Metadata to Products
					</PrimaryButton>
				</PaperHeaderItems>
			</PaperHeaderContainer>
			<div>
				{(!data?.store_product || data?.store_product.length === 0) && (
					<NoEntryContainer
						baseColor={SYSTEM_COLORS.SECONDARY}
						title="No Products Defined for store"
						iconPath={mdiTshirtCrewOutline}
						description="You currently do not have any products defined for this store, please start adding them."
					/>
				)}
				{data?.store_product && data.store_product.length > 0 && (
					<ProductList setRemoveProduct={setRemoveProductId} products={data.store_product} refetch={refetch} />
				)}
			</div>
			<ProductCreator
				height={300}
				saveUrlOnSuccess={(status: string) => {
					refetch();
				}}
				storeId={props.storeId}
				label="Add Images"
			/>
			{discoundDialog && (
				<AddDiscount
					storeId={props.storeId}
					open={discoundDialog}
					handleClose={closeDiscountDialog}
					discountType={normalizeDiscountType(storeContext.store.store_discount_type)}
				/>
			)}
			<AddProductDialog open={dialogAdd} handleClose={handleClose} storeId={props.storeId} refetch={refetch} />
			<RemoveProduct handleClose={closeRemoveProduct} productId={removeProductId} />
			<AddProductMetadataDialog
				open={metadataDialog}
				storeId={props.storeId}
				handleClose={(): void => {
					setMetadataDialog(false);
				}}
			/>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {}
	};
});

export default Products;
