import { MuiThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import theme from './core/config/theme';
import Router from './core/router';
import AuthProvider from './providers/authProvider';
function App() {
	const authToken = localStorage.getItem('authToken');

	return (
		<SnackbarProvider
			hideIconVariant={true}
			disableWindowBlurListener={true}
			maxSnack={3}
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'top'
			}}
			autoHideDuration={3000}
		>
			<AuthProvider authToken={authToken}>
				<MuiThemeProvider theme={theme}>
					<Router />
				</MuiThemeProvider>
			</AuthProvider>
		</SnackbarProvider>
	);
}

export default App;
