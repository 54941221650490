import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

interface Props {
	children: ReactNode;
	className?: string;
}

const PaperHeaderContainer = (props: Props): ReactElement => {
	const classes = useStyles();

	return <div className={clsx(classes.header, props.className)}>{props.children}</div>;
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		header: {
			minHeight: 70,
			borderTopLeftRadius: 16,
			borderTopRightRadius: 16,
			backgroundColor: '#FFF',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: 15,
			marginBottom: 25
		}
	};
});

export default PaperHeaderContainer;
