import React, { ReactElement } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import Store from '../../../../core/components/connected/store';
import TeamProvider from '../../../../providers/teamProvider';
import Stores from '../stores';

const Team = (): ReactElement | null => {
	const { teamId } = useParams<'teamId'>();

	if (!teamId) {
		return null;
	}

	return (
		<TeamProvider teamId={teamId}>
			<Routes>
				<Route path=":storeId/*" element={<Store />} />
				<Route path={'/'} element={<Stores />} />
			</Routes>
		</TeamProvider>
	);
};

export default Team;
