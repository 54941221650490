import { mdiAccountMultiple, mdiCog, mdiFolderAccount, mdiLink, mdiReceipt, mdiTshirtCrew, mdiViewDashboard } from '@mdi/js';

export interface RouteIDParam {
	id: string;
}
export interface RouteIDWithAddtParam extends RouteIDParam {
	secondary: string;
}
export interface ThirdParam extends RouteIDWithAddtParam {
	thirdParam: string;
}

const LinkableItems = {
	overview: {
		label: 'Overview',
		to: '/',
		routeIconPath: mdiViewDashboard
	},
	catalog: {
		label: 'Catalog',
		to: '/catalog',
		routeIconPath: mdiTshirtCrew
	},
	teams: {
		label: 'Teams',
		to: '/teams',
		routeIconPath: mdiAccountMultiple
	},
	customLink: {
		label: 'Custom Link',
		to: '/custom-link',
		routeIconPath: mdiLink
	},
	receiptLookup: {
		label: 'Order Lookup',
		to: '/orders',
		routeIconPath: mdiReceipt
	},
	group: {
		label: 'Groups',
		to: '/groups',
		routeIconPath: mdiFolderAccount
	}
};

export const IterableLinkableEls = Object.values(LinkableItems);
