import { CardActionArea, Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import P3 from '../../typography/p3';

export interface RowToggleProps {
	disableHover?: boolean;
	className?: string;
	blockElements?: boolean;
	children?: ReactElement;
	disabled?: boolean;
	description?: string;
	title: string;
	onClick?(): void;
}

const RowToggle = (props: RowToggleProps): ReactElement => {
	const classes = useStyles();

	const Element = props.onClick ? CardActionArea : 'div';

	return (
		<Element
			disableRipple
			disabled={props.disabled}
			onClick={!props.disabled ? props.onClick : undefined}
			className={clsx(
				classes.container,
				props.className,
				props.blockElements ? classes.blockContainer : classes.flexContainer,
				{
					[classes.disableHover]: props.disableHover
				}
			)}
		>
			<div className={classes.content}>
				<div className={classes.navTitle}>{props.title}</div>
				{props.description && <P3 className={classes.p3}>{props.description}</P3>}
			</div>
			{props.children && (
				<div className={clsx(props.blockElements ? classes.blockChildPadding : null)}>{props.children}</div>
			)}
		</Element>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		content: {
			[theme.breakpoints.down('sm')]: {
				marginRight: 30
			}
		},
		container: {
			borderBottom: '1px solid rgba(48, 46, 40, 0.1);',
			'&:last-of-type': {
				borderBottom: 'none'
			}
		},
		blockContainer: {
			display: 'block'
		},
		blockChildPadding: {
			paddingTop: 15
		},
		flexContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		navTitle: {
			color: SYSTEM_COLORS.GRAY_SHADE,
			fontSize: 12,
			lineHeight: '18px'
		},
		p3: {
			color: SYSTEM_COLORS.GRAY
		},
		disableHover: {
			'&:hover': {
				'& .MuiCardActionArea-focusHighlight': {
					opacity: 0
				}
			}
		}
	};
});

export default RowToggle;
