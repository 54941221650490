import { useQuery } from '@apollo/client';
import {
	Chip,
	IconButton,
	Paper,
	SvgIcon,
	Table,
	TableContainer,
	Theme,
	Tooltip,
	Typography,
	makeStyles,
	withStyles
} from '@material-ui/core';
import { mdiMagnify, mdiReceiptTextEditOutline } from '@mdi/js';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { ReactElement, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import PaperHeaderContainer from '../../core/components/blocks/paperHeaderContainer';
import OrderTable from '../../core/components/connected/store/sections/orders/components/orderTable';
import InputField from '../../core/components/inputs/inputField';
import { SYSTEM_COLORS } from '../../core/config/colors';
import useDebounce from '../../core/hooks/useDebounce';
import { SearchOrdersDocument } from '../../generated/graphql';

interface Props {
	c?: any;
}

const OrderLookup = (props: Props): ReactElement => {
	const classes = useStyles();
	const { orderId } = useParams<'orderId'>();
	const [selectedOrder, setSelectedOrder] = useState(orderId || '');
	const [searchText, setSearchText] = useState('');
	const debouncedSearch = useDebounce<string>(searchText, 1000);

	const searchOrderNumber = !Number.isNaN(parseInt(debouncedSearch, 0)) ? parseInt(debouncedSearch, 0) : null;

	const whereClause = useMemo(() => {
		if (debouncedSearch === '' && !searchOrderNumber) {
			return null;
		}

		const where: any = {};
		if (searchOrderNumber) {
			where.order_readable_column = { _eq: searchOrderNumber };
		} else if (debouncedSearch) {
			where._or = [
				{ customer_email: { _ilike: debouncedSearch } },
				{ customer_name: { _ilike: debouncedSearch } },
				{ stripe_customer_id: { _ilike: debouncedSearch } },
				{ stripe_payment_id: { _ilike: debouncedSearch } }
			];
		}
		return where;
	}, [debouncedSearch, searchOrderNumber]);

	const { data, loading } = useQuery(SearchOrdersDocument, {
		variables: { whereClause },
		skip: !whereClause
	});

	return (
		<Paper className={classes.container}>
			<PaperHeaderContainer>
				<Typography variant="h3">Orders</Typography>
				<div>
					<InputField
						value={searchText}
						variant="outlined"
						InputProps={{
							startAdornment: (
								<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
									<path d={mdiMagnify} />
								</SvgIcon>
							)
						}}
						onChange={(e): void => {
							setSearchText(e.target.value || '');
						}}
						placeholder={`Search`}
						inputProps={{ 'aria-label': 'search' }}
					/>
				</div>
			</PaperHeaderContainer>
			<div className={classes.orderContainer}>
				{data?.order && <OrderTable selectedRows={[]} loading={loading} orders={data.order} />}
			</div>
		</Paper>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			minHeight: 277,
			textAlign: 'center',
			alignItems: 'center',
			justifyContent: 'center'
		},
		dialogIcons: {
			color: SYSTEM_COLORS.GRAY
		},
		magnify: {
			marginTop: 2
		},
		orderContainer: {
			padding: 15
		},

		table: {
			minWidth: 700
		},
		gray: {
			color: SYSTEM_COLORS.GRAY
		},
		chipCOMPLETE: {
			backgroundColor: SYSTEM_COLORS.GREEN_DEFAULT,
			'& span': {
				color: '#fff'
			},
			marginBottom: 2
		},
		chipEXPIRED: {
			backgroundColor: SYSTEM_COLORS.GRAY,
			'& span': {
				color: SYSTEM_COLORS.WHITE
			},
			marginBottom: 2
		},
		chipPENDING: {
			backgroundColor: SYSTEM_COLORS.WARNING,
			'& span': {
				color: '#333'
			},
			marginBottom: 2
		},
		dynamicSpan: {
			'&.pending': {
				backgroundColor: '#ffc107'
			},
			'&.complete': {
				backgroundColor: '#4caf50'
			},
			'&.cancelled': {
				backgroundColor: '#f44336'
			},
			'&.expired': {
				backgroundColor: '#9e9e9e'
			},
			'&.refunded': {
				backgroundColor: '#f44336'
			},
			'&.paid': {
				backgroundColor: '#4caf50'
			},
			'&.shipped': {
				backgroundColor: '#4caf50'
			},
			'&.indesign': {
				backgroundColor: '#9e9e9e'
			},
			'&.inproduction': {
				backgroundColor: '#9e9e9e'
			}
		}
	};
});

export default OrderLookup;
