/* eslint-disable no-useless-escape */
import { gql, useApolloClient } from '@apollo/client';
import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';

import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import InputField from '../../../../../../inputs/inputField';

interface Props {
	open: boolean;
	storeId: string;
	handleClose(success?: boolean): void;
}

export interface TeamMember {
	'product key': string;
	team: string;
	'First name': string;
	'Last Name': string;
	name: string;
	number: string;
	position: string;
	hometown: string;
	headshot: string;
	'Fun Fact': string;
}

interface Template {
	description: string;
	label: string;
	order: string;
	additional_description_blocks: DescriptionBlock[];
}

interface VariableMapping {
	key?: string;
	prefix?: string;
	suffix?: string;
	default?: string;
}

interface VariableMappings {
	[key: string]: VariableMapping | string;
}

interface DescriptionBlock {
	title: string;
	description: string;
}

interface JSONInstructions {
	template: Template;
	variableMappings: VariableMappings;
	players: TeamMember[];
}

function isKeyOfTeamMember(key: string): key is keyof TeamMember {
	return key in teamMemberSample;
}

const teamMemberSample: TeamMember = {
	'product key': '',
	team: '',
	'First name': '',
	'Last Name': '',
	name: '',
	number: '0',
	position: '',
	hometown: '',
	headshot: '',
	'Fun Fact': ''
};

const AddProductMetadataDialog = (props: Props): ReactElement => {
	const classes = useStyles();
	const [textData, setTextData] = useState<undefined | string>();
	const client = useApolloClient();
	const { enqueueSnackbar } = useSnackbar();

	const handleSave = async () => {
		try {
			if (textData) {
				const templateData: JSONInstructions = JSON.parse(textData);
				const teamData = templateData.players;

				let str = '';
				const sortedData = teamData.sort((a, b) => parseInt(`${a.number}`) - parseInt(`${b.number}`));

				sortedData.forEach((d, i) => {
					let description = templateData.template.description;
					let label = templateData.template.label;
					let order = templateData.template.order.replace('${order}', i.toString());

					Object.keys(templateData.variableMappings).forEach((key) => {
						const mapping = templateData.variableMappings[key];
						if (typeof mapping === 'string') {
							if (isKeyOfTeamMember(mapping)) {
								// Safe to use the key here
								description = description.replace(new RegExp(`\\$\{${key}\}`, 'g'), d[mapping]);
								label = label.replace(new RegExp(`\\$\{${key}\}`, 'g'), d[mapping]);
								order = order.replace(new RegExp(`\\$\{${key}\}`, 'g'), d[mapping]);
							}
						} else {
							const dataKey = mapping.key || key;
							if (isKeyOfTeamMember(dataKey)) {
								const value = `${mapping.prefix || ''}${d[dataKey]}${mapping.suffix || ''}`;
								description = description.replace(new RegExp(`\\$\{${key}}`, 'g'), value);
								label = label.replace(new RegExp(`\\$\{${key}\}`, 'g'), value);
								order = order.replace(new RegExp(`\\$\{${key}\}`, 'g'), value);
							}
						}
					});

					// Process additional description blocks
					let additionalDescription = '';
					templateData.template.additional_description_blocks.forEach((block) => {
						additionalDescription += `**${block.title}**\\n${block.description}\\n\\n`;
					});

					str += `
update${i}: update_store_product(where: {team_store_id: {_eq: "${props.storeId}"}, meta_key: {_eq: "${d['product key']}"}}, _set: {description: "${description}", label: "${label}", order: ${order}, additional_description: "${additionalDescription}" }) { returning {id} }`;
				});

				await client.mutate({
					mutation: gql`
				mutation UpdateMultiple {
					${str}
				}
			`
				});
			}
			enqueueSnackbar('Successfully added metadata to products!', {
				variant: 'success'
			});
		} catch (E) {
			enqueueSnackbar('There was an error adding your metadata labels' + E.message, {
				variant: 'error'
			});
		}
		props.handleClose();
	};

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader
				title="Apply Metadata to Products"
				subText={'Doing this will apply metadata to products listed in the store.'}
				closeDialog={props.handleClose}
			/>
			<DialogContent style={{ padding: 15 }}>
				<InputField
					variant="outlined"
					label="Percent discount in the store."
					fullWidth
					onChange={(e) => {
						setTextData(e.target.value);
					}}
					multiline
				/>
			</DialogContent>
			<DialogActionBar cancelAction={props.handleClose} saveAction={handleSave} />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		content: {
			width: '100%',
			flex: 1,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '15px 30px',
			borderTop: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			'&:last-of-type': {
				borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`
			}
		},
		contentSelected: {
			backgroundColor: 'rgba(55, 81, 255, .04)',
			'& svg': {
				color: SYSTEM_COLORS.PRIMARY
			}
		},
		input: {},
		fullFlex: { flex: 1 },
		label: {
			minWidth: 140,
			padding: '15px'
		}
	};
});

export default AddProductMetadataDialog;
