import { useMemo } from 'react';

import { TeamStoreOrderFragment } from '../../generated/graphql';

export interface ProductBase {
	label: string;
	id: string;
	price: number;
	image: string;
	orderFor?: string;
}

export interface OptionValue {
	id: string;
	availableOptionId: string; // abd-asdc-asdc
	availableOptionValueId: string; // abd-asdc-asdc
	priceModifiedAs: number; // 0
	value: string; // Medium
	label: string; // Size
	imageUrl?: string; // https://www.gogle.com
}

export interface Product extends ProductBase {
	type: 'product';
	store_product_id: string;
	custom_name?: string;
	custom_number?: string;
	quantity: number;
	options: {
		[key: string]: OptionValue;
	};
}

export interface Package extends ProductBase {
	type: 'package';
	products: Product[];
}

const useFormatServerOrder = (serverItems: TeamStoreOrderFragment) => {
	const productsAndPackages: (Product | Package)[] = useMemo(() => {
		const formattedPackages = serverItems.order_items
			.filter((o) => o.order_package_id)
			.reduce<{ [key: string]: Package }>((prev, cur) => {
				if (!cur.order_package_id) {
					return prev;
				}
				const {
					id,
					quantity,
					order_package,
					order_package_id,
					order_item_options,
					image_url,
					store_product,
					total_price,
					custom_name,
					custom_number
				} = cur;

				const orderOptionDefaults: { [key: string]: OptionValue } = {};

				const pkg: Package = {
					id: order_package_id,
					image: order_package?.image_url || '',
					label: order_package?.store_package.label || '',
					price: order_package?.total_price || 0,
					type: 'package',
					products: [
						{
							store_product_id: store_product.id,
							id: id,
							quantity,
							image: image_url || '',
							label: store_product.label || '',
							price: total_price,
							custom_name: custom_name || undefined,
							custom_number: custom_number || undefined,
							type: 'product',
							orderFor: cur.order_for || undefined,
							options: order_item_options.reduce((previous, opts) => {
								const optionValue: OptionValue = {
									id: opts.id,
									availableOptionId: opts.available_option_id,
									availableOptionValueId: opts.available_option_value_id,
									label: opts.available_option.name,
									priceModifiedAs: opts.price_modifier || 0,
									value: opts.value,
									imageUrl: opts.image_url || undefined
								};
								return {
									...previous,
									[opts.id]: optionValue
								};
							}, orderOptionDefaults)
						}
					]
				};

				if (prev[cur.order_package_id]) {
					return {
						...prev,
						[cur.order_package_id]: {
							...prev[cur.order_package_id],
							products: [...prev[cur.order_package_id].products, ...pkg.products]
						}
					};
				}

				return {
					...prev,
					[cur.order_package_id]: pkg
				};
			}, {});
		const products: Product[] = serverItems.order_items
			.filter((o) => !o.order_package_id)
			.map((order) => {
				return {
					store_product_id: order.store_product.id,
					id: order.id,
					quantity: order.quantity,
					image: order.image_url || '',
					label: order.store_product.label || '',
					price: order.total_price,
					type: 'product',
					orderFor: order.order_for || undefined,
					custom_name: order.custom_name || undefined,
					custom_number: order.custom_number || undefined,
					options: order.order_item_options.reduce((previous, opts) => {
						const optionValue: OptionValue = {
							id: opts.id,
							availableOptionId: opts.available_option_id,
							availableOptionValueId: opts.available_option_value_id,
							label: opts.available_option.name,
							priceModifiedAs: opts.price_modifier || 0,
							value: opts.value,
							imageUrl: opts.image_url || undefined
						};
						return {
							...previous,
							[opts.id]: optionValue
						};
					}, {})
				};
			});

		return [...products, ...Object.values(formattedPackages)];
	}, [serverItems.order_items]);

	return productsAndPackages;
};

export default useFormatServerOrder;
