import { useMutation, useQuery } from '@apollo/client';
import { Theme, makeStyles } from '@material-ui/core';
import { mdiTshirtCrewOutline } from '@mdi/js';
import React, { ReactElement, useEffect, useState } from 'react';

import {
	GetTeamStorePackagesDocument,
	TeamStorePackageFragment,
	UpdateStorePackageImageByIdDocument
} from '../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../config/colors';
import NoEntryContainer from '../../../../blocks/containerNoEntries/containerNoEntries';
import PaperHeaderContainer from '../../../../blocks/paperHeaderContainer';
import PaperHeaderItems from '../../../../blocks/paperHeaderItems';
import AddPackage from './components/addPackage';
import EditPackage from './components/editPackage';
import PackageList from './components/packageList';
import RemovePackage from './components/removePackage/removePackage';

interface Props {
	storeId: string;
}

const Packages = (props: Props): ReactElement => {
	const classes = useStyles();
	const [addDialogStatus, setAddDialogStatus] = useState(false);
	const [removePackageId, setRemovePackageId] = useState<string | undefined>();
	const [editPackage, setEditPackage] = useState<undefined | TeamStorePackageFragment>();
	const { data, refetch } = useQuery(GetTeamStorePackagesDocument, {
		variables: {
			_eq: props.storeId
		}
	});
	const [handleImageUpdate] = useMutation(UpdateStorePackageImageByIdDocument, {
		onCompleted: (): void => {
			refetch();
		}
	});

	useEffect(() => {
		refetch();
	}, []);

	const packages = data?.store_package;

	const handleClose = (): void => {
		setAddDialogStatus(false);
	};

	const handleCloseSuccessful = async (): Promise<void> => {
		await refetch();
		handleClose();
	};

	const addPackageImage = (packageId: string, url?: string): void => {
		handleImageUpdate({
			variables: {
				id: packageId,
				photo_url: url
			}
		});
	};

	const closeRemovePackage = async () => {
		await refetch();
		setRemovePackageId(undefined);
	};

	return (
		<>
			<PaperHeaderContainer>
				<PaperHeaderItems
					handleNew={(): void => {
						setAddDialogStatus(true);
					}}
					header={'Bundles'}
					newLabel="Add Bundle"
				/>
			</PaperHeaderContainer>
			{(!packages || packages.length === 0) && (
				<NoEntryContainer
					baseColor={SYSTEM_COLORS.SECONDARY}
					title="No Bundles"
					iconPath={mdiTshirtCrewOutline}
					description="You currently do not have any Bundles created."
				/>
			)}
			{packages && packages.length > 0 && (
				<PackageList
					packages={packages}
					addPackageImage={addPackageImage}
					editPackage={setEditPackage}
					removePackage={setRemovePackageId}
				/>
			)}

			<AddPackage
				open={addDialogStatus}
				handleClose={handleClose}
				handleSuccessfulClose={handleCloseSuccessful}
				storeId={props.storeId}
			/>
			{editPackage && (
				<EditPackage
					package={editPackage}
					open={true}
					handleClose={() => setEditPackage(undefined)}
					handleSuccessfulClose={() => {
						setEditPackage(undefined);
						refetch();
					}}
					storeId={props.storeId}
				/>
			)}
			<RemovePackage handleClose={closeRemovePackage} productId={removePackageId} />
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {}
	};
});

export default Packages;
