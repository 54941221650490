import { useMutation } from '@apollo/client';
import { Avatar, DialogContent, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiDelete } from '@mdi/js';
import React, { ReactElement } from 'react';

import { UpdateTeamStoreProductStatusByIdDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../../../../../../config/colors';
import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import P3 from '../../../../../../typography/p3';

interface Props {
	handleClose(): void;
	productId?: string;
}

const RemoveProduct = (props: Props): ReactElement => {
	const classes = useStyles();
	const [disableProduct, { loading }] = useMutation(UpdateTeamStoreProductStatusByIdDocument);

	const handleSave = async () => {
		if (!props.productId) {
			return;
		}
		await disableProduct({
			variables: {
				productId: props.productId,
				enabled: false
			}
		});
		props.handleClose();
	};

	return (
		<DialogBase open={Boolean(props.productId)}>
			<DialogTitleHeader closeDialog={props.handleClose} title="Are you sure?" />
			<DialogContent className={classes.dialogContent}>
				<Avatar className={classes.avatar}>
					<SvgIcon>
						<path d={mdiDelete} />
					</SvgIcon>
				</Avatar>
				<Typography variant="h5">Delete Product</Typography>
				<P3 className={classes.p3}>
					You can not undo this action. Are you sure you want to remove this product? (note if anyone has already
					ordered it, it will still show up in orders and in the export)
				</P3>
			</DialogContent>
			<DialogActionBar cancelAction={props.handleClose} disableSave={loading} saveAction={handleSave} saveLabel="Remove" />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		avatar: {
			width: 128,
			height: 128,
			backgroundColor: getRGBAFromHex(SYSTEM_COLORS.RED_DEFAULT, 0.15),
			'& svg': {
				width: 78,
				height: 78,
				color: SYSTEM_COLORS.RED_DEFAULT
			},
			marginBottom: 50
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			maxWidth: '500px',
			marginLeft: 'auto',
			marginRight: 'auto',
			justifyContent: 'center',
			textAlign: 'center'
		},
		p3: {
			paddingTop: 5,
			color: SYSTEM_COLORS.GRAY
		}
	};
});

export default RemoveProduct;
