import { User, useAuth0 } from '@auth0/auth0-react';
import { mdiClose } from '@mdi/js';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Catalog from '../page/catalog';
import CatalogProduct from '../page/catalogProduct';
import CustomLink from '../page/customLink';
import ErrorOnAuth from '../page/errorOnAuth';
import GroupRender from '../page/group/groupRender';
import Login from '../page/login';
import Overview from '../page/overview';
import OrderLookup from '../page/receiptLookup';
import Teams from '../page/teams';
import Team from '../page/teams/pages/team';
import { WebsiteConfig } from '../page/websiteConfig';
import { useAuth } from '../providers/authProvider/authProvider';
import AppContainer from './AppContainer';

const RouterInstance = () => {
	// const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();
	const { user } = useAuth();

	// if (error) {
	// 	return <ErrorOnAuth name={error.name} message={error.message} actionLabel="Logout" action={logout} />;
	// }

	if (!user) {
		// loginWithRedirect();
		return <Login />;
	}

	return (
		<AppContainer>
			<Routes>
				<Route path="/groups/*" element={<GroupRender />} />
				<Route path="/orders/:orderId" element={<OrderLookup />} />
				<Route path="/orders" element={<OrderLookup />} />
				<Route path="/custom-link" element={<CustomLink />} />
				<Route path="/teams" element={<Teams />} />
				<Route path="/teams/:teamId/*" element={<Team />} />
				<Route path="/catalog/:productId" element={<CatalogProduct />} />
				<Route path="/catalog" element={<Catalog />} />
				<Route path="/" element={<Overview />} />
			</Routes>
		</AppContainer>
	);
};

export default RouterInstance;
