import { gql, useApolloClient, useMutation } from '@apollo/client';
import { SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiDotsVertical } from '@mdi/js';
import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

import { TeamStoreProductListFragment, UpdateStoreProductDisplayImageDocument } from '../../../../../../../../generated/graphql';
import { useStoreContext } from '../../../../../../../../providers/storeProvider/storeProvider';
import { useTeamContext } from '../../../../../../../../providers/teamProvider/teamProvider';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import { dragReorder } from '../../../../../../../utilites/dragReorder';
import ProductItem from '../productItem';

interface Props {
	refetch(): void;
	setRemoveProduct(productId: string): void;
	products: TeamStoreProductListFragment[];
}

const ProductList = (props: Props): ReactElement => {
	const classes = useStyles();
	const { products } = props;
	const [options, setProductOptions] = useState(products);
	const [updateImageUrl] = useMutation(UpdateStoreProductDisplayImageDocument);
	const client = useApolloClient();
	const navigate = useNavigate();

	useEffect(() => {
		setProductOptions(products);
	}, [products]);

	const handleDragEnd = (result: any): void => {
		const updatedValues = dragReorder<TeamStoreProductListFragment>(
			[...options],
			result.source.index,
			result.destination.index
		);

		let str = '';
		updatedValues.map((u, i) => {
			str += `
			update${i}: update_store_product_by_pk(pk_columns: {id: "${u.id}"}, _set: {order: ${i}}) {id}`;
		});
		client.mutate({
			mutation: gql`
				mutation UpdateMultiple {
					${str}
				}
			`
		});

		setProductOptions(updatedValues);
	};

	const handleEditProduct = (editId: string) => {
		navigate(`../product/${editId}`);
	};

	const handleImageUpdate = async (id: string, url?: string): Promise<void> => {
		await updateImageUrl({
			variables: {
				display_photo: url,
				id
			}
		});
		props.refetch();
	};

	return (
		<div>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="product-list">
					{(provided): ReactElement => {
						return (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{options.map((d, i): ReactElement => {
									return (
										<Draggable key={d.id} index={i} draggableId={d.id}>
											{(p) => {
												const elem = (
													<div {...p.dragHandleProps}>
														<SvgIcon className={classes.dragIcon}>
															<path d={mdiDotsVertical} />
														</SvgIcon>
													</div>
												);
												return (
													<div {...p.draggableProps} ref={p.innerRef}>
														<ProductItem
															setRemoveProduct={props.setRemoveProduct}
															editProduct={handleEditProduct}
															dragHandle={elem}
															product={d}
															handleImageUpdate={handleImageUpdate}
														/>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		dragIcon: {
			cursor: 'grab',
			color: SYSTEM_COLORS.GRAY_LIGHT,
			width: 24,
			height: 24
		}
	};
});

export default ProductList;
