import { useQuery } from '@apollo/client';
import { ButtonBase, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../../../core/components/blocks/loader';
import P3 from '../../../../core/components/typography/p3';
import P6 from '../../../../core/components/typography/p6';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { formatCurrencyValueFromServer } from '../../../../core/utilites/currenty';
import { GetTeamStoresWithActiveShippingDocument } from '../../../../generated/graphql';
import OverviewContainer from '../common/overviewContainer';
import OverviewHeader from '../common/overviewHeader';

interface Props {
	c?: any;
}

const OrdersAwaitingShipping = (props: Props): ReactElement | null => {
	const classes = useStyles();

	const { data, refetch, loading } = useQuery(GetTeamStoresWithActiveShippingDocument, {
		fetchPolicy: 'network-only'
	});

	return (
		<OverviewContainer>
			<>
				<OverviewHeader
					loading={false}
					refetch={() => refetch()}
					title="Stores with Ship To orders"
					description="Stores with ship to orders awaiting to be acknowledged"
				/>
				{loading && <Loader />}
				{!loading && (
					<div className={classes.results}>
						{!data?.team_store.length && <div>No stores with ship to orders awaiting to be acknowledged</div>}
						{data?.team_store.map((teamStoreDetails) => {
							return (
								<ButtonBase
									key={teamStoreDetails.id}
									className={classes.order}
									component={Link}
									to={`/teams/${teamStoreDetails.team.id}/${teamStoreDetails.id}/orders`}
								>
									<div>
										<Typography variant="h5">{teamStoreDetails.name}</Typography>
										<P3 className={classes.p3}>{teamStoreDetails.team.name}</P3>
									</div>
									<div className={classes.rightContent}>
										<Typography variant="h4">
											{formatCurrencyValueFromServer(
												teamStoreDetails.orders_aggregate.aggregate?.sum?.total || 0
											)}
										</Typography>
										<P3>{teamStoreDetails.orders_aggregate.aggregate?.count} orders</P3>
									</div>
								</ButtonBase>
							);
						})}
					</div>
				)}
			</>
		</OverviewContainer>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		results: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '10px',

			overflowY: 'scroll',
			maxHeight: '500px'
		},

		order: {
			borderRadius: 4,
			padding: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			textAlign: 'left'
		},
		rightContent: {
			textAlign: 'right'
		},
		p3: {
			color: SYSTEM_COLORS.GRAY_LIGHT
		}
	};
});

export default OrdersAwaitingShipping;
