/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Paper, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useStoreContext } from '../../../../providers/storeProvider/storeProvider';
import { useTeamContext } from '../../../../providers/teamProvider/teamProvider';
import Breadcrumb from '../../blocks/breadcrumb';
import StoreMetrics from '../storeMetrics';
import StoreMetricsForContainer from '../storeMetrics/StoreMetricsContainer';
import StoreMetricsForTeamContainer from '../storeMetrics/StoreMetricsForTeamContainer';
import NavSections from './components/navSections';
import Orders from './sections/orders';
import OrdersFromGroup from './sections/ordersFromGroup';
import Packages from './sections/packages';
import Products from './sections/products';
import Showcase from './sections/showcase';
import StoreInfo from './sections/storeInfo';
import { STORE_SUB_ROUTE_DETAILS } from './subRoutes';

const StoreSection = (): ReactElement | null => {
	const classes = useStyles();
	const teamContext = useTeamContext();

	const storeContext = useStoreContext();

	if (!storeContext.store) {
		return <div> you need a team </div>;
	}

	return (
		<>
			<Breadcrumb label={`Back to Stores`} route={'../../'} />
			<div className={classes.sectionSelector}>
				<NavSections isTeamShowcaseStore={storeContext.store.use_as_catalog} />
			</div>
			{storeContext?.store?.id ? (
				<StoreMetricsForContainer storeId={storeContext.store.id} />
			) : (
				<StoreMetricsForTeamContainer teamId={teamContext.team.id} />
			)}
			<Routes>
				<Route
					path={STORE_SUB_ROUTE_DETAILS.STORE.route}
					element={
						<Paper className={classes.paperContainer}>
							<StoreInfo teamStoreInfo={storeContext.store} refetch={storeContext.refetch} />
						</Paper>
					}
				/>
				<Route
					path={STORE_SUB_ROUTE_DETAILS.PRODUCTS.route}
					element={
						<Paper className={classes.paperContainer}>
							<Products storeId={storeContext.store!.id} />
						</Paper>
					}
				/>
				<Route
					path={STORE_SUB_ROUTE_DETAILS.PACKAGES.route}
					element={
						<Paper className={classes.paperContainer}>
							<Packages storeId={storeContext.store!.id} />
						</Paper>
					}
				/>
				<Route path={STORE_SUB_ROUTE_DETAILS.ORDERS.route} element={<Orders storeId={storeContext.store!.id} />} />
				<Route
					path={STORE_SUB_ROUTE_DETAILS.ORDERS_FROM_GROUP.route}
					element={
						<Paper className={classes.paperContainer}>
							<OrdersFromGroup storeId={storeContext.store!.id} />
						</Paper>
					}
				/>
				<Route
					path={STORE_SUB_ROUTE_DETAILS.SHOWCASING_STORES.route}
					element={
						<Paper className={classes.paperContainer}>
							<Showcase storeId={storeContext.store!.id} />
						</Paper>
					}
				/>
			</Routes>
			{/* </Paper> */}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		sectionSelector: {
			paddingTop: 20,
			'& > :not(:first-child)': {
				marginLeft: 16
			}
		},
		selectionOption: {
			width: 120
		},
		paperContainer: {
			padding: 16
		}
	};
});

export default StoreSection;
