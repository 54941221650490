/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ButtonBase, Collapse, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';

import PrimaryButton from '../../../../../../../../../../core/components/buttons/primaryButton';
import InputField from '../../../../../../../../../../core/components/inputs/inputField';

interface Props {
	label: string;
	updateProductOption(labelOverrideValue: string): void;
}

const LabelOverride = (props: Props): ReactElement => {
	const classes = useStyles();
	const [labelOverrideValue, setEditLabel] = useState<string | undefined>();

	return (
		<div className={classes.label}>
			<Collapse in={labelOverrideValue === undefined}>
				<ButtonBase
					style={{ width: '100%', justifyContent: 'flex-start' }}
					onClick={(): void => {
						setEditLabel(props.label || '');
					}}
				>
					<Typography variant="body2">{props.label}</Typography>
				</ButtonBase>
			</Collapse>
			<Collapse in={labelOverrideValue !== undefined} unmountOnExit>
				<InputField
					className={classes.inputElement}
					autoFocus
					variant="outlined"
					label="Override Label"
					onChange={(e) => {
						setEditLabel(e.target.value);
					}}
					onKeyDown={(e): void => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							props.updateProductOption(labelOverrideValue!);
							setEditLabel(undefined);
						}
					}}
					value={labelOverrideValue}
				/>
				<PrimaryButton
					onClick={(): void => {
						props.updateProductOption(labelOverrideValue!);
						setEditLabel(undefined);
					}}
				>
					Save
				</PrimaryButton>
			</Collapse>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		label: {
			height: 100,
			marginLeft: 20,
			marginRight: 20,
			flex: 1
		},
		inputElement: {
			marginRight: 20
		}
	};
});

export default LabelOverride;
