import { SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiMagnify, mdiPlusCircle } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import PrimaryButton from '../../buttons/primaryButton';
import InputField from '../../inputs/inputField';

interface Props {
	header: string;
	handleNew(): void;
	newLabel: string;
	hasInputField?: boolean;
	handleAltAction?(): void;
	altLabel?: string;
	altIcon?: string;
	children?: ReactNode;
}

const PaperHeaderItems = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<>
			<Typography variant="h3">{props.header}</Typography>
			<div>
				{props.hasInputField && (
					<InputField
						variant="outlined"
						InputProps={{
							startAdornment: (
								<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
									<path d={mdiMagnify} />
								</SvgIcon>
							)
						}}
						placeholder={`Search`}
						inputProps={{ 'aria-label': 'search' }}
					/>
				)}
				{props.children}
				{props.handleAltAction && (
					<PrimaryButton
						variant="outlined"
						onClick={props.handleAltAction}
						startIcon={
							props.altIcon && (
								<SvgIcon>
									<path d={props.altIcon} />
								</SvgIcon>
							)
						}
						className={classes.newButton}
					>
						{props.altLabel}
					</PrimaryButton>
				)}
				<PrimaryButton
					onClick={props.handleNew}
					startIcon={
						<SvgIcon>
							<path d={mdiPlusCircle} />
						</SvgIcon>
					}
					className={classes.newButton}
				>
					{props.newLabel}
				</PrimaryButton>
			</div>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		dialogIcons: {
			color: SYSTEM_COLORS.GRAY
		},
		magnify: {
			marginTop: 2
		},
		newButton: {
			marginLeft: 20
		}
	};
});

export default PaperHeaderItems;
