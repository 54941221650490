import { useMutation } from '@apollo/client';
import { DialogContent, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';

import { UpdateOrderItemCustomNameByIdDocument } from '../../../../../../../../../../generated/graphql';
import DialogActionBar from '../../../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../../../blocks/dialogTitleHeader';
import InputField from '../../../../../../../../inputs/inputField';

interface Props {
	open: boolean;
	handleClose(saved?: boolean): void;
	incomingValue?: string;
	orderItemId: string;
}

const EditCustomNameDialog = (props: Props): ReactElement => {
	const classes = useStyles();
	const [customName, setCustomName] = useState(props.incomingValue || '');
	const [updateValue] = useMutation(UpdateOrderItemCustomNameByIdDocument);

	const handleSave = async () => {
		await updateValue({
			variables: {
				id: props.orderItemId,
				custom_name: customName ? customName : ' ' // we do this because undefined represents the lack of a number at all.
			}
		});
		props.handleClose(true);
	};

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader title="Edit Custom Number" closeDialog={props.handleClose} />
			<DialogContent>
				<Typography variant="h5">Edit Custom Name</Typography>
				<InputField
					onKeyDown={(e) => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							handleSave();
							e.preventDefault();
						}
					}}
					variant="outlined"
					onChange={(e): void => {
						setCustomName(e.target.value);
					}}
					value={customName}
				/>
			</DialogContent>
			<DialogActionBar saveAction={handleSave} cancelAction={props.handleClose} />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {}
	};
});

export default EditCustomNameDialog;
