import { Button, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

import DialogActionBar from '../../blocks/dialogActionBar';
import DialogBase from '../../blocks/dialogBase';
import DialogTitleHeader from '../../blocks/dialogTitleHeader';

interface ConfirmationDialogProps {
	title: string;
	message: string;
	open: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ title, message, open, onConfirm, onCancel }) => {
	return (
		<DialogBase
			open={open}
			closeDialog={onCancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitleHeader title={title} closeDialog={onCancel} />
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{message}</DialogContentText>
			</DialogContent>
			<DialogActionBar>
				<Button onClick={onCancel} color="primary">
					Cancel
				</Button>
				<Button onClick={onConfirm} color="primary" autoFocus>
					Confirm
				</Button>
			</DialogActionBar>
		</DialogBase>
	);
};

export default ConfirmationDialog;
