import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';

import { GetTeamTotalsNoStoreByPkDocument } from '../../../../generated/graphql';
import StoreMetrics from './storeMetrics';

interface Props {
	teamId: string;
}

const StoreMetricsForTeamContainer = (props: Props): ReactElement => {
	const { data } = useQuery(GetTeamTotalsNoStoreByPkDocument, {
		variables: {
			_eq: props.teamId
		},
		pollInterval: 10000 // Refresh every 10 seconds
	});

	return <StoreMetrics teamStores={data?.team[0]?.team_stores || []} />;
};

export default StoreMetricsForTeamContainer;
