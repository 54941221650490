import { CardActionArea, SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiPencil } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

import P3 from '../../../../../../../../typography/p3';

interface LineItemProps {
	className: string;
	label: string;
	value: string | number;
	withLabel?: boolean;
	handleEdit?(): void;
}
const LineItem = (props: LineItemProps) => {
	const classes = useStyles();
	if (props.handleEdit) {
		return (
			<CardActionArea onClick={props.handleEdit} className={clsx(classes.iconContainer, props.className)}>
				<SvgIcon className={classes.icon}>
					<path d={mdiPencil} />
				</SvgIcon>
				<P3>
					{props.label}: {props.value}
				</P3>
			</CardActionArea>
		);
	}

	return (
		<div className={props.className}>
			{props.label}: {props.value}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		iconContainer: {
			padding: 5,
			display: 'flex',
			justifyContent: 'initial',
			alignItems: 'center'
		},
		icon: {
			marginRight: 5,
			width: 15,
			height: 15
		}
	};
});

export default LineItem;
