import { IconButton, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiRefresh } from '@mdi/js';
import React, { ReactElement } from 'react';

import P3 from '../../../../../core/components/typography/p3';
import { SYSTEM_COLORS } from '../../../../../core/config/colors';

interface Props {
	title: string;
	description: string;
	refetch?: () => void;
	loading: boolean;
	children?: ReactElement;
}

const OverviewHeader = (props: Props): ReactElement => {
	const classes = useStyles();
	const { title, refetch, loading, description } = props;

	return (
		<div className={classes.header}>
			<div>
				<Typography variant="h5" className={classes.h5}>
					{title}
				</Typography>
				<P3 className={classes.p3}>{description}</P3>
			</div>
			<div>
				{props.refetch && (
					<IconButton disabled={loading} onClick={refetch}>
						<SvgIcon>
							<path d={mdiRefresh} />
						</SvgIcon>
					</IconButton>
				)}
				{props.children}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		header: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			marginBottom: 20
		},
		p3: {
			color: SYSTEM_COLORS.GRAY_LIGHT
		},
		h5: {
			color: SYSTEM_COLORS.PRIMARY
		}
	};
});

export default OverviewHeader;
