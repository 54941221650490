import { useMutation } from '@apollo/client';
import { DialogContent, Theme, Typography, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useState } from 'react';

import { UpdateTeamStoreFriendlyUrlKeyDocument } from '../../../../../../../../generated/graphql';
import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import InputField from '../../../../../../inputs/inputField';

interface Props {
	refetch(): void;
	open: boolean;
	handleClose(success?: boolean): void;
	teamStoreId: string;
	hasKey?: boolean;
}

const DialogFriendlyKey = (props: Props): ReactElement => {
	const [friendlyUrlKey, setFriendlyUrlKey] = useState('');
	const { enqueueSnackbar } = useSnackbar();
	const [addProduct, { loading }] = useMutation(UpdateTeamStoreFriendlyUrlKeyDocument);
	const classes = useStyles();

	const handleSubmit = async (): Promise<void> => {
		if (friendlyUrlKey !== '') {
			try {
				await addProduct({
					variables: {
						friendly_url_key: friendlyUrlKey,
						id: props.teamStoreId
					}
				});
				props.refetch();
				props.handleClose(true);
			} catch (e) {
				enqueueSnackbar('This key has already been used. Please use a new key.', {
					variant: 'error'
				});
			}
		}
	};

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader title={props.hasKey ? 'Change URL' : 'Add URL'} closeDialog={props.handleClose} />
			<DialogContent>
				<Typography variant="h3" className={classes.content}>
					NOTE: Changing this will impact any shared URL's
				</Typography>
				<InputField
					onKeyDown={(e) => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							handleSubmit();
							e.preventDefault();
						}
					}}
					onChange={(e): void => {
						const value = e.currentTarget.value.replace(/[^a-z0-9]/gi, '');
						setFriendlyUrlKey(value.toLowerCase());
					}}
					variant="outlined"
					label="Friendly URL Key"
					fullWidth
					helperText="lowercase letters & numbers only."
					value={friendlyUrlKey}
				/>
			</DialogContent>
			<DialogActionBar cancelAction={props.handleClose} saveAction={handleSubmit} disableSave={loading} />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		content: {
			textAlign: 'center',
			marginBottom: 15
		}
	};
});

export default DialogFriendlyKey;
