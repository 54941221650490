export const cleanNumberFormat = (inputNumber: string): string => {
	return inputNumber.replace(/[, ]+/g, '').trim();
};

export const formatCurrencyValueFromServer = (value: number | string) => {
	if (typeof value === 'string') {
		return formatValueWithCurrency(parseInt(value, 10) / 100);
	}
	return formatValueWithCurrency(value / 100);
};

export const formatValueWithCurrency = (value: number, includeDecimals = true): string => {
	return Intl.NumberFormat('en-us', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: includeDecimals ? 2 : 0
	}).format(value);
};
