import { InputAdornment, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import NumberFormatNegative from '../../../../../../core/components/blocks/numberFormatNegative';
import PrimaryButton from '../../../../../../core/components/buttons/primaryButton';
import InputField from '../../../../../../core/components/inputs/inputField';
import { SYSTEM_COLORS } from '../../../../../../core/config/colors';

interface Props {
	asEdit?: boolean;
	defaultPrice?: string;
	defaultPriceProd?: string;
	defaultName?: string;
	handleAdd(name: string, price: string, prodPrice: string): void;
}

const AddItem = (props: Props): ReactElement => {
	const classes = useStyles();
	const [label, setLabel] = useState(props.defaultName || '');
	const [price, setPrice] = useState(props.defaultPrice || '0');
	const [priceProd, setPriceProd] = useState(props.defaultPriceProd || '0');

	const handleSubmit = (): void => {
		if (label === '' || price === '') {
			return;
		}
		props.handleAdd(label, `${Math.round(parseFloat(price) * 100)}`, `${Math.round(parseFloat(priceProd) * 100)}`);
		setLabel('');
		setPrice('0');
		setPriceProd('0');
	};

	const handleInput = (e: any): void => {
		setLabel(e.target.value);
	};

	const handleInputNumber = (e: any): void => {
		setPrice(e.target.value);
	};
	const handleProdInputNumber = (e: any): void => {
		setPriceProd(e.target.value);
	};

	return (
		<div className={classes.addNew}>
			<InputField
				fullWidth
				autoCorrect="off"
				autoComplete="off"
				variant="outlined"
				label="Name"
				name="name"
				onKeyDown={(e) => {
					if (e.keyCode === 13 || e.key === 'Enter') {
						handleSubmit();
						e.preventDefault();
					}
				}}
				onChange={handleInput}
				value={label}
			/>
			<div className={classes.priceOptions}>
				<InputField
					onChange={handleInputNumber}
					variant="outlined"
					label="Public Price"
					onKeyDown={(e) => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							handleSubmit();
							e.preventDefault();
						}
					}}
					InputProps={{
						inputComponent: NumberFormatNegative,
						startAdornment: (
							<InputAdornment position="start">
								<strong>$</strong>
							</InputAdornment>
						)
					}}
					value={price}
				/>
				<InputField
					onChange={handleProdInputNumber}
					variant="outlined"
					label="Production Price"
					onKeyDown={(e) => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							handleSubmit();
							e.preventDefault();
						}
					}}
					InputProps={{
						inputComponent: NumberFormatNegative,
						startAdornment: (
							<InputAdornment position="start">
								<strong>$</strong>
							</InputAdornment>
						)
					}}
					value={priceProd}
				/>
			</div>
			<PrimaryButton disabled={label === '' || price === ''} fullWidth onClick={handleSubmit}>
				{props.asEdit ? 'Save' : 'Add'}
			</PrimaryButton>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		addNew: {
			borderTop: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: 16
		},
		priceOptions: {
			paddingTop: 25,
			paddingBottom: 25,
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
			gap: '15px'
		}
	};
});

export default AddItem;
