import { Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface Props {
	c?: any;
}

const Loader = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<div>
			<div>Loading...</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {}
	};
});

export default Loader;
