import { gql, useApolloClient, useMutation } from '@apollo/client';
import { SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiDotsVertical } from '@mdi/js';
import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { TeamStorePackageFragment } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import { dragReorder } from '../../../../../../../utilites/dragReorder';
import Package from '../package/package';

interface Props {
	packages: TeamStorePackageFragment[];
	addPackageImage(id: string, url?: string): void;
	editPackage(pack: TeamStorePackageFragment): void;
	removePackage(packageId: string): void;
}

const PackageList = (props: Props): ReactElement => {
	const classes = useStyles();
	const client = useApolloClient();
	const { packages } = props;

	const [options, setProductOptions] = useState(packages);
	const handleDragEnd = (result: any): void => {
		const updatedValues = dragReorder<TeamStorePackageFragment>([...options], result.source.index, result.destination.index);

		let str = '';
		updatedValues.map((u, i) => {
			str += `
			update${i}: update_store_package_by_pk(pk_columns: {id: "${u.id}"}, _set: {order: ${i}}) {id}`;
		});
		client.mutate({
			mutation: gql`
				mutation UpdateMultiple {
					${str}
				}
			`
		});

		setProductOptions(updatedValues);
	};

	useEffect(() => {
		setProductOptions(packages);
	}, [packages]);

	return (
		<div>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="product-list">
					{(provided): ReactElement => {
						return (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{options.map((d, i): ReactElement => {
									return (
										<Draggable key={d.id} index={i} draggableId={d.id}>
											{(p) => {
												const elem = (
													<div {...p.dragHandleProps}>
														<SvgIcon className={classes.dragIcon}>
															<path d={mdiDotsVertical} />
														</SvgIcon>
													</div>
												);
												return (
													<div {...p.draggableProps} ref={p.innerRef}>
														<Package
															dragHandle={elem}
															removePackage={props.removePackage}
															p={d}
															key={d.id}
															addPackageImage={props.addPackageImage}
															editPackage={props.editPackage}
														/>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		dragIcon: {
			cursor: 'grab',
			color: SYSTEM_COLORS.GRAY_LIGHT,
			width: 24,
			height: 24
		}
	};
});

export default PackageList;
