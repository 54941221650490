import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';

import { GetTeamTotalsByPkDocument } from '../../../../generated/graphql';
import StoreMetrics from './storeMetrics';

interface Props {
	storeId: string;
}

const StoreMetricsForContainer = (props: Props): ReactElement => {
	const { data } = useQuery(GetTeamTotalsByPkDocument, {
		variables: {
			storeId: props.storeId
		},
		pollInterval: 10000 // Refresh every 10 seconds
	});

	return <StoreMetrics teamStores={data?.team_store || []} />;
};

export default StoreMetricsForContainer;
