import { useMutation, useQuery } from '@apollo/client';
import { DialogContent, Select, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';

import {
	GetStoreAvailableOptionsDocument,
	UpdateOrderItemOptionByIdDocument
} from '../../../../../../../../../../generated/graphql';
import DialogActionBar from '../../../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../../../blocks/dialogTitleHeader';
import InputField from '../../../../../../../../inputs/inputField';

interface Props {
	open: boolean;
	handleClose(saved?: boolean): void;
	availableOptionId: string;
	selectedAvailableOptionId: string;
	storeProductId: string;
	cartOptionId: string;
}

const EditCustomOptionDialog = (props: Props): ReactElement => {
	const classes = useStyles();
	const [selectedOption, setSelectedOption] = useState(props.selectedAvailableOptionId);
	const [updateValue] = useMutation(UpdateOrderItemOptionByIdDocument);
	const { data } = useQuery(GetStoreAvailableOptionsDocument, {
		variables: {
			available_option_id: props.availableOptionId,
			store_product_id: props.storeProductId
		}
	});

	const handleSave = async () => {
		const item = data?.store_product_has_options.find((pOption) => {
			return pOption.available_option_value_id === selectedOption;
		});

		await updateValue({
			variables: {
				id: props.cartOptionId,
				available_option_value_id: selectedOption,
				value: item?.label_override ? item.label_override : item?.available_option_value?.label
			}
		});

		props.handleClose(true);
	};

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader title="Edit Custom Number" closeDialog={props.handleClose} />
			<DialogContent>
				<Typography variant="h5">Edit Custom Value</Typography>
				<Select
					variant="outlined"
					native
					value={selectedOption}
					onChange={(e: any) => {
						setSelectedOption(e.target.value);
					}}
				>
					{data?.store_product_has_options.map((item) => {
						return (
							<option key={item.id} value={item.available_option_value_id}>
								{item.label_override ? item.label_override : item.available_option_value?.label}
							</option>
						);
					})}
				</Select>
			</DialogContent>
			<DialogActionBar saveAction={handleSave} cancelAction={props.handleClose} />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {}
	};
});

export default EditCustomOptionDialog;
