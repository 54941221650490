import '@remirror/styles/all.css';

import { css } from '@emotion/css';
import { IconButton, SvgIcon } from '@material-ui/core';
import { mdiLanguageMarkdown, mdiLanguageMarkdownOutline } from '@mdi/js';
import {
	MarkdownToolbar,
	PlaceholderExtension,
	ReactExtensions,
	Remirror,
	ThemeProvider,
	UseRemirrorReturn,
	useRemirror
} from '@remirror/react';
import { createContextState } from 'create-context-state';
import React, { ReactElement, useState } from 'react';
import jsx from 'refractor/lang/jsx.js';
import md from 'refractor/lang/markdown.js';
import typescript from 'refractor/lang/typescript.js';
import { ExtensionPriority, getThemeVar } from 'remirror';
import {
	BlockquoteExtension,
	BoldExtension,
	BulletListExtension,
	CodeBlockExtension,
	CodeExtension,
	DocExtension,
	HardBreakExtension,
	HeadingExtension,
	ItalicExtension,
	LinkExtension,
	ListItemExtension,
	MarkdownExtension,
	OrderedListExtension,
	StrikeExtension,
	TableExtension,
	TrailingNodeExtension
} from 'remirror/extensions';

interface Context extends Props {
	setMarkdown: (markdown: string) => void;
	setVisual: (markdown: string) => void;
}

interface Props {
	visual: UseRemirrorReturn<ReactExtensions<ReturnType<any>[number]>>;
	markdown: UseRemirrorReturn<ReactExtensions<DocExtension | CodeBlockExtension>>;
	onChange?: (md: string | null) => void;
}

const [DualEditorProvider, useDualEditor] = createContextState<Context, Props>(({ props }) => {
	return {
		...props,

		setMarkdown: (text: string) => {
			if (props.onChange) {
				if (text && text.length > 0) {
					props.onChange(text);
				} else {
					props.onChange(null);
				}
			}
			return props.markdown.getContext()?.setContent({
				type: 'doc',
				content: [
					{
						type: 'codeBlock',
						attrs: { language: 'markdown' },
						content: text ? [{ type: 'text', text }] : undefined
					}
				]
			});
		},
		setVisual: (markdown: string) => {
			return props.visual.getContext()?.setContent(markdown);
		},
		onChange: () => {
			props.visual.onChange;
		}
	};
});

const MarkdownTextEditor = () => {
	const { markdown, setVisual } = useDualEditor();

	return (
		<Remirror
			manager={markdown.manager}
			autoRender="end"
			onChange={({ helpers, state }) => {
				const text = helpers.getText({ state });
				return setVisual(text);
			}}
			classNames={[
				css`
					&.ProseMirror {
						padding: 0;

						pre {
							height: 100%;
							padding: ${getThemeVar('space', 3)};
							margin: 0;
						}
					}
				`
			]}
		>
			{/* <Toolbar items={toolbarItems} refocusEditor label='Top Toolbar' /> */}
		</Remirror>
	);
};

const VisualEditor = () => {
	const { visual, setMarkdown } = useDualEditor();

	return (
		<Remirror
			manager={visual.manager}
			autoRender="end"
			onChange={({ helpers, state }) => {
				return setMarkdown(helpers.getMarkdown(state));
			}}
			initialContent={visual.state}
			classNames={[
				css`
					&.ProseMirror {
						p,
						h3,
						h4 {
							margin-top: ${getThemeVar('space', 2)};
							margin-bottom: ${getThemeVar('space', 2)};
						}

						h1,
						h2 {
							margin-bottom: ${getThemeVar('space', 3)};
							margin-top: ${getThemeVar('space', 3)};
						}
					}
				`
			]}
		>
			<MarkdownToolbar />
		</Remirror>
	);
};

interface EditorProps {
	content?: string | null;
	onChange?: (md: string | null) => void;
	placeholder?: string;
}

/**
 * The editor which is used to create the annotation. Supports formatting.
 */
export const MarkdownEditor = ({ content, onChange, placeholder }: EditorProps): ReactElement => {
	const [showSource, setShowSource] = useState(false);
	const visual = useRemirror({
		extensions: () => [
			new LinkExtension({ autoLink: true }),
			new BoldExtension(),
			new StrikeExtension(),
			new ItalicExtension(),
			new HeadingExtension(),
			new LinkExtension(),
			new BlockquoteExtension(),
			new BulletListExtension({ enableSpine: true }),
			new OrderedListExtension(),
			new ListItemExtension({ priority: ExtensionPriority.High, enableCollapsible: true }),
			new CodeExtension(),
			new CodeBlockExtension({ supportedLanguages: [jsx, typescript] }),
			new TrailingNodeExtension(),
			new TableExtension(),
			new MarkdownExtension({ copyAsMarkdown: false }),
			/**
			 * `HardBreakExtension` allows us to create a newline inside paragraphs.
			 * e.g. in a list item
			 */
			new HardBreakExtension(),
			new PlaceholderExtension({ placeholder: placeholder })
		],
		stringHandler: 'markdown',
		content: content || ''
	});
	const markdown = useRemirror({
		extensions: () => [
			new DocExtension({ content: 'codeBlock' }),
			new CodeBlockExtension({
				supportedLanguages: [md, typescript],
				defaultLanguage: 'markdown',
				syntaxTheme: 'base16_ateliersulphurpool_light',
				defaultWrap: true
			})
		],
		builtin: {
			exitMarksOnArrowPress: false
		},

		stringHandler: 'html'
	});

	return (
		<DualEditorProvider visual={visual} markdown={markdown} onChange={onChange}>
			<ThemeProvider>
				<div style={{ display: showSource ? 'none' : 'block' }}>
					<VisualEditor />
				</div>
				<div style={{ display: showSource ? 'block' : 'none' }}>
					<MarkdownTextEditor />
				</div>
				<div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
					<IconButton style={{ padding: 5 }} onClick={() => setShowSource(!showSource)}>
						<SvgIcon>
							{showSource ? <path d={mdiLanguageMarkdown} /> : <path d={mdiLanguageMarkdownOutline} />}
						</SvgIcon>
					</IconButton>
				</div>
			</ThemeProvider>
		</DualEditorProvider>
	);
};
