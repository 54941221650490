import { Collapse, IconButton, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiChevronUp } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';

import P5 from '../../../../../../../../core/components/typography/p5';
import { SYSTEM_COLORS } from '../../../../../../../../core/config/colors';
import OptionValueList from '../optionValueList';

interface Props {
	storeProductId: string;
	dragHandle: ReactElement;
	label: string;
	description?: string;
	available_option_id: string;
}

const Option = (props: Props): ReactElement => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	return (
		<div className={classes.container}>
			<div className={classes.labelContainer}>
				{props.dragHandle}
				<div className={classes.context}>
					<Typography variant="body2" className={classes.label}>
						{props.label}
					</Typography>
					{props.description && <P5 className={classes.description}>{props.description}</P5>}
				</div>
				<div>
					<IconButton
						onClick={(): void => {
							setOpen(!open);
						}}
					>
						<SvgIcon className={clsx(classes.chevron, open ? classes.chevronFlip : null)}>
							<path d={mdiChevronUp} />
						</SvgIcon>
					</IconButton>
				</div>
			</div>
			<Collapse in={open} unmountOnExit>
				<OptionValueList optionValueId={props.available_option_id} storeProductId={props.storeProductId} />
			</Collapse>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			backgroundColor: '#FFF',
			borderRadius: 8,
			padding: '0 7px',
			margin: '14px 0'
		},
		labelContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		context: {
			flex: 1,
			paddingLeft: 10
		},
		label: {
			fontWeight: 600,
			color: SYSTEM_COLORS.GRASCALE_BLACK
		},
		description: {
			color: SYSTEM_COLORS.GRASCALE_GRAY_DARK
		},
		chevron: {
			color: SYSTEM_COLORS.GRAY,
			transition: 'all .3s'
		},
		chevronFlip: {
			'-webkit-transform': 'rotate(180deg)',
			'-moz-transform': 'rotate(180deg)',
			'-ms-transform': 'rotate(180deg)',
			'-o-transform': 'rotate(180deg)',
			transform: 'rotate(180deg)'
		}
	};
});

export default Option;
