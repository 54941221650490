import { useQuery } from '@apollo/client';
import { CardActionArea, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiTshirtCrewOutline } from '@mdi/js';
import dayjs from 'dayjs';
import React, { ReactElement, useState } from 'react';

import { GetTeamStoreGroupsApartOfDocument, TeamGroupBasicsFragment } from '../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../config/colors';
import NoEntryContainer from '../../../../blocks/containerNoEntries/containerNoEntries';
import PaperHeaderContainer from '../../../../blocks/paperHeaderContainer';
import ViewOrders from './viewOrders';

interface Props {
	storeId: string;
}

const OrdersFromGroup = (props: Props): ReactElement => {
	const classes = useStyles();

	const [selectedGroup, setSelectedGroup] = useState<undefined | TeamGroupBasicsFragment>(undefined);
	const { data } = useQuery(GetTeamStoreGroupsApartOfDocument, {
		variables: {
			storeId: props.storeId
		}
	});

	if (selectedGroup) {
		return (
			<ViewOrders
				groupStore={selectedGroup}
				storeId={props.storeId}
				closeView={(): void => {
					setSelectedGroup(undefined);
				}}
			/>
		);
	}

	return (
		<>
			{!data ||
				(data.showcase.length === 0 && (
					<NoEntryContainer
						title="No Groups Found"
						iconPath={mdiTshirtCrewOutline}
						description="This store is not apart of any groups at this time."
					/>
				))}
			{data && data.showcase.length > 0 && (
				<>
					<PaperHeaderContainer className={classes.header}>
						<Typography variant="h4">Groups you're apart of</Typography>
					</PaperHeaderContainer>

					{data.showcase.map((showcase) => {
						return (
							<CardActionArea
								className={classes.group}
								onClick={(): void => {
									setSelectedGroup(showcase.team_store);
								}}
							>
								<img className={classes.image} src={showcase.team_store.image_url || ''} />
								<div style={{ flex: 1 }}>
									<Typography variant="h4">{showcase.team_store.name}</Typography>
									<Typography variant="h6">as {showcase.label}</Typography>
								</div>
								<div>
									<Typography variant="h5">
										Joined {dayjs(showcase.created_at).format('MMM D, YYYY')}
									</Typography>
								</div>
							</CardActionArea>
						);
					})}
				</>
			)}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		group: {
			padding: 10,
			borderRadius: 8,
			cursor: 'pointer',
			display: 'flex',
			flex: 1
		},
		image: {
			marginRight: 15,
			widht: 50,
			height: 50
		},
		header: {
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: '0px 0 10px'
		}
	};
});

export default OrdersFromGroup;
