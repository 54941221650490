import React, { ReactElement, useEffect, useState } from "react";
import { Theme, makeStyles, SvgIcon, Select, MenuItem, TextField, IconButton } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import { mdiContentSave, mdiDelete, mdiMagnify, mdiPlusCircle } from "@mdi/js";
import { SearchSet } from "./searchSets";

interface Props {
  searchSet?: SearchSet;
  onChange?: (searchSet: SearchSet | null) => void;
}

const SearchBox = ({ searchSet, onChange }: Props): ReactElement => {
  const classes = useStyles();
  const [label, setLabel] = useState("");
  const [terms, setTerms] = useState<any[]>([]);
  const [filterType, setFilterType] = useState("and");

  useEffect(() => {
    if (searchSet) {
      setFilterType(searchSet.filterType);
      setTerms(searchSet.terms);
      setLabel(searchSet.label);
    }
  }, [searchSet]);

  const handleAdd = (chip: any) => {
    const clonedTerms = [...terms];
    clonedTerms.push(chip);
    setTerms(clonedTerms);
  };

  const handleDelete = (chip: any, index: number) => {
    const clonedTerms = [...terms];
    clonedTerms.splice(terms.indexOf(chip), 1);
    setTerms(clonedTerms);
  };

  const handleFilterTypeChange = (e: any) => {
    setFilterType(e.target.value);
  };

  const handleLabelChange = (e: any) => {
    setLabel(e.target.value);
  };

  const handleAddSet = () => {
    if (onChange) {
      if(canAddSet()) {
        // add or save
        onChange({ label: label, terms: terms, filterType: filterType });
        if (!searchSet) {
          setLabel("");
          setTerms([]);
        }
      }else{
        // delete
        onChange(null);
        setLabel("");
        setTerms([]);
      }
    }
  };

  const canAddSet = () => {
    let allowed = true;
    if (label.length === 0) {
      allowed = false;
    }
    if (terms.length === 0) {
      allowed = false;
    }
    return allowed;
  };

  const canSave = () => {
    let allowed = false;
    if (label !== searchSet?.label) {
      allowed = true;
    }
    if (filterType !== searchSet?.filterType) {
      allowed = true;
    }
    if (terms !== searchSet?.terms) {
      allowed = true;
    }
    return allowed;
  };

  return (
    <div className={classes.container}>
      <TextField
        className={classes.labelField}
        placeholder={"Category"}
        variant={"outlined"}
        value={label}
        onChange={handleLabelChange}
      />
      <div className={classes.searchBox}>
        <SvgIcon className={classes.icon}>
          <path d={mdiMagnify} />
        </SvgIcon>
        <ChipInput
          value={terms}
          onAdd={handleAdd}
          onDelete={handleDelete}
          variant={"standard"}
          placeholder={"Search Terms"}
          disableUnderline={true}
          margin={"dense"}
          fullWidth={true}
        />
        <Select
          className={classes.filterSelect}
          disableUnderline={true}
          value={filterType}
          onChange={handleFilterTypeChange}
        >
          <MenuItem value={"or"}>Match Any</MenuItem>
          <MenuItem value={"and"}>Match All</MenuItem>
        </Select>
      </div>
      <div>
        {searchSet ?
          <div style={{ display: "flex" }}>
            {canAddSet() ?
              <IconButton onClick={handleAddSet} disabled={!canSave()}>
                <SvgIcon fontSize={"large"}>
                  <path d={mdiContentSave} />
                </SvgIcon>
              </IconButton>
              :
              <IconButton onClick={handleAddSet}>
                <SvgIcon fontSize={"large"}>
                  <path d={mdiDelete} />
                </SvgIcon>
              </IconButton>
            }
          </div>
          :
          <IconButton onClick={handleAddSet} disabled={!canAddSet()}>
            <SvgIcon fontSize={"large"}>
              <path d={mdiPlusCircle} />
            </SvgIcon>
          </IconButton>
        }
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: "flex"
    },
    labelField: {
      width: 150,
      minWidth: 150,
      marginRight: 5
    },
    searchBox: {
      display: "flex",
      alignItems: "center",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 4,
      borderColor: theme.palette.secondary.light
    },
    icon: {
      marginLeft: 10,
      marginRight: 10
    },
    filterSelect: {
      fontSize: 12,
      color: theme.palette.secondary.dark,
      marginRight: 10
    }
  };
});

export default SearchBox;
