import { useQuery } from '@apollo/client';
import React, { ReactElement, ReactNode, createContext } from 'react';

import { GetTeamByPkDocument } from '../../generated/graphql';

interface TeamContext {
	team: any;
	refetch(): void;
}

export const TeamContext = createContext<TeamContext | undefined>(undefined);

interface Props {
	teamId: string;
	children: ReactNode;
}

export const TeamProvider = (props: Props): ReactElement => {
	const { data, refetch, loading } = useQuery(GetTeamByPkDocument, {
		variables: {
			id: props.teamId
		}
	});

	if (loading || !data?.team_by_pk) {
		return <section key={props.teamId}>Loading</section>;
	}

	return (
		<TeamContext.Provider
			value={{
				refetch,
				team: data.team_by_pk
			}}
		>
			{props.children}
		</TeamContext.Provider>
	);
};

export const useTeamContext = (): TeamContext => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return React.useContext(TeamContext)!;
};

export default TeamProvider;
