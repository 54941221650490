import { Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import ButtonBase, { ExtendedBaseProps } from '../buttonBase/buttonBase';

const SecondadryButton = (props: ExtendedBaseProps): ReactElement => {
	const classes = useStyles();
	const { children, ...rest } = props;

	return (
		<ButtonBase variant="contained" color="secondary" {...rest}>
			{children}
		</ButtonBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {};
});

export default SecondadryButton;
