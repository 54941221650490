import { useQuery } from '@apollo/client';
import { Link, Paper, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiPlusCircle } from '@mdi/js';
import dayjs from 'dayjs';
import React, { Fragment, ReactElement, useState } from 'react';

import PaperHeaderContainer from '../../core/components/blocks/paperHeaderContainer';
import RowBlock from '../../core/components/blocks/rowBlock';
import PrimaryButton from '../../core/components/buttons/primaryButton';
import SecondaryButton from '../../core/components/buttons/secondaryButton';
import P3 from '../../core/components/typography/p3';
import P6 from '../../core/components/typography/p6';
import { SYSTEM_COLORS } from '../../core/config/colors';
import { findEnvAttr } from '../../core/utilites/process';
import { GetCustomLinkProductsDocument } from '../../generated/graphql';
import DialogCreateLink from './components/dialogCreateLink';
import DialogCreateStripeProduct from './components/dialogCreateStripeProduct';

interface Props {
	c?: any;
}

const CustomLink = (props: Props): ReactElement => {
	const classes = useStyles();
	const [dialogAdd, setDialogAdd] = useState(false);
	const [dialogCreateLink, setDialogCreateLink] = useState<string | undefined>(undefined);

	const { data, refetch } = useQuery(GetCustomLinkProductsDocument);

	const handleOpen = (): void => {
		setDialogAdd(true);
	};

	return (
		<Paper>
			<PaperHeaderContainer>
				<Typography variant="h3">Custom Link Products</Typography>
				<div>
					<PrimaryButton
						onClick={handleOpen}
						startIcon={
							<SvgIcon>
								<path d={mdiPlusCircle} />
							</SvgIcon>
						}
					>
						New Product
					</PrimaryButton>
				</div>
			</PaperHeaderContainer>

			<div className={classes.graphContainer}>
				<div className={classes.results}>
					{data?.getCustomLinkProducts?.map((product) => {
						if (!product) {
							return null;
						}

						return (
							<RowBlock
								key={product.id}
								label={product.name || ''}
								subLabel={product.description}
								updatedAt={product.updated}
							>
								<>
									<SecondaryButton
										buttonSizeOverride="small"
										component={Link}
										href={`${findEnvAttr('REACT_APP_PUBLIC_URL')}generate-link/${product.id}`}
										target="_blank"
									>
										Public Url
									</SecondaryButton>
									<PrimaryButton
										buttonSizeOverride="small"
										onClick={(): void => {
											// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											setDialogCreateLink(product.id!);
										}}
									>
										Create Price
									</PrimaryButton>
								</>
							</RowBlock>
						);
					})}
				</div>
			</div>
			{dialogCreateLink && (
				<DialogCreateLink
					handleClose={(): void => {
						setDialogCreateLink(undefined);
					}}
					open={true}
					productId={dialogCreateLink}
				/>
			)}
			<DialogCreateStripeProduct
				handleClose={(): void => {
					refetch();
					setDialogAdd(false);
				}}
				open={dialogAdd}
			/>
		</Paper>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		results: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '10px'
		},
		graphContainer: {
			padding: 15,
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '15px',
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.GRAYSCALE_GRAY_LIGHTEST
		}
	};
});

export default CustomLink;
