import { useAuth0 } from '@auth0/auth0-react';
import { Theme, makeStyles } from '@material-ui/core';
import { Breadcrumbs, Button, Grid } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import PrimaryButton from '../../core/components/buttons/primaryButton';
import { useAuth } from '../../providers/authProvider/authProvider';

interface Props {
	c?: any;
}

const Login = (props: Props): ReactElement => {
	const classes = useStyles();
	const { token, loginWithProvider } = useAuth();
	const navigate = useNavigate();

	if (token) {
		loginWithProvider('email', { token }, navigate).then(() => {
			navigate('/');
		});
	}

	return (
		<Grid item sm={6} xs={12} display={'flex'} justifyContent={'center'}>
			<div className={'signin-button'}>
				<GoogleLogin
					onSuccess={(credentialResponse) => loginWithProvider('google', credentialResponse, navigate)}
					// onError={() => {}}
					width={'205px'}
				/>
			</div>
		</Grid>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {}
	};
});

export default Login;
