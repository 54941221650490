import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import IntegratedButton from '../integratedButton';

export interface SegmentedButtonControlOption {
	startIcon?: ReactElement;
	label?: string;
}

interface Props {
	buttonContainerClass?: string;
	selectedIndex: number;
	setSelectedIndex(index: number): void;
	buttonsConfig: SegmentedButtonControlOption[];
	customButtonClass?: string;
	selectedButtonClass?: string;
}

const SegmentedButtonControl = (props: Props): ReactElement => {
	const classes = useStyles();
	const { selectedIndex, setSelectedIndex } = props;

	const selectedButtonClass = props.selectedButtonClass || classes.selectedButton;

	return (
		<div className={clsx(classes.buttonContainer, props.buttonContainerClass)}>
			{props.buttonsConfig.map((option, i) => {
				return (
					<IntegratedButton
						key={option.label}
						onClick={(): void => {
							setSelectedIndex(i);
						}}
						className={clsx(
							classes.button,
							props.customButtonClass,
							selectedIndex === i ? selectedButtonClass : null
						)}
						startIcon={option.startIcon}
					>
						{option.label}
					</IntegratedButton>
				);
			})}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		buttonContainer: {
			borderRadius: 8,
			backgroundColor: '#fff'
		},
		button: {
			color: SYSTEM_COLORS.GRAY
		},
		selectedButton: {
			backgroundColor: SYSTEM_COLORS.BACKGROUND,
			color: SYSTEM_COLORS.GRAY_SHADE
		}
	};
});

export default SegmentedButtonControl;
