import { useQuery } from '@apollo/client';
import { Button, SvgIcon, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement, useMemo, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import SegmentedButtonControl from '../../../../core/components/buttons/segmentedButtonControl';
import { GetOriginOrdersDocument } from '../../../../generated/graphql';
import OverviewContainer from '../common/overviewContainer';
import OverviewHeader from '../common/overviewHeader';
import CustomTooltip, { mapLabelToReadable } from './customTooltip';

interface OrderData {
	count: number;
	total: number | null;
}

interface ChartData {
	name: string;
	value: number | string; // Updated to include string for formatted values
}

const getTotal = (data: ChartData[]) => {
	return data.reduce((acc, item) => acc + parseInt(`${item.value}`, 10), 0);
};

// Custom formatter function for the legend
const renderColorfulLegendText = (value: string) => {
	return <span>{mapLabelToReadable(value)}</span>;
};
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82ca9d'];

const OrderOrigins = (): ReactElement | null => {
	const classes = useStyles();
	const [selectedOption, setSelectedOption] = useState(0);
	const { data, refetch, loading } = useQuery(GetOriginOrdersDocument, {
		fetchPolicy: 'network-only'
	});

	const chartData = useMemo(() => {
		if (!data) {
			return undefined;
		}

		const formedData = {
			collegeOrders: {
				count: data.collegeOrders.aggregate?.count || 0,
				total: data.collegeOrders.aggregate?.sum?.total || 0
			},
			highSchoolOrders: {
				count: data.highSchoolOrders.aggregate?.count || 0,
				total: data.highSchoolOrders.aggregate?.sum?.total || 0
			},
			middleSchoolOrders: {
				count: data.middleSchoolOrders.aggregate?.count || 0,
				total: data.middleSchoolOrders.aggregate?.sum?.total || 0
			},
			clubOrders: {
				count: data.clubOrders.aggregate?.count || 0,
				total: data.clubOrders.aggregate?.sum?.total || 0
			},
			noneOrders: {
				count: data.noneOrders.aggregate?.count || 0,
				total: data.noneOrders.aggregate?.sum?.total || 0
			}
		};

		const processData = (data: Record<string, OrderData>): ChartData[] => {
			return Object.keys(data).map((key) => ({
				name: key,
				value: selectedOption === 0 ? data[key].count : data[key].total || 0
			}));
		};

		return processData(formedData);
	}, [data, selectedOption]);

	const options = useMemo(
		() => [
			{
				label: 'Count'
			},
			{
				label: 'Amount'
			}
		],
		[]
	);

	if (loading) {
		return <>loading</>;
	}

	if (!chartData) {
		return null;
	}

	const total = getTotal(chartData);

	return (
		<OverviewContainer>
			<OverviewHeader title="Order Origins" loading={false} description="Breakdown of orders by origin">
				<SegmentedButtonControl
					buttonContainerClass={classes.segmentedButtonControlContainer}
					buttonsConfig={options}
					customButtonClass={classes.customButton}
					selectedIndex={selectedOption}
					setSelectedIndex={setSelectedOption}
				/>
			</OverviewHeader>

			<div style={{ width: '100%', height: '400px', position: 'relative' }}>
				<ResponsiveContainer width="100%" height="100%">
					<PieChart>
						<Pie
							dataKey="value"
							isAnimationActive={false}
							data={chartData}
							cx="50%"
							cy="50%"
							outerRadius="80%"
							fill="#8884d8"
							label={false}
						>
							{chartData.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
						<Tooltip content={<CustomTooltip isCurrency={selectedOption === 1} total={total} />} />
						<Legend formatter={renderColorfulLegendText} />
					</PieChart>
				</ResponsiveContainer>
			</div>
		</OverviewContainer>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		segmentedButtonControlContainer: {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				display: 'flex'
			}
		},
		customButton: {
			margin: 5,
			padding: '5px 12px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				padding: '10px 12px'
			}
		}
	};
});

export default OrderOrigins;
