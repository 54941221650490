import { Paper, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import PaperHeaderContainer from '../../core/components/blocks/paperHeaderContainer';
import { SYSTEM_COLORS } from '../../core/config/colors';
import LatestOrders from './components/latestOrders';
import OpenStores from './components/openStores';
import OrderOrigins from './components/orderOrigins';
import ActivityOrigins from './components/orderOrigins/activityOrigins';
import OrdersAwaitingShipping from './components/ordersAwaitingShipping';

interface Props {
	c?: any;
}

const Overview = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<Paper className={classes.container}>
			<PaperHeaderContainer className={classes.header}>
				<Typography variant="h3">Overview</Typography>
			</PaperHeaderContainer>
			<div className={classes.graphContainer}>
				<OrdersAwaitingShipping />
				<OpenStores />
				<LatestOrders />
				<OrderOrigins />
				<ActivityOrigins />
			</div>
		</Paper>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			padding: 16
		},
		graphContainer: {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 3fr)',
			gap: '15px',
			padding: 16,
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.GRAYSCALE_GRAY_LIGHTEST,
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: 'repeat(1, 1fr)'
			}
		},
		header: {
			marginBottom: 0
		}
	};
});

export default Overview;
