import { Box, Checkbox, IconButton, SvgIcon, TableCell, TableRow, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiCheckbook, mdiCreditCard, mdiPlus, mdiPrinterPosCheckOutline } from '@mdi/js';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { ReactElement, useState } from 'react';

import { TeamStoreOrderBasicFragment } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import { formatCurrencyValueFromServer } from '../../../../../../../utilites/currenty';
import AftershipChip from '../../../../../../blocks/aftershipChip';
import P4 from '../../../../../../typography/p4/p4';
import P5 from '../../../../../../typography/p5';
import P6 from '../../../../../../typography/p6';
import ViewOrder from '../../../viewOrder';
import { ReactComponent as EgyptLogo } from './assets/egypt.svg';
import { ReactComponent as UsaLogo } from './assets/us.svg';
import ActionButton from './components/actionButton';
import IndicatorStatus from './components/orderStatusIndicator';

interface Props {
	order: TeamStoreOrderBasicFragment;
	isItemSelected: boolean;
	handleCheckboxClick(orderId: string): void;
	refetchMetrics?(): void;
	refetchStore?(): void;
	disableSelectedRow?: boolean;
}
const currentYear = dayjs().format(', YYYY');

const OrderRow = (props: Props): ReactElement => {
	const classes = useStyles();
	const { order, isItemSelected, handleCheckboxClick, disableSelectedRow } = props;

	const [expanded, setExpanded] = useState<number | null>(null);
	const handleExpandClick = (id: number) => {
		setExpanded(expanded === id ? null : id);
	};

	return (
		<>
			<TableRow selected={isItemSelected} className={classes.tableRow}>
				{!disableSelectedRow && (
					<TableCell className={classes.checkboxCell}>
						<Checkbox
							checked={isItemSelected}
							onChange={(event: any) => {
								handleCheckboxClick(order.id);
							}}
						/>
					</TableCell>
				)}
				<TableCell className={classes.orderCell}>
					<div className={classes.orderCellItem}>
						<IconButton aria-label="expand row" size="small" onClick={(event) => handleExpandClick(order.id)}>
							<SvgIcon>
								<path d={mdiPlus} />
							</SvgIcon>
						</IconButton>
						{order.order_readable_column}
					</div>
				</TableCell>

				<TableCell className={classes.customerCell}>
					{order.stripe_payment_id && (
						<a
							className={clsx(classes.strongContent, classes.onHover)}
							href={`https://dashboard.stripe.com/payments/${order.stripe_payment_id}`}
							target="_blank"
							referrerPolicy="no-referrer"
						>
							{order.customer_name}
						</a>
					)}
					{!order.stripe_payment_id && order.customer_name}
					<br />
					{order.customer_email}
				</TableCell>

				<TableCell className={classes.dateCell}>
					{dayjs(order.created_at).format('MMM D, YYYY').replace(currentYear, '')}
				</TableCell>

				<IndicatorStatus orderId={order.id} status={order.status} />
				<TableCell>
					{order.aftership_id && order.aftership_status_tag && (
						<AftershipChip aftershipId={order.aftership_id} aftershipStatus={order.aftership_status_tag} />
					)}
				</TableCell>
				<TableCell className={classes.purchaseInfoCell}>
					<div className={classes.iconContainer}>
						{order.shipping_origin === 'EGYPT' ? (
							<EgyptLogo className={classes.flag} />
						) : (
							<UsaLogo className={classes.flag} />
						)}
						{order.checkout_type === 'card' && (
							<SvgIcon className={classes.subIcon}>
								<path d={mdiCreditCard} />
							</SvgIcon>
						)}
						{order.checkout_type === 'check' && (
							<SvgIcon className={classes.subIcon}>
								<path d={mdiCheckbook} />
							</SvgIcon>
						)}
						{typeof order.shipping_label_url === 'string' && order.shipping_label_url !== '' && (
							<SvgIcon className={clsx(classes.subIcon, classes.successIcon)}>
								<path d={mdiPrinterPosCheckOutline} />
							</SvgIcon>
						)}
					</div>
				</TableCell>
				<TableCell className={classes.totalCell}>
					{order.total && (
						<Typography variant="body1" className={classes.strongContent}>
							{formatCurrencyValueFromServer(order.total)}
						</Typography>
					)}
					{order.original_order_price && order.original_order_price !== order.total && (
						<P6 className={clsx(classes.gray, classes.crossThrough)}>
							{formatCurrencyValueFromServer(order.original_order_price)}
						</P6>
					)}
				</TableCell>
				<TableCell className={classes.actionsCell}>
					<ActionButton
						receiptUrl={props.order.receipt_url || undefined}
						orderTotal={props.order.total || undefined}
						shippingLabelUrl={props.order.shipping_label_url || undefined}
						orderId={props.order.id}
						refetchStore={() => (props.refetchStore ? props.refetchStore() : null)}
					/>
				</TableCell>
			</TableRow>
			{expanded && (
				<TableRow>
					<TableCell colSpan={8}>
						<Box p={2}>
							<ViewOrder
								refetchStore={() => (props.refetchStore ? props.refetchStore() : null)}
								orderId={order.id}
							/>
						</Box>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		tableRow: {},
		orderCell: {
			padding: 4
		},
		strongContent: {
			fontWeight: 'bold',
			color: SYSTEM_COLORS.BLACK_SHADE,
			textDecoration: 'none'
		},
		totalCell: {
			padding: 4,
			fontWeight: 'bold',
			width: 75
		},
		purchaseInfoCell: {
			textAlign: 'center',
			padding: 4,
			width: 50
		},
		actionsCell: {
			padding: 4,
			width: 80,
			textAlign: 'right',
			paddingRight: 20
		},
		subIcon: {
			color: SYSTEM_COLORS.LIGHT_GRAY_30,
			width: 16,
			height: 16
		},
		successIcon: {
			color: SYSTEM_COLORS.GREEN_DEFAULT
		},
		statusCell: {
			padding: 4,
			fontWeight: 'bold',
			width: 235
		},
		orderCellItem: {
			justifyContent: 'normal',
			alignItems: 'center',
			display: 'flex'
		},
		customerCell: {
			padding: 4
			// width: 400
		},
		iconContainer: {
			display: 'flex',
			'& :not(:first-child)': {
				marginLeft: 5
			}
		},
		dateCell: {
			padding: 4,
			width: 110
		},
		checkboxCell: {
			padding: 4,
			width: 50
		},
		expandableCell: {
			padding: 4,
			width: 30
		},
		flag: {
			height: 16
		},
		gray: {
			color: SYSTEM_COLORS.GRAY
		},
		crossThrough: {
			textAlign: 'right',
			textDecoration: 'line-through'
		},
		onHover: {
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	};
});

export default OrderRow;
