import { Button, ButtonProps, Theme, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import { getRemFromPx } from '../../../config/theme';

interface Props extends ButtonProps {
	buttonSizeOverride?: 'large' | 'small';
}

const IntegratedButton = (props: Props): ReactElement => {
	const classes = useStyles();
	const { children, buttonSizeOverride, ...rest } = props;
	const theme = useTheme();
	const isFullscreen = useMediaQuery(theme.breakpoints.down('sm'));
	const buttonSize = buttonSizeOverride ? buttonSizeOverride : isFullscreen ? 'large' : 'small';

	return (
		<Button
			{...rest}
			variant="outlined"
			disableRipple
			classes={{
				root: clsx(classes.root, buttonSize === 'large' ? classes.largeButton : classes.smallButton),
				outlined: classes.outlined
			}}
		>
			{children}
		</Button>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		root: {
			margin: 8,
			borderRadius: 8,
			'&:disabled': {
				backgroundColor: 'transparent',
				color: SYSTEM_COLORS.GRAY_10
			}
		},
		largeButton: {
			// mobile
			fontSize: getRemFromPx(16),
			lineHeight: getRemFromPx(24),
			fontWeight: 600,
			padding: '12px 15px'
		},
		smallButton: {
			// desktop
			fontSize: getRemFromPx(12),
			lineHeight: getRemFromPx(18),
			fontWeight: 600,
			padding: '10px 17px'
		},
		outlined: {
			border: `none`,
			color: SYSTEM_COLORS.GRAY_SHADE,
			backgroundColor: 'transparent',
			boxShadow: 'none',
			'&:hover': {
				backgroundColor: SYSTEM_COLORS.BACKGROUND
			},
			'&:focusVisible': {
				backgroundColor: SYSTEM_COLORS.BACKGROUND
			},
			'&:active': {
				backgroundColor: SYSTEM_COLORS.BACKGROUND
			},
			'&:disabled': {
				backgroundColor: 'transparent',
				color: SYSTEM_COLORS.LIGHT_GRAY_30
			}
		}
	};
});

export default IntegratedButton;
