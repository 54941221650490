import { useMutation, useQuery } from '@apollo/client';
import { Paper, SvgIcon, Theme, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { mdiCog, mdiDotsHorizontal, mdiEyeOutline, mdiPackageDown, mdiTshirtCrewOutline } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'timeago.js';

import ButtonMenu, { MenuItemSpec } from '../../../../core/components/blocks/buttonMenu/buttonMenu';
import NoEntryContainer from '../../../../core/components/blocks/containerNoEntries/containerNoEntries';
import TableComponent from '../../../../core/components/blocks/tableComponent';
import P3 from '../../../../core/components/typography/p3';
import P5 from '../../../../core/components/typography/p5';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { formatCurrencyValueFromServer } from '../../../../core/utilites/currenty';
import { GetProductsDocument, UpdateCatalogItemSetArchiveDocument } from '../../../../generated/graphql';
import DialogAdd from '../dialogAdd';

interface Props {
	isArchived?: boolean;
	searchQuery?: string;
	isBundle?: boolean;
	dialogOpen?: boolean;
	handleClose(): void;
	public_only?: boolean;
}

const CatalogList = (props: Props): ReactElement => {
	const classes = useStyles();

	const whereClause = useMemo(() => {
		const where: any = {};
		if (props.public_only) {
			where.public_product = { _eq: props.public_only };
		}

		if (props.searchQuery) {
			where.label = { _ilike: `%${props.searchQuery}%` };
		}
		if (props.isBundle) {
			where.is_bundle = { _eq: props.isBundle };
		}

		return where;
	}, [props.public_only, props.searchQuery, props.isBundle]);

	const { data, refetch } = useQuery(GetProductsDocument, {
		variables: { whereClause },
		skip: !whereClause,
		fetchPolicy: 'no-cache'
	});
	const [archiveCategoryItem, { data: archiveData }] = useMutation(UpdateCatalogItemSetArchiveDocument);
	const navigate = useNavigate();

	useEffect(() => {
		if (archiveData) {
			refetch();
		}
	}, [archiveData]);

	const menuSpec: MenuItemSpec[] = useMemo(() => {
		return [
			{
				key: '1',
				label: 'Manage',
				onClick: (menuArgs: any) => {
					navigate(menuArgs.categoryId);
				},
				startIcon: (
					<SvgIcon className={classes.secondaryFill}>
						<path d={mdiCog} />
					</SvgIcon>
				)
			},
			{
				key: '5',
				label: props.isArchived ? 'Unarchive' : 'Archive',
				onClick: (menuArgs: any) => {
					archiveCategoryItem({
						variables: {
							id: menuArgs.categoryId,
							is_archived: !props.isArchived
						}
					});
				},
				hasDivider: true,
				startIcon: (
					<SvgIcon className={classes.warningFill}>
						<path d={mdiPackageDown} />
					</SvgIcon>
				)
			}
		].filter(Boolean);
	}, [props]);

	const storeData = useMemo(() => {
		if (!data) {
			return [];
		}

		if (props.isArchived) {
			return data.product.filter((product) => product.is_archived);
		}
		return data.product.filter((product) => !product.is_archived);
	}, [data, props.isArchived]);

	const columns = useMemo(() => {
		return [
			{
				Header: 'Product',
				accessor: 'label',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return (
						<>
							<div style={{ display: 'flex', alignItems: 'center', gap: '8px', paddingBottom: 8 }}>
								<Typography variant="body2">{row.label}</Typography>
								{row.public_product && (
									<Tooltip title="Public Product">
										<SvgIcon fontSize="small" className={classes.publicIcon}>
											<path d={mdiEyeOutline} />
										</SvgIcon>
									</Tooltip>
								)}
							</div>
							<Typography variant="h6">
								SKU Value: {row.sku} -{' '}
								{row.weight_in_ounces ? `Weight: ${row.weight_in_ounces} ounces` : 'No Weight Set'} - Shipping
								from: {row.shipping_origin}
							</Typography>
						</>
					);
				}
			},
			{
				Header: 'Price',
				accessor: 'base_price',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return (
						<>
							<Typography variant="body2" style={{ paddingBottom: 8 }}>
								{formatCurrencyValueFromServer(row.base_price)}
							</Typography>
							<P5>Prod: {formatCurrencyValueFromServer(row.production_price)}</P5>
						</>
					);
				}
			},
			{
				Header: 'Created on',
				accessor: 'created_at',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return (
						<>
							<P3
								className={clsx({
									[classes.isActive]: row.is_active
								})}
							>
								{row.is_active ? 'Active' : 'Inactive'}
							</P3>
							<Typography variant="h6">Created {format(row.created_at)}</Typography>
						</>
					);
				}
			},
			{
				Header: 'Actions',
				accessor: 'id',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;

					return <ButtonMenu iconPath={mdiDotsHorizontal} menuSpec={menuSpec} menuArg={{ categoryId: row.id }} />;
				}
			}
		];
	}, [data?.product]);

	return (
		<Paper>
			<TableComponent columns={columns} data={storeData || []} globalFilter={props.searchQuery} />
			{!storeData && (
				<NoEntryContainer
					baseColor={SYSTEM_COLORS.SECONDARY}
					title="No Products exist in the Catalog"
					iconPath={mdiTshirtCrewOutline}
					description="You currently do not have any products in the catalog, please start adding them."
				/>
			)}
			<DialogAdd handleClose={props.handleClose} open={!!props.dialogOpen} refetch={refetch} />
		</Paper>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {},
		isActive: {
			fontWeight: 'bold',
			color: SYSTEM_COLORS.GREEN_DEFAULT
		},
		secondaryFill: {
			color: SYSTEM_COLORS.OPT_SECONDARY
		},
		warningFill: {
			color: SYSTEM_COLORS.WARNING
		},
		publicIcon: {
			color: SYSTEM_COLORS.PRIMARY,
			fontSize: '1rem'
		}
	};
});

export default CatalogList;
