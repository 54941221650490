import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import Store from '../../core/components/connected/store';
import GroupType from './groupType';

const GroupRender = (): ReactElement => {
	return (
		<Routes>
			<Route path={':storeId/*'} element={<Store />} />
			<Route path="/" element={<GroupType />} />
		</Routes>
	);
};

export default GroupRender;
