import { useMutation } from '@apollo/client';
import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DialogActionBar from '../../../../core/components/blocks/dialogActionBar';
import DialogBase from '../../../../core/components/blocks/dialogBase';
import DialogTitleHeader from '../../../../core/components/blocks/dialogTitleHeader';
import RowToggleWithSwitch from '../../../../core/components/blocks/rowToggleWithSwitch';
import InputField from '../../../../core/components/inputs/inputField';
import { Insert_ProductDocument } from '../../../../generated/graphql';

interface Props {
	refetch(): void;
	open: boolean;
	handleClose(success?: boolean): void;
}

const DialogAdd = (props: Props): ReactElement => {
	const [title, setTitle] = useState('');
	const [isBundle, setIsBundle] = useState(false);
	const [addProduct, { loading, data }] = useMutation(Insert_ProductDocument);
	const navigate = useNavigate();

	useEffect(() => {
		if (data?.insert_product?.returning) {
			props.handleClose(true);
			props.refetch();
			setTimeout(() => {
				if (data?.insert_product?.returning[0].id) {
					navigate(data?.insert_product?.returning[0].id);
				}
			}, 500);
		}
	}, [data]);

	const handleSubmit = (): void => {
		addProduct({
			variables: {
				label: title,
				is_bundle: isBundle
			}
		});
	};

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader title="Create new product" closeDialog={props.handleClose} />
			<DialogContent>
				<InputField
					onKeyDown={(e) => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							handleSubmit();
							e.preventDefault();
						}
					}}
					onChange={(e): void => {
						setTitle(e.target.value);
					}}
					variant="outlined"
					label="Product Name"
					fullWidth
					helperText="You can always change this at a later point"
					value={title}
				/>
				<RowToggleWithSwitch
					title="Is Bundle"
					handleUpdate={(): void => {
						setIsBundle(!isBundle);
					}}
					switchState={isBundle}
					disabled={loading}
				/>
			</DialogContent>
			<DialogActionBar cancelAction={props.handleClose} saveAction={handleSubmit} disableSave={loading} />
		</DialogBase>
	);
};

export default DialogAdd;
