import { DocumentNode, useMutation } from '@apollo/client';
import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { ReactElement, ReactNode, useState } from 'react';

import DialogActionBar from '../../blocks/dialogActionBar';
import DialogBase from '../../blocks/dialogBase';
import DialogTitleHeader from '../../blocks/dialogTitleHeader';
import PrimaryButton from '../../buttons/primaryButton';

interface Props {
	buttonLabel: string;
	title: string;
	description: ReactNode;
	dialogMutationOnConfirmation: DocumentNode;
	dialogMutationVariables: any;
	keyName: string;
	onChange: ()=>void;
}

const ButtonToDialogCommit = (props: Props): ReactElement => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [dialogState, setDialogState] = useState(false);
	const [executeDialogConfirmation, { loading }] = useMutation(props.dialogMutationOnConfirmation);

	const handleClose = () => {
		setDialogState(false);
	};

	const handleOpen = () => {
		setDialogState(true);
	};

	const handleSubmit = async (): Promise<void> => {
		try {
			const e = await executeDialogConfirmation({
				variables: props.dialogMutationVariables
			});

			if (e?.data?.[props.keyName]?.error) {
				enqueueSnackbar(e?.data?.[props.keyName]?.error, {
					variant: 'error'
				});
			} else {
				props.onChange();
				enqueueSnackbar('Successfully scheduled emails!', {
					variant: 'success'
				});
				handleClose();
			}
		} catch (e) {
			enqueueSnackbar('There was an error with this request, please try again', {
				variant: 'error'
			});
		}
	};

	return (
		<>
			<PrimaryButton onClick={handleOpen}>{props.buttonLabel}</PrimaryButton>

			<DialogBase open={dialogState} closeDialog={handleClose}>
				<DialogTitleHeader title={props.title} closeDialog={handleClose} />
				<DialogContent>{props.description}</DialogContent>
				<DialogActionBar saveLabel={"Schedule Emails"} cancelAction={handleClose} saveAction={handleSubmit} disableSave={loading} />
			</DialogBase>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {}
	};
});

export default ButtonToDialogCommit;
