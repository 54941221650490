import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

interface Props {
	className?: string;
	children: ReactElement | ReactElement[];
}

const OverviewContainer = (props: Props): ReactElement => {
	const classes = useStyles();

	return <div className={clsx(classes.container, props.className)}>{props.children}</div>;
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			padding: 16,
			borderRadius: 8,
			backgroundColor: '#fff'
		}
	};
});

export default OverviewContainer;
