import { DocumentNode, useQuery } from '@apollo/client';

interface UseAbstractQueryProps {
	queryDocument: DocumentNode;
	variables: Record<string, any>;
}

export const useAbstractQuery = ({ queryDocument, variables }: UseAbstractQueryProps) => {
	const { data } = useQuery(queryDocument, {
		fetchPolicy: 'network-only',
		variables
	});

	return data;
};
