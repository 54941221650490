import { useQuery } from '@apollo/client';
import { Tab, Tabs, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiDotsHorizontal } from '@mdi/js';
import React, { ReactElement, useMemo, useState } from 'react';

import { GetTeamStoreOrdersFromGroupDocument, TeamGroupBasicsFragment } from '../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../config/colors';
import {
	OrderStatus,
	createTabsFromEnum,
	getOrderStatusByIndex,
	getOrderStatusReadableByIndex
} from '../../../../../utilites/orderHelpers';
import ButtonMenu, { MenuItemSpec } from '../../../../blocks/buttonMenu/buttonMenu';
import PaperHeaderContainer from '../../../../blocks/paperHeaderContainer';
import SecondaryButton from '../../../../buttons/secondaryButton';
import ExportDialog from '../orders/components/exportDialog';
import ExportMasterForDesignDialog from '../orders/components/exportMasterForDesignDialog';
import UpdatedTable from '../orders/components/orderTable/orderTable';

interface Props {
	storeId: string;
	groupStore: TeamGroupBasicsFragment;
	closeView(): void;
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const ViewOrders = (props: Props): ReactElement => {
	const classes = useStyles();

	const [exportData, setExportData] = useState<undefined | 'EGYPT' | 'USA'>();
	const [exportDataForDesignMaster, setExportDataForDesignMaster] = useState<undefined | 'EGYPT' | 'USA'>();
	const { data, refetch, loading } = useQuery(GetTeamStoreOrdersFromGroupDocument, {
		variables: {
			groupStoreId: props.groupStore.id,
			teamStoreId: props.storeId,
			status: {
				_eq: getOrderStatusByIndex(5)
			}
		}
	});

	const [selected, setSelected] = useState<string[]>([]);
	const [orderStatusType, setOrderStatusType] = React.useState(6);

	const fetchOrderStatus = useMemo(
		() => (value?: number) => {
			const fetchWith = value === undefined ? orderStatusType : value;
			if (fetchWith === 0) {
				refetch({
					groupStoreId: props.groupStore.id,
					teamStoreId: props.storeId,
					status: {}
				});
			} else {
				const dbVar = getOrderStatusByIndex(fetchWith - 1);
				refetch({
					groupStoreId: props.groupStore.id,
					teamStoreId: props.storeId,
					status: { _eq: dbVar.toUpperCase() }
				});
			}
		},
		[]
	);

	const tabSection = useMemo(() => {
		return createTabsFromEnum(OrderStatus, classes.tab);
	}, []);

	const menuSpec: MenuItemSpec[] = useMemo(() => {
		const exportType = orderStatusType !== 0 ? getOrderStatusReadableByIndex(orderStatusType - 1) : 'all';
		const hasUsOrder = data?.order?.some((o) => o.shipping_origin === 'USA');
		const hasEgyptOrder = data?.order?.some((o) => o.shipping_origin === 'EGYPT');

		return [
			{
				key: '1',
				label: `Export Prod Design Sheet - USA (${exportType})})`,
				onClick: () => {
					setExportDataForDesignMaster('USA');
				},
				disabled: !hasUsOrder
			},
			{
				key: '2',
				label: `Export Prod Design Sheet - EGYPT (${exportType})})`,
				onClick: () => {
					setExportDataForDesignMaster('EGYPT');
				},
				disabled: !hasEgyptOrder
			},
			{
				key: '3',
				label: `Export Customer Sheet - USA (${exportType})})`,
				onClick: () => {
					setExportData('USA');
				},
				disabled: !hasUsOrder
			},
			{
				key: '4',
				label: `Export Customer Sheet - EGYPT (${exportType})})`,
				onClick: () => {
					setExportData('EGYPT');
				},
				disabled: !hasEgyptOrder
			}
		];
	}, [data?.order, orderStatusType]);

	const handleChange = (event: any, newValue: number) => {
		setOrderStatusType(newValue);
		fetchOrderStatus(newValue);
	};

	return (
		<>
			<PaperHeaderContainer className={classes.header}>
				<Typography variant="h4" onClick={props.closeView}>
					Viewing {props.groupStore.name}
				</Typography>
				<div>
					<SecondaryButton onClick={props.closeView}>Back to Groups</SecondaryButton>
					<ButtonMenu iconPath={mdiDotsHorizontal} menuSpec={menuSpec} />
				</div>
			</PaperHeaderContainer>

			<Tabs className={classes.tabs} value={orderStatusType} onChange={handleChange} aria-label="simple tabs example">
				<Tab className={classes.tab} label="All Orders" {...a11yProps(0)} />
				{tabSection}
			</Tabs>

			{data && (
				<div>
					{data?.order && (
						<UpdatedTable
							setSelected={setSelected}
							selectedRows={selected}
							loading={loading}
							searchTerm={''}
							orders={data.order}
							refetchMetrics={() => {
								return;
							}}
							refetchStore={refetch}
						/>
					)}
					{exportDataForDesignMaster && props.storeId && (
						<ExportMasterForDesignDialog
							origin={exportDataForDesignMaster}
							orderStatusType={orderStatusType}
							closeDialog={(): void => setExportDataForDesignMaster(undefined)}
							storeId={props.storeId}
							groupId={props.groupStore.id}
						/>
					)}
					{exportData && props.storeId && (
						<ExportDialog
							origin={exportData}
							orderStatusType={orderStatusType}
							closeDialog={(): void => setExportData(undefined)}
							storeId={props.storeId}
							groupId={props.groupStore.id}
						/>
					)}
				</div>
			)}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		header: {
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: '0px 0 10px'
		},
		orderImage: {
			widht: 50,
			height: 50
		},
		tabs: {
			maxHeight: 40
			// borderBottom: `2px solid ${theme.palette.primary.main}`
		},
		tab: {
			fontWeight: 500,
			fontSize: '0.8rem',
			textTransform: 'none',
			minWidth: 0,
			marginRight: theme.spacing(2),
			'&$selected': {
				fontWeight: 600
				// borderBottom: `4px solid ${theme.palette.primary.main}`
			}
		}
	};
});

export default ViewOrders;
