import { useMutation } from '@apollo/client';
import { DialogContent, TextField, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DialogActionBar from '../../../../../../core/components/blocks/dialogActionBar';
import DialogBase from '../../../../../../core/components/blocks/dialogBase';
import DialogTitleHeader from '../../../../../../core/components/blocks/dialogTitleHeader';
import InputField from '../../../../../../core/components/inputs/inputField';
import { InsertTeamStoreDocument } from '../../../../../../generated/graphql';

interface Props {
	refetch(): void;
	open: boolean;
	handleClose(success?: boolean): void;
	teamId: string;
	stopRedirect?: boolean;
	isCatalogStore?: boolean;
}

const DialogAdd = (props: Props): ReactElement => {
	const classes = useStyles();
	const [name, setName] = useState('');
	const [rep, setRep] = useState('');
	const [addProduct, { loading, data }] = useMutation(InsertTeamStoreDocument);
	const navigate = useNavigate();

	useEffect(() => {
		if (data?.insert_team_store_one?.id) {
			props.handleClose(true);
			props.refetch();
			if (props.stopRedirect !== true) {
				setTimeout(() => {
					navigate(data?.insert_team_store_one?.id);
				}, 500);
			}
		}
	}, [data]);

	const handleSubmit = (): void => {
		if (name && props.teamId)
			addProduct({
				variables: {
					name,
					rep,
					team_id: props.teamId,
					use_as_catalog: props.isCatalogStore
				}
			});
	};

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose} containerClass={classes.container}>
			<DialogTitleHeader title="New Store or Group" closeDialog={props.handleClose} />
			<DialogContent>
				<InputField
					autoFocus={true}
					className={classes.input}
					onChange={(e): void => {
						setName(e.target.value);
					}}
					variant="outlined"
					label="Name"
					fullWidth
					helperText="Suggested Format: Order Type - Quarter - Year"
					value={name}
					onKeyDown={(e): void => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							handleSubmit();
							e.preventDefault();
						}
					}}
				/>
				<InputField
					className={classes.input}
					onChange={(e): void => {
						setRep(e.target.value);
					}}
					variant="outlined"
					label="Rep Name"
					fullWidth
					value={rep}
					onKeyDown={(e): void => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							handleSubmit();
							e.preventDefault();
						}
					}}
				/>
			</DialogContent>
			<DialogActionBar
				cancelAction={props.handleClose}
				saveAction={handleSubmit}
				disableSave={!name || !props.teamId || loading}
			/>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			minHeight: 'unset'
		},
		input: {
			marginBottom: 25
		}
	};
});

export default DialogAdd;
