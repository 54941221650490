import { CardActionArea, Collapse, IconButton, SvgIcon, Switch, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiDotsHorizontal, mdiDotsVertical, mdiTrashCan } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';

import P5 from '../../../../../../core/components/typography/p5';
import P6 from '../../../../../../core/components/typography/p6';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../../../../../core/config/colors';
import { formatCurrencyValueFromServer } from '../../../../../../core/utilites/currenty';
import { AvailableOptionValueAdminBasicsFragment } from '../../../../../../generated/graphql';
import AddItem from '../addItem';

interface Props extends AvailableOptionValueAdminBasicsFragment {
	dragElem: ReactElement;
	handleEdit(label: string, price: string, prodPrice: string): void;
	toggleStatus(status: boolean): void;
	handleRemove(): void;
}

const AvailableOptionValue = (props: Props): ReactElement => {
	const classes = useStyles();
	const [editMode, setEditMode] = useState(false);
	const price = formatCurrencyValueFromServer(props.price_modifier);
	const prodPrice = formatCurrencyValueFromServer(props.price_modifier_production);
	const [enabled, setEnabled] = useState<boolean>(props.store_default_enabled);

	useEffect(() => {
		setEnabled(props.store_default_enabled);
	}, [props.store_default_enabled]);

	return (
		<>
			<Collapse in={editMode === false}>
				<div className={classes.optionConfigs}>
					<div className={classes.optionConfigItem}>
						{props.dragElem}
						<CardActionArea className={classes.labelButton} onClick={(): void => setEditMode(true)}>
							<P5 className={classes.p5}>{props.label}</P5>
						</CardActionArea>
						<div>
							<div className={classes.actions}>
								<div>
									<P5
										className={clsx(
											props.price_modifier >= 0 ? classes.positiveAmount : classes.negativeAmount
										)}
									>
										{price}
									</P5>
									<P6>(Public)</P6>
								</div>
								<div>
									<P5
										className={clsx(
											props.price_modifier_production >= 0 ? classes.positiveAmount : classes.negativeAmount
										)}
									>
										{prodPrice}
									</P5>
									<P6>(Admin)</P6>
								</div>
								<IconButton className={classes.icon} onClick={(): void => props.handleRemove()}>
									<SvgIcon>
										<path d={mdiTrashCan} />
									</SvgIcon>
								</IconButton>
							</div>
							<div className={classes.activeContainer}>
								<Typography variant="h6">Is Active</Typography>
								<Switch
									checked={enabled}
									color="primary"
									name="is_active"
									onChange={(e): void => {
										props.toggleStatus(!enabled);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Collapse>
			<Collapse in={editMode} unmountOnExit>
				<AddItem
					asEdit
					defaultName={props.label}
					defaultPrice={`${props.price_modifier / 100}`}
					defaultPriceProd={`${props.price_modifier_production / 100}`}
					handleAdd={(label: string, price: string, prodPrice: string) => {
						props.handleEdit(label, price, prodPrice);
						setEditMode(false);
					}}
				/>
			</Collapse>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		optionConfigItem: {
			display: 'flex',
			alignItems: 'center',
			paddingBottom: 17,
			'&:last-of-type': {
				paddingBottom: 0
			}
		},
		uploadContainer: {
			width: 30,
			height: 30,
			marginRight: 20
		},
		p5: {
			flex: 1,
			color: SYSTEM_COLORS.GRASCALE_GRAY_DARK
		},
		negativeAmount: {
			color: SYSTEM_COLORS.RED_DEFAULT
		},
		positiveAmount: {
			color: SYSTEM_COLORS.GREEN_DEFAULT
		},
		icon: {
			marginLeft: 5
		},
		actions: {
			display: 'flex',
			alignItems: 'center',
			'& > :not(:first-child)': {
				marginLeft: 15
			}
		},
		hide: {
			display: 'none'
		},
		optionConfigs: {
			borderRadius: 4,
			backgroundColor: getRGBAFromHex(SYSTEM_COLORS.PRIMARY, 0.05),
			margin: 8,
			padding: 16
		},
		labelButton: {
			padding: '10px 0',
			backgroundColor: 'none !important',
			'&:hover': {
				backgroundColor: 'none !important'
			}
		},
		activeContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			textAlign: 'right'
		}
	};
});

export default AvailableOptionValue;
