import { TextField, TextFieldProps, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';

const InputField = (props: TextFieldProps): ReactElement => {
	const { inputProps, ...rest } = props;
	const classes = useStyles({ isTextfield: props.multiline });

	return (
		<TextField
			autoComplete="off"
			classes={{
				root: classes.root
			}}
			{...rest}
			inputProps={{
				autoCorrect: 'off',
				autoCapitalize: 'none',
				className: classes.inputClass,
				...inputProps
			}}
			InputLabelProps={{
				className: classes.labelClass
			}}
		/>
	);
};

export default InputField;

interface StyleProps {
	isTextfield?: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		root: {
			borderRadius: 8,
			backgroundColor: '#fff',
			'&:hover': {
				backgroundColor: '#fff'
			},
			'& .MuiInputBase-input': {
				borderRadius: 8,
				height: (props: StyleProps) => (props.isTextfield ? undefined : '4px')
			},
			'& .MuiOutlinedInput-root': {
				borderRadius: 8
			},
			'& .MuiOutlinedInput-multiline': {
				padding: 0
			},
			'& .MuiOutlinedInput-multiline textarea': {
				padding: '16px 14px'
			},
			'& .Mui-disabled': {
				color: SYSTEM_COLORS.GRAY_SHADE
			}
		},
		inputClass: {
			fontSize: 16,
			borderRadius: 8
		},
		labelClass: {
			marginTop: -3,
			borderRadius: 8,
			transform: 'translate(14px, 16px) scale(1)'
		}
	};
});
