import React, { ReactElement, useEffect, useState } from "react";
import { Theme, makeStyles, SvgIcon } from "@material-ui/core";
import {
  TeamStoreProductFragment, TeamStoreProductListFragment,
  UpdateStoreProductAdditionalImagesDocument,
  UpdateStoreProductDisplayImageDocument
} from "../../../../../generated/graphql";
import ImageContainer from "../../../../group/components/imageContainer";
import { useMutation } from "@apollo/client";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { mdiDotsVertical } from "@mdi/js";
import { SYSTEM_COLORS } from "../../../../../core/config/colors";
import { dragReorder } from "../../../../../core/utilites/dragReorder";

interface Props {
  productId: string;
  dataItem: TeamStoreProductFragment;
}

const StoreProductImages = ({ productId, dataItem }: Props): ReactElement => {
  const classes = useStyles();
  const [addlImages, setAddlImages] = useState(dataItem.additional_image_list);
  const [updateDisplayImage] = useMutation(UpdateStoreProductDisplayImageDocument);
  const [updateAdditionalImages] = useMutation(UpdateStoreProductAdditionalImagesDocument);

  useEffect(() => {
    setAddlImages(dataItem.additional_image_list);
  }, [dataItem]);

  const handleMainImageUpdate = async (url: string | undefined) => {
    await updateDisplayImage({ variables: { id: productId, display_photo: url } });
  };

  const handleAdditionalImageUpdate = async (index: number, url: string | undefined) => {
    const clonedImages = [...addlImages];
    if (index < 0) {
      //new image
      if (url && url.length > 0) {
        clonedImages.push({ url: url });
      }
    } else {
      if (url && url !== undefined && url.length > 0) {
        clonedImages[index] = { url: url };
      } else {
        if (window.confirm("Remove Image?\nAre you Sure?")) {
          clonedImages.splice(index, 1);
        }
      }
    }
    setAddlImages(clonedImages);
    await updateAdditionalImages({ variables: { id: productId, additional_image_list: clonedImages } });
  };

  const handleDragEnd = async (result: any) => {
    // console.log(result.source.index, result.destination.index, result);
    const clonedImages = [...addlImages];
    const items = clonedImages.splice(result.source.index, 1);
    clonedImages.splice(result.destination.index, 0, items[0]);
    setAddlImages(clonedImages);
    await updateAdditionalImages({ variables: { id: productId, additional_image_list: clonedImages } });
  }


  return (
    <div className={classes.imageStrip}>
      <ImageContainer
        width={120}
        height={120}
        label={"Add Main Photo"}
        image_url={dataItem.display_photo}
        onImageUpdate={handleMainImageUpdate}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="product-list" direction={"horizontal"}>
          {(provided): ReactElement => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef} className={classes.imageStrip}>
                {addlImages.map((ai: { url: string | null | undefined; }, index: number) => (
                  <Draggable key={`draggable-${index}`} index={index} draggableId={index.toString()}>
                    {(p) => {
                      return (
                        <div {...p.draggableProps} ref={p.innerRef} className={classes.dragBox}>
                          <div {...p.dragHandleProps}>
                            <SvgIcon className={classes.dragIcon}>
                              <path d={mdiDotsVertical} />
                            </SvgIcon>
                          </div>
                          <ImageContainer
                            key={`addlImage-${index}}`}
                            width={120}
                            height={120}
                            style={{marginLeft: 0}}
                            label={"Add Additional Photo"}
                            image_url={ai.url}
                            onImageUpdate={url => handleAdditionalImageUpdate(index, url)}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <ImageContainer
        width={120}
        height={120}
        label={"Add New Photo"}
        image_url={null}
        onImageUpdate={url => handleAdditionalImageUpdate(-1, url)}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    imageStrip: {
      display: "flex",
      overflow: "scroll",
      paddingBottom: 30
    },
    dragBox:{
      display: "flex",
      marginLeft: 10
    },
    dragIcon: {
      cursor: 'grab',
      color: SYSTEM_COLORS.GRAY_LIGHT,
      width: 24,
      height: 24,
      marginTop: 10
    }
  };
});
export default StoreProductImages;
