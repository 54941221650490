import { useMutation, useQuery } from '@apollo/client';
import { Card, IconButton, SvgIcon, Theme, makeStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { mdiClose, mdiDotsVertical, mdiFolderEditOutline } from '@mdi/js';
import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { ArchiveShowcaseDocument, GetShowcaseDocument, ShowcaseBasicsFragment } from '../../../../../../generated/graphql';
import ShowcaseEditDialog from '../../../../../../page/group/components/showcaseEditDialog';
import { SYSTEM_COLORS } from '../../../../../config/colors';
import { useDragReorderEnd } from '../../../../../utilites/dragReorder';
import PaperHeaderContainer from '../../../../blocks/paperHeaderContainer';
import PaperHeaderItems from '../../../../blocks/paperHeaderItems';
import RowBlockEdit from '../../../../blocks/rowBlockEdit';
import ConfirmationDialog from '../../../../dialogs/confirmationDialog/confirmationDialog';
import { STORE_SUB_ROUTE_DETAILS } from '../../subRoutes';
import DialogNewStore from './components/dialogNewStore';

interface Props {
	storeId: string;
}

const Showcase = ({ storeId }: Props): ReactElement => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const [activeStore, setActiveStore] = useState<ShowcaseBasicsFragment | null>(null);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [options, setStoreOrder] = useState<ShowcaseBasicsFragment[]>([]);
	const [archiveShowcase, { loading }] = useMutation(ArchiveShowcaseDocument);
	const { data, refetch } = useQuery(GetShowcaseDocument, {
		variables: {
			storeId
		}
	});
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState<ShowcaseBasicsFragment | null>(null);

	useEffect(() => {
		if (data?.showcase) {
			setStoreOrder(data.showcase);
		}
	}, [data]);

	const dragOnEnd = useDragReorderEnd<ShowcaseBasicsFragment>('update_showcase_by_pk');

	const handleDragEnd = (result: any) => {
		const updatedValues = dragOnEnd(result, options);
		setStoreOrder(updatedValues);
	};

	const handleEditClick = (cs: ShowcaseBasicsFragment) => {
		setActiveStore(cs);
		setEditDialogOpen(true);
	};

	const handleShowcaseEditClose = async () => {
		setEditDialogOpen(false);
		await refetch();
	};

	const handleShowcaseRemove = (cs: ShowcaseBasicsFragment) => {
		setCurrentItem(cs);
		setConfirmOpen(true);
	};

	const handleConfirm = async () => {
		if (currentItem) {
			await archiveShowcase({ variables: { id: currentItem.id, is_archived: true } });
			await refetch();
		}
		setConfirmOpen(false);
	};

	const handleCancel = () => {
		setConfirmOpen(false);
	};

	return (
		<>
			<Card>
				<CardContent>
					<PaperHeaderContainer>
						<PaperHeaderItems
							handleNew={(): void => setOpen(true)}
							header={'Connected Team Stores'}
							newLabel="Add New Store"
						/>
					</PaperHeaderContainer>
					<div className={classes.items}>
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="product-list">
								{(provided): ReactElement => {
									return (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{options.map((cs, i) => {
												return (
													<Draggable key={cs.id} index={i} draggableId={cs.id}>
														{(p) => {
															const elem = (
																<div {...p.dragHandleProps}>
																	<SvgIcon className={classes.dragIcon}>
																		<path d={mdiDotsVertical} />
																	</SvgIcon>
																</div>
															);
															const endEL = (
																<IconButton
																	aria-label="close"
																	onClick={() => handleShowcaseRemove(cs)}
																	disabled={loading}
																>
																	<SvgIcon>
																		<path d={mdiClose} />
																	</SvgIcon>
																</IconButton>
															);
															return (
																<div {...p.draggableProps} ref={p.innerRef}>
																	<RowBlockEdit
																		to={`../../../../teams/
																			${cs.teamStoreByStoreShowcasingId.team.id}/
																			${cs.teamStoreByStoreShowcasingId.id}
																		/${STORE_SUB_ROUTE_DETAILS.STORE.route}`}
																		startEl={elem}
																		endEl={endEL}
																		editEl={
																			<IconButton
																				aria-label="edit"
																				onClick={() => handleEditClick(cs)}
																			>
																				<SvgIcon>
																					<path d={mdiFolderEditOutline} />
																				</SvgIcon>
																			</IconButton>
																		}
																		label={cs.label || cs.teamStoreByStoreShowcasingId.name}
																		subLabel={cs.teamStoreByStoreShowcasingId.team.name}
																	/>
																</div>
															);
														}}
													</Draggable>
												);
											})}
											{provided.placeholder}
										</div>
									);
								}}
							</Droppable>
						</DragDropContext>
					</div>
				</CardContent>
			</Card>
			<DialogNewStore
				storeId={storeId}
				handleClose={(): void => {
					setOpen(false);
				}}
				open={open}
				refetch={refetch}
			/>
			<ConfirmationDialog
				title="Confirm Removal"
				message="Are you sure you want to archive this group?"
				open={confirmOpen}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			/>
			{activeStore ? (
				<ShowcaseEditDialog open={editDialogOpen} onClose={handleShowcaseEditClose} showcase={activeStore} />
			) : null}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		paperContainer: {
			padding: 16
		},
		cardWide: {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 3fr)',
			gap: '15px',
			marginBottom: 20
		},
		items: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '10px',
			padding: 15,
			borderRadius: 4,
			backgroundColor: SYSTEM_COLORS.GRAYSCALE_GRAY_LIGHTEST
		},
		dragIcon: {
			color: SYSTEM_COLORS.GRAY_LIGHT,
			width: 24,
			height: 24
		}
	};
});

export default Showcase;
