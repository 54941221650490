import { useMutation } from '@apollo/client';
import { IconButton, Paper, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiCog, mdiContentCopy, mdiDotsHorizontal, mdiEye, mdiPackageDown } from '@mdi/js';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ButtonMenu, { MenuItemSpec } from '../../../../../../core/components/blocks/buttonMenu/buttonMenu';
import TableComponent from '../../../../../../core/components/blocks/tableComponent';
import P6 from '../../../../../../core/components/typography/p6';
import { SYSTEM_COLORS } from '../../../../../../core/config/colors';
import { findEnvAttr } from '../../../../../../core/utilites/process';
import { DuplicateTeamStoreDocument, UpdateTeamStoreSetArchiveDocument } from '../../../../../../generated/graphql';
import { useTeamContext } from '../../../../../../providers/teamProvider/teamProvider';

interface Props {
	stores: any[];
	isArchived?: boolean;
}

const StoreList = (props: Props): ReactElement => {
	const classes = useStyles();
	const teamContext = useTeamContext();
	const [duplicateStore, { loading, data }] = useMutation(DuplicateTeamStoreDocument);
	const [archiveStore, { data: archiveData }] = useMutation(UpdateTeamStoreSetArchiveDocument);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const storeData = useMemo(() => {
		if (props.isArchived) {
			return props.stores.filter((store) => store.is_archived);
		}
		return props.stores.filter((store) => !store.is_archived);
	}, [props.stores, props.isArchived]);

	useEffect(() => {
		if (data) {
			if (data.duplicateTeamStore?.newStoreId) {
				navigate(`${data.duplicateTeamStore?.newStoreId}`);
			}
		}
	}, [data]);

	useEffect(() => {
		if (data || archiveData) {
			teamContext.refetch();
		}
	}, [data, archiveData]);

	const menuSpec: MenuItemSpec[] = useMemo(() => {
		return [
			{
				key: '1',
				label: 'View',
				onClick: (menuArgs: any) => {
					window.location.href = `${findEnvAttr('REACT_APP_PUBLIC_URL')}store/${
						menuArgs.friendlyUrlKey ? menuArgs.friendlyUrlKey : menuArgs.teamStoreId
					}`;
				},
				startIcon: (
					<SvgIcon className={classes.tertiaryFill}>
						<path d={mdiEye} />
					</SvgIcon>
				)
			},
			{
				key: '2',
				label: 'Manage',
				onClick: (menuArgs: any) => {
					navigate(menuArgs.teamStoreId);
				},
				startIcon: (
					<SvgIcon className={classes.secondaryFill}>
						<path d={mdiCog} />
					</SvgIcon>
				)
			},
			{
				key: '3',
				label: 'Duplicate',
				startIcon: (
					<SvgIcon className={classes.tertiaryFill}>
						<path d={mdiContentCopy} />
					</SvgIcon>
				),
				onClick: (menuArgs: any) => {
					duplicateStore({
						variables: {
							teamStoreId: menuArgs.teamStoreId
						}
					});
				}
			},
			{
				key: '4',
				label: 'Copy  URL',
				startIcon: (
					<SvgIcon className={classes.primaryFill}>
						<path d={mdiContentCopy} />
					</SvgIcon>
				),
				onClick: (menuArgs: any) => {
					copy(
						`${findEnvAttr('REACT_APP_PUBLIC_URL')}store/${
							menuArgs.friendlyUrlKey ? menuArgs.friendlyUrlKey : menuArgs.teamStoreId
						}`
					);
					enqueueSnackbar('Successfully copied the Store URL', {
						variant: 'success'
					});
				}
			},
			{
				key: '5',
				label: props.isArchived ? 'Unarchive' : 'Archive',
				onClick: (menuArgs: any) => {
					archiveStore({
						variables: {
							id: menuArgs.teamStoreId,
							is_archived: !props.isArchived
						}
					});
				},
				hasDivider: true,
				startIcon: (
					<SvgIcon className={classes.warningFill}>
						<path d={mdiPackageDown} />
					</SvgIcon>
				)
			}
		].filter(Boolean);
	}, [props]);

	const columns = useMemo(() => {
		return [
			{
				Header: 'Store',
				accessor: 'name',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return (
						<>
							<Typography
								className={classes.link}
								variant="body2"
								style={{ paddingBottom: 8 }}
								component={Link}
								to={`${row.id}`}
							>
								{row.name}
							</Typography>
							<Typography variant="h6">Created {dayjs(row.created_at).format('MMM DD, YYYY')}</Typography>
						</>
					);
				}
			},
			{
				Header: 'Orders',
				accessor: '',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;

					return <P6> {row.orders.length}</P6>;
				}
			},
			{
				Header: 'Products',
				accessor: '',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;

					return <P6> {row.store_products.length}</P6>;
				}
			},
			{
				Header: 'Bundles',
				accessor: '',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return <P6> {row.store_packages.length}</P6>;
				}
			},
			{
				Header: 'Last Updated',
				accessor: 'updated_at',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					const label = row.status[0].toUpperCase() + row.status.substring(1);

					return (
						<>
							<Typography
								variant="body2"
								className={clsx({
									[classes.active]: row.status === 'open',
									[classes.closed]: row.status === 'closed'
								})}
							>
								{label}
							</Typography>
							<P6>{dayjs(row.updated_at).format('MMM DD, YYYY')}</P6>
						</>
					);
				}
			},
			{
				Header: 'Actions',
				accessor: 'id',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;

					return (
						<ButtonMenu
							iconPath={mdiDotsHorizontal}
							menuSpec={menuSpec}
							menuArg={{ teamStoreId: row.id, friendlyUrlKey: row.friendly_url_key }}
						/>
					);
				}
			}
		];
	}, [storeData]);

	return (
		<Paper>
			<TableComponent columns={columns} data={storeData || []} />
		</Paper>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		link: {
			color: SYSTEM_COLORS.PRIMARY,
			textDecoration: 'none'
		},
		active: {
			color: SYSTEM_COLORS.GREEN_DEFAULT
		},
		closed: {
			color: SYSTEM_COLORS.RED_DEFAULT
		},
		primaryFill: {
			color: SYSTEM_COLORS.PRIMARY
		},
		secondaryFill: {
			color: SYSTEM_COLORS.OPT_SECONDARY
		},
		tertiaryFill: {
			color: SYSTEM_COLORS.TERTIARY
		},
		warningFill: {
			color: SYSTEM_COLORS.WARNING
		}
	};
});

export default StoreList;
