import { useQuery } from '@apollo/client';
import { Paper, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiPlusCircle } from '@mdi/js';
import dayjs from 'dayjs';
import React, { ReactElement, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import PaperHeaderContainer from '../../core/components/blocks/paperHeaderContainer';
import TableComponent from '../../core/components/blocks/tableComponent';
import PrimaryButton from '../../core/components/buttons/primaryButton';
import { STORE_SUB_ROUTE_DETAILS } from '../../core/components/connected/store/subRoutes';
import { SYSTEM_COLORS } from '../../core/config/colors';
import { GetTeamStoresByTeamIdDocument } from '../../generated/graphql';
import DialogAdd from '../teams/pages/stores/components/dialogAdd';

interface Props {
	groupId: string;
}

const Group = (props: Props): ReactElement => {
	const classes = useStyles();
	const [dialogAdd, setDialogAdd] = useState(false);
	const { data, refetch } = useQuery(GetTeamStoresByTeamIdDocument, {
		variables: {
			team_id: props.groupId
		}
	});

	const handleOpen = (): void => {
		setDialogAdd(true);
	};

	const handleClose = (): void => {
		setDialogAdd(false);
	};

	const columns = useMemo(() => {
		return [
			{
				Header: 'Store',
				accessor: 'name',
				Cell: (rowData: any): ReactElement => {
					const row = rowData.row.original;
					return (
						<>
							<Typography
								to={`${row.id}/${STORE_SUB_ROUTE_DETAILS.STORE.route}`}
								className={classes.link}
								variant="body2"
								style={{ paddingBottom: 8 }}
								component={Link}
							>
								{row.name}
							</Typography>
							<Typography variant="h6">Created {dayjs(row.created_at).format('MMM DD, YYYY')}</Typography>
						</>
					);
				}
			}
		];
	}, [data?.team_store]);

	return (
		<>
			<Paper>
				<PaperHeaderContainer>
					<Typography variant="h3">Groups</Typography>
					<div>
						<PrimaryButton
							onClick={handleOpen}
							startIcon={
								<SvgIcon>
									<path d={mdiPlusCircle} />
								</SvgIcon>
							}
						>
							New Group
						</PrimaryButton>
					</div>
				</PaperHeaderContainer>
				<div>
					<TableComponent columns={columns} data={data?.team_store || []} globalFilter={''} />
				</div>
			</Paper>
			<DialogAdd
				open={dialogAdd}
				refetch={refetch}
				handleClose={handleClose}
				teamId={props.groupId}
				stopRedirect={true}
				isCatalogStore={true}
			/>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		content: {
			borderRadius: 16,
			padding: 15,
			backgroundColor: SYSTEM_COLORS.BACKGROUND
		},
		tableContainer: {
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.WHITE
		},

		link: {
			color: SYSTEM_COLORS.PRIMARY,
			textDecoration: 'none'
		}
	};
});

export default Group;
