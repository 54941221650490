import { gql, useApolloClient, useMutation } from '@apollo/client';
import { SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiDotsVertical } from '@mdi/js';
import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { SYSTEM_COLORS } from '../../../../../../core/config/colors';
import { dragReorder } from '../../../../../../core/utilites/dragReorder';
import { StoreProductHasOptionBasicsFragment } from '../../../../../../generated/graphql';
import Option from './components/option';

interface Props {
	storeProductId: string;
	refetch(): void;
	productAvailableOptions: StoreProductHasOptionBasicsFragment[];
}

const StoreProductOptions = (props: Props): ReactElement => {
	const classes = useStyles();
	const { productAvailableOptions } = props;
	const [options, setProductOptions] = useState(productAvailableOptions);
	const client = useApolloClient();

	useEffect(() => {
		setProductOptions(productAvailableOptions);
	}, [productAvailableOptions]);

	const handleDragEnd = (result: any): void => {
		const updatedValues = dragReorder<StoreProductHasOptionBasicsFragment>(
			[...options],
			result.source.index,
			result.destination.index
		);

		let str = '';
		updatedValues.map((u, i) => {
			str += `
			update${i}: update_store_product_has_options_by_pk(pk_columns: {id: "${u.id}"}, _set: {order: ${i}}) {id}`;
		});
		client.mutate({
			mutation: gql`
				mutation UpdateMultiple {
					${str}
				}
			`
		});

		setProductOptions(updatedValues);
	};

	return (
		<div className={classes.optionContainer}>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="product-list">
					{(provided): ReactElement => {
						return (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{options.map((d, i): ReactElement => {
									return (
										<Draggable key={d.id} index={i} draggableId={d.id}>
											{(p) => {
												const elem = (
													<div {...p.dragHandleProps}>
														<SvgIcon className={classes.dragIcon}>
															<path d={mdiDotsVertical} />
														</SvgIcon>
													</div>
												);
												return (
													<div {...p.draggableProps} ref={p.innerRef}>
														<Option
															storeProductId={props.storeProductId}
															available_option_id={d.available_option_id}
															dragHandle={elem}
															label={d.available_option?.name}
														/>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		dragIcon: {
			color: SYSTEM_COLORS.GRAY_LIGHT,
			width: 24,
			height: 24
		},
		optionContainer: {
			padding: 15
		}
	};
});
export default StoreProductOptions;
