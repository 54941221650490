import { useQuery } from '@apollo/client';
import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';

import { GetDataExportUrlDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import P3 from '../../../../../../typography/p3';

interface Props {
	storeId: string;
	groupId?: string;
	closeDialog(): void;
	orderStatusType: number;
	origin: 'USA' | 'EGYPT';
}

const ExportDialog = (props: Props): ReactElement => {
	const classes = useStyles();
	const { closeDialog } = props;

	const { data: urlData } = useQuery(GetDataExportUrlDocument, {
		fetchPolicy: 'network-only',
		variables: {
			storeId: props.storeId,
			groupId: props.groupId,
			orderStatusType: props.orderStatusType,
			origin: props.origin
		}
	});

	useEffect(() => {
		if (urlData && urlData.getDataExportURL?.url) {
			const link = document.createElement('a');
			link.target = '_self';
			link.href = urlData.getDataExportURL.url;
			link.click();
			closeDialog();
		}
	}, [urlData, closeDialog]);

	return (
		<DialogBase open={true}>
			<DialogTitleHeader closeDialog={closeDialog} title={'Export Store Data'} />
			<DialogContent>
				<div className={classes.loadingContainer}>
					<P3 className={classes.p3}>Generating that report, hold on a moment.</P3>
				</div>
			</DialogContent>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		p3: {
			paddingTop: 5,
			color: SYSTEM_COLORS.GRAY
		},
		loadingContainer: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			textAlign: 'center'
		}
	};
});

export default ExportDialog;
