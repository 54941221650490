import './index.css';

import { MuiThemeProvider } from '@material-ui/core';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ApolloWrapper from './core/apollo/apolloWrapper';
import { findEnvAttr } from './core/utilites/process';
import reportWebVitals from './reportWebVitals';

Sentry.init({
	dsn: 'https://b99c7152028c4e01afd42511825d1483@o1038180.ingest.sentry.io/6006986',
	environment: findEnvAttr('REACT_APP_ENVIRONMENT'),
	integrations: [new Integrations.BrowserTracing()],
	release: findEnvAttr('REACT_APP_SENTRY_RELEASE'),

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
});

ReactDOM.render(
	<BrowserRouter>
		<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
			<ApolloWrapper>
				<App />
			</ApolloWrapper>
		</GoogleOAuthProvider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
