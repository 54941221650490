import { Theme, makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { ReactElement, ReactNode } from 'react';

import Menu from './components/menu/menu';

interface Props {
	children: ReactNode;
}

const AppContainer = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Menu />

			<main className={classes.main}>{props.children}</main>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		root: {
			display: 'flex'
		},
		main: {
			padding: '80px 30px',
			width: '100%',
			maxWidth: 1400,
			margin: 'auto'
		}
	};
});

export default AppContainer;
