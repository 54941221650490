import { useMutation } from '@apollo/client';
import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React, { ReactElement } from 'react';

import DialogActionBar from '../../../../core/components/blocks/dialogActionBar';
import DialogBase from '../../../../core/components/blocks/dialogBase';
import DialogTitleHeader from '../../../../core/components/blocks/dialogTitleHeader';
import InputField from '../../../../core/components/inputs/inputField';
import { AvailableOptionBasicsFragment, UpdateAvailableOptionByPkDocument } from '../../../../generated/graphql';

interface Props {
	handleSuccessfulClose(): void;
	handleClose(): void;
	open: boolean;
	availableOption: AvailableOptionBasicsFragment;
}

const EditOptionDialog = (props: Props): ReactElement => {
	const classes = useStyles();
	const [saveOption, { loading }] = useMutation(UpdateAvailableOptionByPkDocument, {
		variables: {
			id: props.availableOption.id
		}
	});

	return (
		<DialogBase closeDialog={props.handleClose} open={props.open}>
			<Formik
				initialValues={{
					name: props.availableOption.name,
					display_name: props.availableOption.display_name || '',
					description: props.availableOption.description || '',
					csv_key: props.availableOption.csv_key || '',
					ui_test_id: props.availableOption.ui_test_id || ''
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					await saveOption({
						variables: {
							...values
						}
					});
					setSubmitting(false);
					props.handleSuccessfulClose();
				}}
			>
				{({ isSubmitting, values, handleChange, submitForm }) => {
					return (
						<form className={classes.form}>
							<DialogTitleHeader title="Edit Option" closeDialog={props.handleClose} />
							<DialogContent>
								<InputField
									className={classes.input}
									variant="outlined"
									label="Option Name"
									fullWidth
									name="name"
									onChange={handleChange}
									value={values.name}
								/>
								<InputField
									className={classes.input}
									variant="outlined"
									label="Display Name for Customer"
									fullWidth
									name="display_name"
									onChange={handleChange}
									value={values.display_name}
								/>
								<InputField
									multiline
									className={classes.input}
									rows={4}
									variant="outlined"
									label="Description"
									fullWidth
									name="description"
									onChange={handleChange}
									value={values.description}
								/>
								<InputField
									className={classes.input}
									variant="outlined"
									label="CSV Key"
									helperText="MUST BE UNIQUE - Will be represented in the CSV as this."
									fullWidth
									name="csv_key"
									onChange={handleChange}
									value={values.csv_key}
								/>
								<InputField
									className={classes.input}
									variant="outlined"
									label="Automated ID"
									helperText="Used for importing orders - do not change"
									fullWidth
									name="ui_test_id"
									onChange={handleChange}
									value={values.ui_test_id}
								/>
							</DialogContent>
							<DialogActionBar
								cancelAction={props.handleClose}
								saveAction={submitForm}
								disableSave={isSubmitting || loading}
							/>
						</form>
					);
				}}
			</Formik>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		form: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1
		},
		input: {
			marginBottom: 15
		}
	};
});

export default EditOptionDialog;
