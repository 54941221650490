import { useMutation } from '@apollo/client';
import { DialogContent, InputLabel, Select, Theme, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React, { ReactElement } from 'react';

import DialogActionBar from '../../../../core/components/blocks/dialogActionBar';
import DialogBase from '../../../../core/components/blocks/dialogBase';
import DialogTitleHeader from '../../../../core/components/blocks/dialogTitleHeader';
import InputField from '../../../../core/components/inputs/inputField';
import { InsertTeamDocument } from '../../../../generated/graphql';
import { TeamDefaultSchema } from '../../../../models/teamDefault';

interface Props {
	open: boolean;
	handleClose(success?: boolean): void;
}

const DialogAddTeam = (props: Props): ReactElement => {
	const classes = useStyles();
	const [createTeam, { loading, data }] = useMutation(InsertTeamDocument);

	return (
		<Formik
			initialValues={{
				name: '',
				city: '',
				state: '',
				category: '',
				activity: ''
			}}
			validationSchema={TeamDefaultSchema}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);
				await createTeam({
					variables: {
						...values
					}
				});
				setSubmitting(false);
				props.handleClose(true);
			}}
		>
			{({ isSubmitting, values, handleChange, submitForm, errors }) => {
				return (
					<form>
						<DialogBase open={props.open} closeDialog={props.handleClose} containerClass={classes.paperContainer}>
							<DialogTitleHeader title="New Team" closeDialog={props.handleClose} />
							<DialogContent>
								<div>
									<InputField
										variant="outlined"
										label="Team Name"
										fullWidth
										name="name"
										onKeyDown={(e): void => {
											if (e.keyCode === 13 || e.key === 'Enter') {
												submitForm();
												e.preventDefault();
											}
										}}
										onChange={handleChange}
										value={values.name}
										error={Boolean(errors.name)}
										helperText={errors.name}
									/>
									<div className={classes.locationContainer}>
										<InputField
											variant="outlined"
											label="City"
											helperText="You can always change this at a later point"
											name="city"
											onChange={handleChange}
											value={values.city}
											onKeyDown={(e): void => {
												if (e.keyCode === 13 || e.key === 'Enter') {
													submitForm();
													e.preventDefault();
												}
											}}
										/>
										<InputField
											variant="outlined"
											label="State"
											helperText="You can always change this at a later point"
											name="state"
											onChange={handleChange}
											value={values.state}
											onKeyDown={(e): void => {
												if (e.keyCode === 13 || e.key === 'Enter') {
													submitForm();
													e.preventDefault();
												}
											}}
										/>
									</div>
									<div className={classes.locationContainer}>
										<div style={{ width: '100%' }}>
											<InputLabel htmlFor="category-simple" shrink className={classes.selectOutline}>
												Activity
											</InputLabel>
											<Select
												classes={{
													select: classes.selectField
												}}
												variant="outlined"
												fullWidth
												native
												value={values.activity}
												onChange={handleChange}
												inputProps={{
													name: 'activity',
													id: 'group-simple'
												}}
											>
												<option aria-label="None" value="" />
												<option value={'Ultimate'}>Ultimate</option>
												<option value={'Maccabi'}>Maccabi</option>
												<option value={'Basketball'}>Basketball</option>
												<option value={'Volleyball'}>Volleyball</option>
												<option value={'Quad Ball'}>Quad Ball</option>
												<option value={'Other'}>Other</option>
											</Select>
										</div>
									</div>
									<div className={classes.locationContainer}>
										<div style={{ width: '100%' }}>
											<InputLabel htmlFor="category-simple" shrink className={classes.selectOutline}>
												Category
											</InputLabel>
											<Select
												classes={{
													select: classes.selectField
												}}
												variant="outlined"
												fullWidth
												native
												value={values.category}
												onChange={handleChange}
												inputProps={{
													name: 'category',
													id: 'group-simple'
												}}
											>
												<option aria-label="None" value="" />
												<option value={'Elementary School'}>Elementary School</option>
												<option value={'Middle School'}>Middle School</option>
												<option value={'High School'}>High School</option>
												<option value={'College'}>College</option>
												<option value={'Club'}>Club</option>
												<option value={'PUL'}>PUL</option>
												<option value={'WUL'}>WUL</option>
												<option value={'National'}>National</option>
											</Select>
										</div>
									</div>
								</div>
							</DialogContent>
							<DialogActionBar
								cancelAction={props.handleClose}
								saveAction={submitForm}
								disableSave={isSubmitting || loading}
							/>
						</DialogBase>
					</form>
				);
			}}
		</Formik>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		locationContainer: {
			paddingTop: 25,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		paperContainer: {
			minHeight: 'auto'
		},
		selectField: {
			padding: 10
		},
		selectOutline: {
			borderRadius: 8
		}
	};
});

export default DialogAddTeam;
