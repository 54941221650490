import { useMutation } from '@apollo/client';
import { mdiDotsHorizontal } from '@mdi/js';
import React, { ReactElement, useMemo, useState } from 'react';

import { CreateNewReceiptForOrderDocument, TeamStoreOrderFragment } from '../../../../../../../../../../generated/graphql';
import ButtonMenu, { MenuItemSpec } from '../../../../../../../../blocks/buttonMenu/buttonMenu';
import DialogAdjustOrderTotal from '../../../../../viewOrder/components/dialogAdjustOrderTotal';
import DialogCancelOrder from '../../../../../viewOrder/components/dialogCancelOrder';
import DialogGenShippingLabel from '../../../../../viewOrder/components/dialogGenShippingLabel';

interface Props {
	// refetchMetrics(): void;
	refetchStore(): void;
	orderTotal?: number;
	shippingLabelUrl?: string;
	receiptUrl?: string;
	orderId: string;
}

const ActionButton = (props: Props): ReactElement => {
	const [adjustOrderTotal, setAdjustOrderTotal] = useState<undefined | string>();
	const [shippingLabelToGenerate, setGenShippingLabel] = useState(false);
	const [cancelOrder, setCancelOrder] = useState(false);
	const [generateShippingLabel, { loading }] = useMutation(CreateNewReceiptForOrderDocument, {
		variables: {
			orderId: props.orderId
		}
	});
	const menuSpec: MenuItemSpec[] = useMemo(() => {
		return [
			{
				key: '1',
				label: 'Adjust Order',
				onClick: () => {
					setAdjustOrderTotal(`${props.orderTotal ? props.orderTotal / 100 : 0}`);
				}
			},
			{
				key: '2',
				label: 'Cancel Order',
				onClick: () => {
					setCancelOrder(true);
				}
			},
			props.shippingLabelUrl !== null && {
				key: '3',
				label: 'View Shipping Label',
				onClick: (): void => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					window.open(props.shippingLabelUrl!, '_blank', 'noopener noreferrer nofollow');
				}
			},
			props.receiptUrl !== null && {
				key: '4',
				label: 'View Packaging Slip',
				onClick: (): void => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					window.open(props.receiptUrl!, '_blank', 'noopener noreferrer nofollow');
				}
			},
			{
				key: '5',
				label: 'Generate Shipping Label',
				onClick: (): void => {
					setGenShippingLabel(true);
				}
			},
			{
				key: '6',
				label: 'Generate Packaging Slip',
				onClick: async (): Promise<void> => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					await generateShippingLabel();

					props.refetchStore();
				}
			}
		].filter(Boolean);
	}, [props]);

	return (
		<>
			<ButtonMenu iconPath={mdiDotsHorizontal} menuSpec={menuSpec} />
			{adjustOrderTotal && (
				<DialogAdjustOrderTotal
					open={true}
					orderId={props.orderId}
					price={adjustOrderTotal}
					handleClose={(): void => {
						setAdjustOrderTotal(undefined);
						props.refetchStore();
						// props.refetchMetrics();
					}}
				/>
			)}
			{shippingLabelToGenerate && (
				<DialogGenShippingLabel
					open={true}
					orderId={props.orderId}
					handleClose={(): void => {
						setGenShippingLabel(false);
						props.refetchStore();
					}}
				/>
			)}
			<DialogCancelOrder
				orderId={props.orderId}
				open={cancelOrder}
				handleClose={(): void => {
					setCancelOrder(false);
					props.refetchStore();
					// props.refetchMetrics();
				}}
			/>
		</>
	);
};

export default ActionButton;
