import { useMutation } from '@apollo/client';
import { InputAdornment, Select, Switch, Theme, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { ReactElement } from 'react';

import NumberFormatCustom from '../../../../core/components/blocks/numberFormatCustom';
import RowToggleWithSwitch from '../../../../core/components/blocks/rowToggleWithSwitch';
import ButtonBase from '../../../../core/components/buttons/buttonBase';
import DropImage from '../../../../core/components/connected/store/sections/products/components/dropImage';
import { MarkdownEditor } from '../../../../core/components/editor/MarkdownEditor';
import AutoSave from '../../../../core/components/inputs/autosave';
import InputField from '../../../../core/components/inputs/inputField';
import P6 from '../../../../core/components/typography/p6';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { cleanNumberFormat } from '../../../../core/utilites/currenty';
import { FullProductFragment, UpdateProductBaseByIdDocument } from '../../../../generated/graphql';

interface Props {
	productId: string;
	dataItem: FullProductFragment;
}

const ManageProduct = (props: Props): ReactElement => {
	const { dataItem } = props;
	const classes = useStyles();
	const [submit] = useMutation(UpdateProductBaseByIdDocument);

	return (
		<Formik
			initialValues={{
				label: dataItem.label || '',
				fabric: dataItem.fabric || '',
				sku: dataItem.sku || '',
				description: dataItem.description || '',
				base_price: dataItem.base_price ? `${(dataItem.base_price / 100).toFixed(2)}` : undefined,
				production_price: dataItem.production_price ? `${(dataItem.production_price / 100).toFixed(2)}` : undefined,
				custom_name_enabled: dataItem.custom_name_enabled,
				custom_number_enabled: dataItem.custom_number_enabled,
				is_active: dataItem.is_active,
				short_size_restriction: dataItem.short_size_restriction,
				top_size_restriction: dataItem.top_size_restriction,
				custom_number_price: dataItem.custom_number_price
					? `${(dataItem.custom_number_price / 100).toFixed(2)}`
					: undefined,
				weight_in_ounces: dataItem.weight_in_ounces ? `${dataItem.weight_in_ounces}` : '0',
				custom_name_price: dataItem.custom_name_price ? `${(dataItem.custom_name_price / 100).toFixed(2)}` : undefined,
				show_in_export: dataItem.show_in_export ? dataItem.show_in_export : false,
				size_chart_url: dataItem.size_chart_url,
				shipping_origin: dataItem.shipping_origin,
				stripe_tax_identifier: dataItem.stripe_tax_identifier,
				public_product_image_url: dataItem.public_product_image_url,
				public_product: dataItem.public_product,
				public_product_description: dataItem.public_product_description
			}}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				const variables = {
					...values,
					weight_in_ounces: parseFloat(cleanNumberFormat(values.weight_in_ounces || '0')),
					base_price: Math.round(parseFloat(cleanNumberFormat(values.base_price || '0')) * 100),
					production_price: Math.round(parseFloat(cleanNumberFormat(values.production_price || '0')) * 100),
					custom_number_price: Math.round(parseFloat(cleanNumberFormat(values.custom_number_price || '0')) * 100),
					custom_name_price: Math.round(parseFloat(cleanNumberFormat(values.custom_name_price || '0')) * 100),
					productId: props.productId
				};

				await submit({
					variables
				});
				setSubmitting(false);
			}}
		>
			{({ isSubmitting, values, handleChange, setFieldValue, submitForm }) => {
				return (
					<Form className={classes.formContainer}>
						<AutoSave />
						<div className={classes.inputContainer}>
							<InputField
								name="label"
								value={values.label}
								variant="outlined"
								fullWidth
								label="Product Name"
								onChange={handleChange}
							/>
							<div className={classes.gridTwo}>
								<InputField
									variant="outlined"
									fullWidth
									label="Base Price"
									onChange={(e): void => {
										setFieldValue('base_price', e.target.value);
									}}
									name="base_price"
									value={values.base_price}
									InputProps={{
										inputComponent: NumberFormatCustom,
										startAdornment: (
											<InputAdornment position="start" className={classes.adornment}>
												<strong>$</strong>
											</InputAdornment>
										)
									}}
								/>
								<InputField
									variant="outlined"
									fullWidth
									label="Production Price"
									onChange={(e): void => {
										setFieldValue('production_price', e.target.value);
									}}
									name="production_price"
									helperText="Admin ONLY"
									value={values.production_price}
									InputProps={{
										inputComponent: NumberFormatCustom,
										startAdornment: (
											<InputAdornment position="start" className={classes.adornment}>
												<strong>$</strong>
											</InputAdornment>
										)
									}}
								/>
							</div>
							<div className={classes.gridTwo}>
								<InputField
									variant="outlined"
									fullWidth
									label="SKU Number"
									onChange={handleChange}
									name="sku"
									value={values.sku}
									helperText="Admin ONLY"
								/>
								<InputField
									variant="outlined"
									fullWidth
									label="Fabric"
									onChange={handleChange}
									name="fabric"
									value={values.fabric}
									helperText="Admin ONLY"
								/>
							</div>
							<div className={classes.gridTwo}>
								<InputField
									variant="outlined"
									fullWidth
									label="Product Weight"
									onChange={handleChange}
									name="weight_in_ounces"
									value={values.weight_in_ounces}
									helperText="Full numbers in Ounces"
								/>
								<InputField
									variant="outlined"
									fullWidth
									label="Product Sizing Chart"
									onChange={handleChange}
									name="size_chart_url"
									value={values.size_chart_url}
									helperText="URL to Sizing Chart"
								/>
							</div>
							<div className={classes.gridTwo}>
								<div>
									<Select
										variant="outlined"
										fullWidth
										native
										value={values.shipping_origin}
										onChange={handleChange}
										inputProps={{
											name: 'shipping_origin',
											id: 'group-simple'
										}}
									>
										<option value={'EGYPT'}>Egypt</option>
										<option value={'USA'}>USA</option>
									</Select>
								</div>
								<InputField
									variant="outlined"
									fullWidth
									label="Product Tax Identifier"
									onChange={handleChange}
									name="stripe_tax_identifier"
									value={values.stripe_tax_identifier}
									helperText="Product tax codes: https://stripe.com/docs/tax/tax-codes"
								/>
							</div>
							<div className={classes.textContainer}>
								<Typography variant="h2">Product Description</Typography>
								<InputField
									name="description"
									onChange={handleChange}
									value={values.description}
									variant="outlined"
									fullWidth
									label="Product Description"
									multiline
									rows={6}
									className={classes.textContainer}
								/>
							</div>

							<div className={classes.textContainer}>
								<Typography variant="h2" style={{ paddingBottom: 15 }}>
									Public Product Description
								</Typography>

								<MarkdownEditor
									content={values.public_product_description}
									onChange={(md) => setFieldValue('public_product_description', md)}
								/>
							</div>

							<div className={classes.gridTwo}>
								<div className={classes.switchComponent}>
									<Switch
										name="custom_name_enabled"
										color="primary"
										checked={values.custom_name_enabled}
										onChange={handleChange}
									/>
									<div>
										<Typography variant="h6" className={classes.label}>
											CUSTOM NAME
										</Typography>
										<InputField
											placeholder={'0.00'}
											variant="outlined"
											disabled={!values.custom_name_enabled}
											onChange={(e): void => {
												setFieldValue('custom_name_price', e.target.value);
											}}
											InputProps={{
												inputComponent: NumberFormatCustom,
												startAdornment: (
													<InputAdornment
														position="start"
														className={clsx(values.custom_name_enabled ? classes.adornment : null)}
													>
														<strong>$</strong>
													</InputAdornment>
												)
											}}
											value={values.custom_name_price}
										/>
									</div>
								</div>
								<div className={classes.switchComponent}>
									<Switch
										color="primary"
										name="custom_number_enabled"
										checked={values.custom_number_enabled}
										onChange={handleChange}
									/>
									<div>
										<Typography variant="h6" className={classes.label}>
											CUSTOM NUMBER
										</Typography>
										<InputField
											variant="outlined"
											disabled={!values.custom_number_enabled}
											placeholder={'0.00'}
											onChange={(e): void => {
												setFieldValue('custom_number_price', e.target.value);
											}}
											InputProps={{
												inputComponent: NumberFormatCustom,
												startAdornment: (
													<InputAdornment
														position="start"
														className={clsx(values.custom_number_enabled ? classes.adornment : null)}
													>
														<strong>$</strong>
													</InputAdornment>
												)
											}}
											value={values.custom_number_price}
										/>
									</div>
								</div>
							</div>

							<div className={classes.gridSection}>
								<div className={classes.section}>
									<div className={classes.sectionHeader}>
										<Typography variant="h6" className={classes.label}>
											Add Featured item options
										</Typography>
									</div>
									<RowToggleWithSwitch
										className={classes.rowToggle}
										title={'Show in Public Products'}
										handleUpdate={(v) => {
											setFieldValue('public_product', v);
										}}
										switchState={values.public_product}
										description="On breakmark.com this item will appear in the products section and be something individuals can look at"
									/>

									<div className={classes.imageContainer}>
										{!values.public_product_image_url && (
											<>
												<DropImage
													label="Add Featured item image"
													containerClass={classes.dropImageContainer}
													saveUrlOnSuccess={async (url: string): Promise<void> => {
														setFieldValue('public_product_image_url', url);
													}}
												/>
											</>
										)}
										{values.public_product_image_url && (
											<>
												<img src={values.public_product_image_url} width={100} height={100} />
												<ButtonBase
													className={classes.clearImageButton}
													onClick={(): void => {
														setFieldValue('public_product_image_url', null);
													}}
												>
													<P6>Clear Image</P6>
												</ButtonBase>
											</>
										)}
									</div>
								</div>
							</div>

							<div className={classes.gridSection}>
								<div className={classes.section}>
									<RowToggleWithSwitch
										className={classes.rowToggle}
										title={'Is Active'}
										handleUpdate={(v) => {
											setFieldValue('is_active', v);
										}}
										switchState={values.is_active}
										description="Show this product in team stores as an available item to be added."
									/>
									<RowToggleWithSwitch
										className={classes.rowToggle}
										title={'Show in Export'}
										handleUpdate={(v) => {
											setFieldValue('show_in_export', v);
										}}
										switchState={values.show_in_export}
										description="Show this product when design sheet is exported."
									/>
									<RowToggleWithSwitch
										className={classes.rowToggle}
										title={'Has Top Size Restriction'}
										handleUpdate={(v) => {
											setFieldValue('top_size_restriction', v);
										}}
										switchState={values.top_size_restriction}
										description="Is this product a top that has a size restriction based on fit?"
									/>
									<RowToggleWithSwitch
										className={classes.rowToggle}
										title={'Has Short Size Restriction'}
										handleUpdate={(v) => {
											setFieldValue('short_size_restriction', v);
										}}
										switchState={values.short_size_restriction}
										description="Is this product a short that has a size restriction based on fit?"
									/>
								</div>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		formContainer: {
			backgroundColor: '#FFF'
		},
		label: {
			color: SYSTEM_COLORS.GRAY_LIGHT,
			marginBottom: 5
		},
		inputContainer: {
			padding: 15
		},
		gridThree: {
			marginTop: 35,
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 1fr)',
			gap: '15px'
		},
		gridTwo: {
			marginTop: 35,
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
			gap: '15px'
		},
		adornment: {
			color: SYSTEM_COLORS.PRIMARY
		},
		textContainer: {
			marginTop: 35
		},
		withSwitch: {
			textAlign: 'center'
		},
		switchComponent: {
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'space-between'
		},
		rowToggle: {
			border: 'none',
			padding: '13px 15px !important',
			'&:hover': {
				borderRadius: 8
			}
		},
		gridSection: {
			marginTop: 15,
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '15px'
		},
		section: {
			backgroundColor: SYSTEM_COLORS.BACKGROUND,
			borderRadius: 8
		},
		sectionHeader: {
			padding: '10px 15px',
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`
		},
		clearImageButton: {
			display: 'block',
			textAlign: 'center',
			width: '100%'
		},

		imageContainer: {
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
			alignItems: 'center'
		},
		dropImageContainer: {
			width: '100%'
		}
	};
});

export default ManageProduct;
