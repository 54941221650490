import { useMutation } from '@apollo/client';
import { Dialog, DialogContent, Theme, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useState } from 'react';

import DialogActionBar from '../../../core/components/blocks/dialogActionBar';
import DialogTitleHeader from '../../../core/components/blocks/dialogTitleHeader';
import InputField from '../../../core/components/inputs/inputField';
import { ShowcaseBasicsFragment, UpdateShowcaseDocument } from '../../../generated/graphql';
import ImageContainer from './imageContainer';
import SearchSets, { SearchSet } from './searchSets';

interface ShowcaseState {
	label: string;
	image_url?: string | null;
	banner_url?: string | null;
	friendly_url_key?: string | null;
	search_sets?: SearchSet[];
}

interface Props {
	open: boolean;
	onClose: () => void;
	showcase: ShowcaseBasicsFragment;
}

const showcaseEditDialog = ({ open, onClose, showcase }: Props): ReactElement => {
	const classes = useStyles();
	const [state, setState] = useState<ShowcaseState>({
		label: showcase.label,
		image_url: showcase.image_url,
		banner_url: showcase.banner_url,
		search_sets: showcase.search_sets,
		friendly_url_key: showcase.friendly_url_key
	});
	const [saveShowcase, { loading }] = useMutation(UpdateShowcaseDocument);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		setState({
			label: showcase.label,
			image_url: showcase.image_url,
			banner_url: showcase.banner_url,
			search_sets: showcase.search_sets,
			...(showcase.friendly_url_key && showcase.friendly_url_key !== '' && { friendly_url_key: showcase.friendly_url_key })
		});
	}, [showcase]);

	const handleSave = async () => {
		try {
			await saveShowcase({
				variables: {
					id: showcase.id,
					label: state.label,
					image_url: state.image_url,
					banner_url: state.banner_url,
					search_sets: state.search_sets,
					...(state.friendly_url_key && state.friendly_url_key !== '' && { friendly_url_key: state.friendly_url_key })
				}
			});
			onClose();
		} catch (e) {
			enqueueSnackbar(
				e.message.indexOf('showcase_friendly_url_key_key') > 0
					? 'This key has already been used. Please use a new key.'
					: e.message,
				{
					variant: 'error'
				}
			);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth={'sm'}>
			<form className={classes.form}>
				<DialogTitleHeader title="Edit Showcase Store Details" closeDialog={onClose} />
				<DialogContent>
					<InputField
						className={classes.input}
						variant="outlined"
						label="Showcase Only Store Name"
						fullWidth
						name="label"
						onChange={(e) => {
							setState({ ...state, label: e.target.value });
						}}
						value={state.label}
						helperText={`Team Store Name: ${showcase.teamStoreByStoreShowcasingId.name}`}
					/>
					<InputField
						className={classes.input}
						variant="outlined"
						label="Friendly URL Key"
						fullWidth
						name="friendly_url_key"
						onChange={(e) => {
							const value = e.currentTarget.value.replace(/[^a-z0-9]/gi, '');
							setState({ ...state, friendly_url_key: value });
						}}
						value={state.friendly_url_key}
					/>
					<div className={classes.imageRow}>
						<ImageContainer
							width={120}
							height={120}
							label={'Drop Logo Here'}
							image_url={state.image_url}
							onImageUpdate={(url) => setState({ ...state, image_url: url })}
						/>
						<ImageContainer
							width={360}
							height={120}
							label={'Drop Banner Image Here'}
							image_url={state.banner_url}
							onImageUpdate={(url) => setState({ ...state, banner_url: url })}
						/>
					</div>
					<SearchSets sets={state.search_sets} onChange={(sets) => setState({ ...state, search_sets: sets })} />
				</DialogContent>
				<DialogActionBar cancelAction={onClose} saveAction={handleSave} disableSave={loading} />
			</form>
		</Dialog>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		form: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1
		},
		input: {
			marginBottom: 15
		},
		imageRow: {
			display: 'flex'
		}
	};
});

export default showcaseEditDialog;
