import { Checkbox, Collapse, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { mdiMinus, mdiPlus } from '@mdi/js';
import React from 'react';

import PrimaryButton from '../../../../../../buttons/primaryButton';

interface GroupHeaderProps {
	date: string;
	isGroupSelected: boolean;
	onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const GroupHeader = ({ date, isGroupSelected, onToggle }: GroupHeaderProps) => {
	const classes = useStyles();

	return (
		<TableRow className={classes.groupHeader}>
			<TableCell colSpan={9} className={classes.noPadding}>
				<div className={classes.groupHeaderCell}>
					<div className={classes.items}>
						<Checkbox checked={isGroupSelected} onChange={onToggle} />
						<Typography variant="subtitle1">Week of {date}</Typography>
					</div>
				</div>
			</TableCell>
		</TableRow>
	);
};

const useStyles = makeStyles((theme) => ({
	groupHeader: {
		backgroundColor: theme.palette.grey[200]
	},
	groupHeaderCell: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
		justifyContent: 'space-between',
		paddingLeft: 5,
		paddingRight: 15
	},
	items: {
		display: 'flex',
		alignItems: 'center'
	},
	noPadding: {
		padding: 0
	}
}));

export default GroupHeader;
