import { gql, useApolloClient, useMutation } from '@apollo/client';
import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useState } from 'react';

import DialogActionBar from '../../../../../../core/components/blocks/dialogActionBar';
import DialogBase from '../../../../../../core/components/blocks/dialogBase';
import DialogTitleHeader from '../../../../../../core/components/blocks/dialogTitleHeader';
import InputField from '../../../../../../core/components/inputs/inputField';
import { UpdateTeamStoreProductCustomNameNumberListDocument } from '../../../../../../generated/graphql';

interface Props {
	open: boolean;
	handleClose(success?: boolean): void;
	id: string;
}

const DialogAddCustomNameNumber = (props: Props): ReactElement => {
	const [textData, setTextData] = useState<undefined | string>();
	const { enqueueSnackbar } = useSnackbar();
	const [updateAdditionalImages] = useMutation(UpdateTeamStoreProductCustomNameNumberListDocument);

	const handleSave = async () => {
		try {
			await updateAdditionalImages({
				variables: {
					custom_name_number_dropdown: textData ? JSON.parse(textData) : [],
					id: props.id
				}
			});

			enqueueSnackbar('Successfully added metadata to products!', {
				variant: 'success'
			});
		} catch (E) {
			enqueueSnackbar('There was an error adding your metadata labels' + E.message, {
				variant: 'error'
			});
		}
		props.handleClose();
	};

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader title="" closeDialog={props.handleClose} />
			<DialogContent style={{ padding: 15 }}>
				<InputField
					variant="outlined"
					label="Add a custom list of player names/numbers."
					fullWidth
					onChange={(e) => {
						setTextData(e.target.value);
					}}
					multiline
				/>
			</DialogContent>
			<DialogActionBar cancelAction={props.handleClose} saveAction={handleSave} />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {}
	};
});

export default DialogAddCustomNameNumber;
