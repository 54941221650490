import { Card, CardActions, CardContent, CardHeader, CardMedia, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiClose, mdiPlus } from '@mdi/js';
import React, { ReactElement } from 'react';

import { TeamStoreProductListFragment } from '../../../../../../../../generated/graphql';
import PrimaryButton from '../../../../../../buttons/primaryButton';
import SecondadryButton from '../../../../../../buttons/secondaryButton/secondaryButton';

interface Props {
	index: number;
	isAdded: boolean;
	product: any;
	handleAdd(): void;
	handleRemove(): void;
}

const ProductWrapper = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardHeader title={props.product.label} />
			{props.product.display_photo && (
				<CardMedia className={classes.media} image={props.product.display_photo} title={props.product.label || ''} />
			)}
			<CardActions className={classes.actions}>
				{!props.isAdded && (
					<PrimaryButton
						startIcon={
							<SvgIcon>
								<path d={mdiPlus} />
							</SvgIcon>
						}
						onClick={(): void => {
							props.handleAdd();
						}}
					>
						Add
					</PrimaryButton>
				)}
				{props.isAdded && (
					<SecondadryButton
						endIcon={
							<SvgIcon>
								<path d={mdiClose} />
							</SvgIcon>
						}
						onClick={(): void => {
							props.handleRemove();
						}}
					>
						{props.index + 1} - Remove
					</SecondadryButton>
				)}
			</CardActions>
		</Card>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		root: {
			maxWidth: 200
		},
		media: {
			height: 100
		},
		actions: {
			justifyContent: 'center'
		}
	};
});

export default ProductWrapper;
