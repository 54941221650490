// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { useMutation } from '@apollo/client';
import { Theme, makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useState } from 'react';
// Import React FilePond
import { FilePond } from 'react-filepond';

import { CreateProductFromSignedUrlDocument } from '../../../generated/graphql';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately

interface Props {
	saveUrlOnSuccess(url: string): void;
	id?: string;
	width?: number;
	height?: number;
	label?: string;
	storeId: string;
}

const ProductCreator = (props: Props): ReactElement => {
	const classes = useStyles();
	const [CreateProductFromSignedUrl] = useMutation(CreateProductFromSignedUrlDocument);
	const [files, setFiles] = useState<any[]>([]);

	return (
		<div className={classes.filePondContainer}>
			<FilePond
				className={classes.filePond}
				files={files}
				onupdatefiles={setFiles}
				allowMultiple={true}
				allowPaste={false}
				allowRevert={false}
				instantUpload={true}
				server={{
					process: async (fileName, file, metadata, load, error, progress, abort) => {
						// related to aborting the request
						const CancelToken = axios.CancelToken;
						const source = CancelToken.source();

						const data = await CreateProductFromSignedUrl({
							variables: {
								storeId: props.storeId,
								fileName: props.id ? `${props.id}/${file.name}` : file.name,
								fileType: file.type
							}
						});

						if (
							!data.data?.createProductFromSignedURL?.success ||
							!data.data?.createProductFromSignedURL?.signedRequest
						) {
							abort();
							return;
						}

						axios({
							method: 'PUT',
							onUploadProgress: (e) => {
								// updating progress indicator
								progress(e.lengthComputable, e.loaded, e.total);
							},
							url: data.data?.createProductFromSignedURL?.signedRequest,
							cancelToken: source.token,
							data: file,
							headers: {
								'Content-Type': file.type
							}
						})
							.then((response) => {
								// passing the file id to FilePond
								load(file.name);
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								props.saveUrlOnSuccess(data.data!.createProductFromSignedURL!.url!);
							})
							.catch((thrown) => {
								//
							});
						return {
							abort: () => {
								source.cancel('Operation canceled by the user.');
							}
						};
					}
				}}
				name="files"
				labelIdle={props.label || 'Drop Image Here'}
			/>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		filePondContainer: {
			width: '100%',
			height: '100%',
			'& .filepond--credits': {
				display: 'none'
			}
		},
		filePond: {
			width: '100%',
			height: '100%'
		}
	};
});

export default ProductCreator;
