import { useMutation } from '@apollo/client';
import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React, { ReactElement } from 'react';

import DialogActionBar from '../../../../core/components/blocks/dialogActionBar';
import DialogBase from '../../../../core/components/blocks/dialogBase';
import DialogTitleHeader from '../../../../core/components/blocks/dialogTitleHeader';
import InputField from '../../../../core/components/inputs/inputField';
import { InsertAvailableOptionDocument } from '../../../../generated/graphql';

interface Props {
	handleSuccessfulClose(): void;
	handleClose(): void;
	open: boolean;
}

const NewOptionDialog = (props: Props): ReactElement => {
	const classes = useStyles();
	const [saveOption, { loading }] = useMutation(InsertAvailableOptionDocument);

	return (
		<DialogBase closeDialog={props.handleClose} open={props.open}>
			<Formik
				initialValues={{
					name: '',
					description: '',
					csv_key: ''
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					await saveOption({
						variables: {
							...values
						}
					});
					setSubmitting(false);
					props.handleSuccessfulClose();
				}}
			>
				{({ isSubmitting, values, handleChange, submitForm }) => {
					return (
						<form className={classes.form}>
							<DialogTitleHeader title="Add New Option" closeDialog={props.handleClose} />
							<DialogContent>
								<InputField
									autoFocus={true}
									onKeyDown={(e) => {
										if (e.keyCode === 13 || e.key === 'Enter') {
											submitForm();
											e.preventDefault();
										}
									}}
									className={classes.input}
									variant="outlined"
									label="Option Name"
									fullWidth
									name="name"
									onChange={handleChange}
									value={values.name}
								/>
								<InputField
									multiline
									className={classes.input}
									rows={4}
									variant="outlined"
									label="Description"
									fullWidth
									name="description"
									onChange={handleChange}
									value={values.description}
								/>
								<InputField
									onKeyDown={(e) => {
										if (e.keyCode === 13 || e.key === 'Enter') {
											submitForm();
											e.preventDefault();
										}
									}}
									className={classes.input}
									variant="outlined"
									label="CSV Key"
									helperText="The CSV key you want this option represented as."
									fullWidth
									name="csv_key"
									onChange={handleChange}
									value={values.csv_key}
								/>
							</DialogContent>
							<DialogActionBar
								cancelAction={props.handleClose}
								saveAction={submitForm}
								disableSave={isSubmitting || loading}
							/>
						</form>
					);
				}}
			</Formik>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		form: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1
		},
		input: {
			marginBottom: 15
		}
	};
});

export default NewOptionDialog;
