/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { mdiLoading, mdiTshirtCrewOutline } from '@mdi/js';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import { TeamStoreOrderBasicFragment } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import NoEntryContainer from '../../../../../../blocks/containerNoEntries/containerNoEntries';
import GroupHeader from './groupHeader';
import OrderRow from './orderRow';

interface Props {
	orders: TeamStoreOrderBasicFragment[];
	refetchMetrics?(): void;
	refetchStore?(): void;
	searchTerm?: string;
	loading: boolean;
	selectedRows: string[];
	setSelected?(rows: string[]): void;
}

interface GroupedOrders {
	[date: string]: TeamStoreOrderBasicFragment[];
}
interface ExpandedGroups {
	[date: string]: boolean;
}

const groupedOrders = (rearrangeOrders: TeamStoreOrderBasicFragment[]) => {
	return rearrangeOrders.reduce<GroupedOrders>((acc, order) => {
		const weekStart = dayjs(order.created_at).startOf('week').format('MMM D, YYYY');
		if (!acc[weekStart]) {
			acc[weekStart] = [];
		}
		acc[weekStart].push(order);
		return acc;
	}, {});
};

function OrderTable(props: Props) {
	const { orders, selectedRows: selected, setSelected } = props;
	const classes = useStyles();
	const [dateSelected, setDateSelected] = useState<string | null>(null);

	const toggleGroup = (event: React.ChangeEvent<HTMLInputElement>, date: string): void => {
		if (setSelected) {
			if (event.target.checked) {
				const updatedOrders: string[] = [];
				orders.forEach((order) => {
					if (dayjs(order.created_at).startOf('week').format('MMM D, YYYY') === date) {
						updatedOrders.push(order.id);
					}
				});

				setSelected(updatedOrders);
				setDateSelected(date);
				return;
			}

			setDateSelected(null);
			setSelected([]);
		}
	};

	const filteredOrderList: GroupedOrders = useMemo(() => {
		if (!props.orders) {
			return {};
		}

		if (!props.searchTerm || props.searchTerm === '') {
			return groupedOrders(props.orders);
		}
		const filtered = props.orders.filter((order) => {
			if (order.customer_name && order.customer_name.toLowerCase().includes(props.searchTerm!.toLowerCase())) {
				return true;
			} else if (
				order.order_readable_column &&
				`${order.order_readable_column}`.includes(props.searchTerm!.toLowerCase())
			) {
				return true;
			}
			return false;
		});

		return groupedOrders(filtered);
	}, [props.searchTerm, props.orders]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (setSelected) {
			if (event.target.checked) {
				const newSelecteds = orders.map((order) => order.id);
				setSelected(newSelecteds);
				return;
			}
			setSelected([]);
		}
	};

	const handleClick = (id: string) => {
		if (setSelected) {
			const selectedIndex = selected.indexOf(id);
			let newSelected: string[] = [];

			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, id);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
			}

			setSelected(newSelected);
		}
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	if (props.loading) {
		return (
			<NoEntryContainer
				baseColor={SYSTEM_COLORS.SECONDARY}
				title="Loading"
				iconPath={mdiLoading}
				description="Hang tight while we fetch that report!"
			/>
		);
	}

	if (Object.keys(filteredOrderList).length === 0) {
		return (
			<NoEntryContainer
				baseColor={SYSTEM_COLORS.SECONDARY}
				title="No Orders"
				iconPath={mdiTshirtCrewOutline}
				description="You currently do not have any orders created."
			/>
		);
	}

	const disableSelectedRow = !setSelected;

	return (
		<TableContainer className={classes.tableContainer}>
			<Table className={classes.root}>
				<TableHead>
					<TableRow>
						{!disableSelectedRow && (
							<TableCell className={clsx(classes.headerCell, classes.checkboxCell)}>
								<Checkbox
									indeterminate={selected.length > 0 && selected.length < orders.length}
									checked={selected.length === orders.length}
									onChange={handleSelectAllClick}
								/>
							</TableCell>
						)}

						<TableCell className={clsx(classes.headerCell, classes.orderCell)}>Order</TableCell>
						<TableCell className={clsx(classes.headerCell, classes.customerCell)}>Customer</TableCell>
						<TableCell className={clsx(classes.headerCell, classes.dateCell)}>Date</TableCell>
						<TableCell className={clsx(classes.headerCell, classes.statusCell)}>Order Status</TableCell>
						<TableCell className={clsx(classes.headerCell, classes.statusCell)}>Delivery Status</TableCell>
						<TableCell className={clsx(classes.headerCell, classes.purchaseInfoCell)}>Info</TableCell>
						<TableCell className={clsx(classes.headerCell, classes.totalCell)}>Total</TableCell>
						<TableCell className={clsx(classes.headerCell, classes.actionsCell)}>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.entries(filteredOrderList).map(([date, groupedOrders]) => (
						<React.Fragment key={date}>
							{!disableSelectedRow && (
								<GroupHeader
									isGroupSelected={date === dateSelected}
									date={date}
									onToggle={(event) => toggleGroup(event, date)}
								/>
							)}
							{groupedOrders.map((order) => {
								const isItemSelected = isSelected(order.id);
								// const isItemExpanded = expanded === order.id;

								return (
									<OrderRow
										disableSelectedRow={disableSelectedRow}
										refetchMetrics={props.refetchMetrics}
										refetchStore={props.refetchStore}
										order={order}
										isItemSelected={isItemSelected}
										handleCheckboxClick={handleClick}
									/>
								);
							})}
						</React.Fragment>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			// borderRadius: 8
		},
		tableContainer: {
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: 8
		},
		table: {
			minWidth: 750,
			borderCollapse: 'collapse'
		},
		tableRow: {},
		headerCell: {
			padding: 4,
			fontWeight: 'bold',
			color: theme.palette.text.secondary
		},
		checkboxCell: {
			padding: 4,
			width: 50
		},
		expandableCell: {
			padding: 4,
			width: 30
		},
		dateCell: {
			padding: 4,
			width: 110
		},
		orderCell: {
			padding: 4,
			width: 80
		},
		customerCell: {
			padding: 4
			// width: 400
		},

		totalCell: {
			padding: 4,
			fontWeight: 'bold',
			width: 75
		},
		purchaseInfoCell: {
			textAlign: 'center',
			padding: 4,
			width: 50
		},
		actionsCell: {
			padding: 4,
			width: 80,
			textAlign: 'right',
			paddingRight: 20
		},

		iconContainer: {
			'& :not(:first-child)': {
				marginLeft: 5
			}
		},
		subIcon: {
			color: SYSTEM_COLORS.LIGHT_GRAY_30,
			width: 16,
			height: 16
		},
		successIcon: {
			color: SYSTEM_COLORS.GREEN_DEFAULT
		},
		statusCell: {
			padding: 4,
			fontWeight: 'bold',
			width: 235
		}
	})
);

export default OrderTable;
