export const STORE_SUB_ROUTE_DETAILS = {
	STORE: {
		route: ''
	},
	PRODUCTS: {
		route: 'products'
	},
	PACKAGES: {
		route: 'packages'
	},
	ORDERS: {
		route: 'orders'
	},
	ORDERS_FROM_GROUP: {
		route: 'orders-from-group'
	},
	SHOWCASING_STORES: {
		route: 'showcase'
	}
};
