import { DocumentNode } from 'graphql';
import { useMemo, useState } from 'react';

import {
	GetDataExportUrlDocument,
	GetExportDesignAutomationDataDocument,
	GetProdDataExportUrlDocument,
	GetProdDataForDesignExportUrlDocument,
	GetProdShippingDataExportUrlDocument,
	GetTeamStoreOrdersQuery
} from '../../../../../../../generated/graphql';
import { getOrderStatusReadableByIndex } from '../../../../../../utilites/orderHelpers';
import { MenuItemSpec } from '../../../../../blocks/buttonMenu/buttonMenu';
import ExportDialog from '../../../../../dialogs/exportDialog';

interface ExportDataStateBase {
	type: string;
	title: string;
	queryDocument: DocumentNode;
	origin: 'USA' | 'EGYPT';
}

interface HookProps {
	orderStatusType: number;
	data?: GetTeamStoreOrdersQuery;
	storeId?: string;
}

interface HookOutput {
	menuSpec: MenuItemSpec[];
	ExportDialog: JSX.Element | null;
}

const exportDataVariants: Array<ExportDataStateBase> = [
	{
		title: 'Prod Sheet',
		type: 'exportDataMaster',
		queryDocument: GetProdDataExportUrlDocument,
		origin: 'USA'
	},
	{ title: 'Prod Sheet', type: 'exportDataMaster', queryDocument: GetProdDataExportUrlDocument, origin: 'EGYPT' },
	{
		title: 'Prod Design Sheet',
		type: 'exportDataForDesignMaster',
		queryDocument: GetProdDataForDesignExportUrlDocument,
		origin: 'USA'
	},
	{
		title: 'Prod Design Sheet',
		type: 'exportDataForDesignMaster',
		queryDocument: GetProdDataForDesignExportUrlDocument,
		origin: 'EGYPT'
	},
	{
		title: 'Customer Sheet',
		type: 'exportData',
		queryDocument: GetDataExportUrlDocument,
		origin: 'USA'
	},
	{ title: 'Customer Sheet', type: 'exportData', queryDocument: GetDataExportUrlDocument, origin: 'EGYPT' },
	{
		title: 'Shipping Order',
		type: 'exportDataForShippingOrder',
		queryDocument: GetProdShippingDataExportUrlDocument,
		origin: 'USA'
	},
	{
		title: 'Shipping Order',
		type: 'exportDataForShippingOrder',
		queryDocument: GetProdShippingDataExportUrlDocument,
		origin: 'EGYPT'
	},
	{
		title: 'Design Automation URL',
		type: 'exportDataForShippingOrder',
		queryDocument: GetExportDesignAutomationDataDocument,
		origin: 'USA'
	},
	{
		title: 'Design Automation URL',
		type: 'exportDataForShippingOrder',
		queryDocument: GetExportDesignAutomationDataDocument,
		origin: 'EGYPT'
	}
];

const useExportMenuAndDialogs = ({ orderStatusType, data, storeId }: HookProps): HookOutput => {
	const [exportState, setExportState] = useState<ExportDataStateBase | undefined>(undefined);

	const menuSpec: MenuItemSpec[] = useMemo(() => {
		const exportType = orderStatusType !== 0 ? getOrderStatusReadableByIndex(orderStatusType - 1) : 'all';

		const hasUsOrder = data?.order?.some((o) => o.shipping_origin === 'USA');
		const hasEgyptOrder = data?.order?.some((o) => o.shipping_origin === 'EGYPT');

		return exportDataVariants.map((variant, key) => {
			return {
				key: `${key}`,
				label: `Export ${variant.title} - ${variant.origin} (${exportType})`,
				onClick: (): void => {
					setExportState(variant);
				},
				disabled: variant.origin === 'USA' ? !hasUsOrder : !hasEgyptOrder
			};
		});
	}, [orderStatusType, data?.order]);

	const ExportDialogMemoized = useMemo(() => {
		if (!exportState || !storeId) return null;

		const foundExport = exportDataVariants.find((variant) => {
			return variant.type === exportState.type && variant.origin === exportState.origin;
		});

		if (foundExport) {
			return (
				<ExportDialog
					title={exportState.type}
					queryDocument={exportState.queryDocument}
					onClose={() => setExportState(undefined)}
					variables={{
						storeId,
						orderStatusType,
						origin: exportState.origin
					}}
				/>
			);
		}

		return null;
	}, [exportState, storeId, orderStatusType]);

	return { menuSpec, ExportDialog: ExportDialogMemoized };
};

export default useExportMenuAndDialogs;
