import { IconButton, Menu, SvgIcon, Theme, makeStyles } from '@material-ui/core';
import React, { Fragment, ReactElement, ReactNode } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import IntegratedButton from '../../buttons/integratedButton';

export interface MenuItemSpec {
	key: string;
	label: string;
	onClick<T>(arg?: T): void;
	disabled?: boolean;
	startIcon?: ReactNode;
	hasDivider?: boolean;
}

interface Props<T> {
	iconPath: string;
	menuSpec: MenuItemSpec[];
	menuArg?: T;
}

const ButtonMenu = <T,>(props: Props<T>): ReactElement => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<IconButton className={classes.iconButton} onClick={handleClick}>
				<SvgIcon>
					<path d={props.iconPath} />
				</SvgIcon>
			</IconButton>
			<Menu
				classes={{
					paper: classes.menuContainer
				}}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{props.menuSpec.map((m) => {
					return (
						<div key={m.key}>
							{m.hasDivider && <div className={classes.divider} />}
							<div className={classes.linkWrapper}>
								<IntegratedButton
									className={classes.menuButtonContainer}
									onClick={() => {
										m.onClick(props?.menuArg);
										setAnchorEl(null);
									}}
									startIcon={m.startIcon}
								>
									{m.label}
								</IntegratedButton>
							</div>
						</div>
					);
				})}
			</Menu>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		menuContainer: {
			minWidth: 150
		},
		iconButton: {
			marginLeft: 8,
			borderRadius: 8,
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: 8
		},
		linkWrapper: {
			display: 'flex',
			textDecoration: 'none',
			width: '100%',
			'&:hover': {
				textDecoration: 'none'
			}
		},
		menuButtonContainer: {
			borderRadius: 4,
			flex: 1,
			justifyContent: 'flex-start'
		},
		divider: {
			width: '100%',
			height: 1,
			backgroundColor: 'rgba(48, 46, 40, 0.1)'
		}
	};
});

export default ButtonMenu;
