import React, { ReactElement } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import Product from '../../../../page/teams/pages/product';
import StoreProvider from '../../../../providers/storeProvider';
import StoreSection from './storeSections';

const Store = (): ReactElement | null => {
	const { storeId } = useParams<'storeId'>();

	if (!storeId) {
		return null;
	}

	return (
		<>
			<StoreProvider storeId={storeId}>
				<Routes>
					<Route path="product/:productId" element={<Product />} />
					<Route path="/*" element={<StoreSection />} />
				</Routes>
			</StoreProvider>
		</>
	);
};
export default Store;
