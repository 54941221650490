import { useQuery } from '@apollo/client';
import { Paper, Theme, Tooltip, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement, useMemo } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';

const calculateSums = (teamStores: any[]) => {
	const sums = {
		originalOrderPrice: 0,
		stripeTaxCharged: 0,
		stripeTotalCharged: 0,
		shipstationShipPrice: 0,
		shippingCharged: 0
	};

	teamStores.forEach((teamStore) => {
		teamStore.orders.forEach((order: any) => {
			// At times we do a manual change to the order total. adjust here.
			const orderTotal =
				order.total !== null && order.total !== order.original_order_price ? order.total : order.original_order_price;

			sums.originalOrderPrice += orderTotal || 0;
			sums.stripeTaxCharged += order.stripe_tax_charged || 0;
			sums.shippingCharged += order.shipping_charged || 0;

			if (order.stripe_total_charged !== null) {
				sums.stripeTotalCharged += order.stripe_total_charged;
			} else if (order.stripe_tax_charged === null && order.additional_origin_cart_id === null) {
				// The null value here assumes that there is a secondary cart. If that secondary cart exists, we do not want to double count the values.
				sums.stripeTotalCharged += orderTotal + order.stripe_tax_charged + order.shipping_charged;
			} else if (order.checkout_type === 'check') {
				sums.stripeTotalCharged += orderTotal;
			}
			const shipstationPrice = order.shipstation_ship_price !== null ? parseFloat(order.shipstation_ship_price) : 0;

			sums.shipstationShipPrice += shipstationPrice * 100; // Convert to cents if it's in dollars
		});
	});

	return sums;
};

const Metric = ({ label, value, tooltip }: { label: string; value: string; tooltip: string }) => {
	const classes = useStyles();
	return (
		<Tooltip title={tooltip}>
			<div className={clsx(classes.dataContainer)}>
				<Typography variant="h6">{label}</Typography>
				<Typography variant="h3">{value}</Typography>
			</div>
		</Tooltip>
	);
};

interface Props {
	teamStores: any[];
}

const StoreMetrics = (props: Props): ReactElement => {
	const classes = useStyles();
	const { teamStores } = props;

	// Compute the sums using useMemo
	const sums = useMemo(() => calculateSums(teamStores), [teamStores]);

	// Convert sums to currency format
	const formatCurrency = (amount: number) => `$${(amount / 100).toFixed(2)}`;

	return (
		<Paper className={classes.metrics}>
			<Metric
				tooltip="The total in sales from items sold in the store"
				label="Total Sales"
				value={formatCurrency(sums.originalOrderPrice)}
			/>
			<Metric label="Tax Charged" value={formatCurrency(sums.stripeTaxCharged)} tooltip="The total tax charged on sales" />
			<Metric
				label="Total Charged"
				value={formatCurrency(sums.stripeTotalCharged)}
				tooltip="The total amount charged, including tax and shipping"
			/>
			<Metric
				label="Shipstation Ship Price"
				value={formatCurrency(sums.shipstationShipPrice)}
				tooltip="The total shipping price from Shipstation"
			/>
			<Metric
				label="Shipping Charged"
				value={formatCurrency(sums.shippingCharged)}
				tooltip="The total shipping charges for the orders"
			/>
		</Paper>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		dataContainer: {
			textAlign: 'center',
			padding: '25px 15px',
			'& h6': {
				color: SYSTEM_COLORS.GRASCALE_GRAY_DARK
			},
			'& h3': {
				color: SYSTEM_COLORS.PRIMARY
			}
		},
		metrics: {
			marginBottom: 15,
			display: 'grid',
			gridTemplateColumns: 'repeat(5, 1fr)',
			gap: '15px',
			backgroundColor: SYSTEM_COLORS.SECONDARY
		}
	};
});

export default StoreMetrics;
