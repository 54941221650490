import { Switch, withStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import RowToggle, { RowToggleProps } from '../rowToggle/rowToggle';

interface Props extends RowToggleProps {
	handleUpdate(value: boolean): void;
	disabled?: boolean;
	switchState: boolean;
	disableRipple?: boolean;
	switchClass?: string;
}

const PrimarySwitch = withStyles({
	switchBase: {
		color: SYSTEM_COLORS.GRAY_LIGHT,
		'& + $track': {
			opacity: 1,
			backgroundColor: SYSTEM_COLORS.GRAY_10
		},
		'&$checked': {
			color: SYSTEM_COLORS.PRIMARY
		},
		'&$checked + $track': {
			backgroundColor: SYSTEM_COLORS.PRIMARY
		}
	},
	checked: {},
	track: {}
})(Switch);

const RowToggleWithSwitch = (props: Props): ReactElement => {
	const { handleUpdate, disabled, switchState, switchClass, ...rest } = props;
	return (
		<RowToggle
			{...rest}
			disabled={disabled}
			onClick={(): void => {
				handleUpdate(!switchState);
			}}
		>
			<PrimarySwitch
				className={switchClass}
				disableRipple={props.disableRipple}
				checked={switchState}
				onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
					handleUpdate(e.target.checked);
				}}
				disabled={disabled}
			/>
		</RowToggle>
	);
};

export default RowToggleWithSwitch;
