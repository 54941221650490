import { Paper, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

interface Props {
	iconPath: string;
	iconColor: string;
	title: string;
	children: ReactNode;
	className?: string;
	headerClass?: string;
}

const ContentSection = (props: Props): ReactElement => {
	const classes = useStyles({
		iconColor: props.iconColor
	});

	return (
		<Paper className={clsx(classes.contentSection, props.className)}>
			<div className={clsx(classes.header, props.headerClass)}>
				<SvgIcon className={classes.icon}>
					<path d={props.iconPath} />
				</SvgIcon>
				<Typography variant="h4">{props.title}</Typography>
			</div>
			{props.children}
		</Paper>
	);
};

interface StyleProps {
	iconColor: string;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		contentSection: {
			padding: 15,
			borderRadius: 16,
			[theme.breakpoints.down('sm')]: {
				borderRadius: 0,
				padding: 10
			}
		},
		header: {
			display: 'flex',
			paddingBottom: 10,
			paddingLeft: 15,
			alignItems: 'center'
		},
		icon: {
			width: 24,
			height: 24,
			marginRight: 10,
			color: (props: StyleProps): string => props.iconColor
		}
	};
});

export default ContentSection;
