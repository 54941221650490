import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

interface Options {
	message: string;
	stringToCopy: string;
}

type CopyReturn = () => void;

export const useCopy = (options: Options): CopyReturn => {
	const { stringToCopy, message } = options;
	const { enqueueSnackbar } = useSnackbar();
	const CopyLink = useCallback((): void => {
		copy(stringToCopy);
		enqueueSnackbar(message, {
			variant: 'success'
		});
	}, [enqueueSnackbar, stringToCopy, message]);

	return CopyLink;
};
