import { DialogContent, Theme, makeStyles } from '@material-ui/core';
import { DocumentNode } from 'graphql';
import React, { ReactElement, useEffect } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import { useAbstractQuery } from '../../../hooks/useAbstractQuery';
import DialogBase from '../../blocks/dialogBase';
import DialogTitleHeader from '../../blocks/dialogTitleHeader';
import P3 from '../../typography/p3';

interface Props {
	onClose: () => void;
	title?: string;
	message?: string;
	queryDocument: DocumentNode;
	variables: Record<string, any>;
}

const ExportDialog = ({ message, onClose, queryDocument, variables, title }: Props): ReactElement => {
	const classes = useStyles();
	const data = useAbstractQuery({ queryDocument, variables });

	useEffect(() => {
		if (data) {
			const url = data[Object.keys(data)[0]]?.url;
			// Replace with your actual logic for handling the returned data
			const link = document.createElement('a');
			link.target = '_self';
			link.href = url;
			link.click();
			onClose();
		}
	}, [data, onClose]);

	return (
		<DialogBase open={true}>
			<DialogTitleHeader closeDialog={onClose} title={title || 'Loading...'} />
			<DialogContent>
				<div className={classes.loadingContainer}>
					<P3 className={classes.p3}>{'Generating that report, hold on a moment'}</P3>
				</div>
			</DialogContent>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		p3: {
			paddingTop: 5,
			color: SYSTEM_COLORS.GRAY
		},
		loadingContainer: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			textAlign: 'center'
		}
	};
});

export default ExportDialog;
