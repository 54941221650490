import { useQuery } from '@apollo/client';
import { Card, Paper, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import Loader from '../../core/components/blocks/loader';
import PaperHeaderContainer from '../../core/components/blocks/paperHeaderContainer';
import { GetIsTeamShowcaseTypeDocument } from '../../generated/graphql';
import ShowcaseSelector from './components/showcaseSelector';
import Group from './group';

interface Props {
	c?: any;
}

const GroupType = (props: Props): ReactElement => {
	const classes = useStyles();
	const { data, loading, refetch, error } = useQuery(GetIsTeamShowcaseTypeDocument, {
		variables: {
			_eq: true
		}
	});

	if (loading) {
		return <Loader />;
	} else if (error) {
		return <div> There was an error, refresh your page</div>;
	}

	const store = data?.team[0];

	if (!store) {
		return <ShowcaseSelector refetch={refetch} />;
	}
	return <Group groupId={store.id} />;
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {},
		body: {
			padding: 15,
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '20px'
		}
	};
});

export default GroupType;
