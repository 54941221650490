import { useMutation } from '@apollo/client';
import { Avatar, DialogContent, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiDelete } from '@mdi/js';
import React, { ReactElement } from 'react';

import { CancelOrderByOrderPkDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../../../../../../config/colors';
import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import P3 from '../../../../../../typography/p3';

interface Props {
	orderId: string;
	handleClose(saved?: boolean): void;
	open: boolean;
}

const DialogCancelOrder = (props: Props): ReactElement => {
	const classes = useStyles();
	const [cancelOrder, { loading }] = useMutation(CancelOrderByOrderPkDocument, {
		variables: {
			id: props.orderId
		}
	});

	const handleSave = async () => {
		if (loading) {
			return;
		}
		await cancelOrder();
		props.handleClose();
	};

	return (
		<DialogBase open={props.open}>
			<DialogTitleHeader closeDialog={props.handleClose} title="Are you sure?" />
			<DialogContent className={classes.dialogContent}>
				<Avatar className={classes.avatar}>
					<SvgIcon>
						<path d={mdiDelete} />
					</SvgIcon>
				</Avatar>
				<Typography variant="h5">Are you sure you want to cancel this order?</Typography>
				<P3 className={classes.p3}>
					By canceling, it will not appear on the exported spreadsheets or in store totals. <br />
					<br />
					NOTE: This will NOT auto-initiate any kind of refunds through stripe. You will need to do that yourself.
				</P3>
			</DialogContent>
			<DialogActionBar
				cancelLabel="No, Don't Cancel Order"
				cancelAction={props.handleClose}
				disableSave={loading}
				saveAction={handleSave}
				saveLabel="Yes, Cancel Order"
			/>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		avatar: {
			width: 128,
			height: 128,
			backgroundColor: getRGBAFromHex(SYSTEM_COLORS.RED_DEFAULT, 0.15),
			'& svg': {
				width: 78,
				height: 78,
				color: SYSTEM_COLORS.RED_DEFAULT
			},
			marginBottom: 50
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			maxWidth: '500px',
			marginLeft: 'auto',
			marginRight: 'auto',
			justifyContent: 'center',
			textAlign: 'center'
		},
		p3: {
			paddingTop: 5
		}
	};
});

export default DialogCancelOrder;
