import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { useMatches } from 'react-router-dom';

import ButtonBase from '../../../../buttons/buttonBase';
import { STORE_SUB_ROUTE_DETAILS } from '../../subRoutes';

interface Props {
	isTeamShowcaseStore?: boolean;
}

const NavSpec = [
	{
		to: STORE_SUB_ROUTE_DETAILS.STORE.route,
		label: 'Store',
		showWhenShowcase: true,
		showWhenNotShowcase: true
	},
	{
		to: STORE_SUB_ROUTE_DETAILS.PRODUCTS.route,
		label: 'Products',
		showWhenShowcase: false,
		showWhenNotShowcase: true
	},
	{
		to: STORE_SUB_ROUTE_DETAILS.PACKAGES.route,
		label: 'Bundles',
		showWhenShowcase: false,
		showWhenNotShowcase: true
	},
	{
		to: STORE_SUB_ROUTE_DETAILS.SHOWCASING_STORES.route,
		label: 'Showcase',
		showWhenShowcase: true,
		showWhenNotShowcase: false
	},
	{
		to: STORE_SUB_ROUTE_DETAILS.ORDERS.route,
		label: 'Orders',
		showWhenShowcase: true,
		showWhenNotShowcase: true
	},
	{
		to: STORE_SUB_ROUTE_DETAILS.ORDERS_FROM_GROUP.route,
		label: 'Group Orders',
		showWhenShowcase: false,
		showWhenNotShowcase: true
	}
];

const NavSections = ({ isTeamShowcaseStore, ...props }: Props): ReactElement | null => {
	const classes = useStyles();

	const location = useLocation();

	const pathDetails = location.pathname.lastIndexOf('/') + 1;
	const path = location.pathname.slice(pathDetails).length === 36 ? '' : location.pathname.slice(pathDetails);

	return (
		<div className={classes.sectionSelector}>
			{NavSpec.map((spec) => {
				if (isTeamShowcaseStore && !spec.showWhenShowcase) {
					return null;
				}
				if (!isTeamShowcaseStore && !spec.showWhenNotShowcase) {
					return null;
				}
				return (
					<ButtonBase
						key={spec.to}
						variant={path === `${spec.to}` ? 'contained' : 'outlined'}
						color={path === `${spec.to}` ? 'primary' : 'default'}
						to={`${spec.to}`}
						component={NavLink}
						className={classes.selectionOption}
					>
						{spec.label}
					</ButtonBase>
				);
			}).filter(Boolean)}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		sectionSelector: {
			paddingBottom: 20,
			'& > :not(:first-child)': {
				marginLeft: 16
			}
		},
		selectionOption: {
			width: 120
		},
		active: {
			backgroundColor: 'blue'
		}
	};
});

export default NavSections;
