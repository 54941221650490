/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ButtonBase, Collapse, Switch, Theme, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';

import PrimaryButton from '../../../../../../../../core/components/buttons/primaryButton';
import AddImageDialog from '../../../../../../../../core/components/connected/store/sections/products/components/addImageDialog';
import DropImage from '../../../../../../../../core/components/connected/store/sections/products/components/dropImage';
import InputField from '../../../../../../../../core/components/inputs/inputField';
import P6 from '../../../../../../../../core/components/typography/p6';
import { SYSTEM_COLORS } from '../../../../../../../../core/config/colors';
import { formatCurrencyValueFromServer } from '../../../../../../../../core/utilites/currenty';
import { StoreProductHasOptionValueFragment } from '../../../../../../../../generated/graphql';
import LabelOverride from './components/labelOverride';
import PriceOverride from './components/priceOverride';

interface Props extends StoreProductHasOptionValueFragment {
	dragElem: ReactElement;
	updateProductOption(vars: any): void;
}

const OptionValue = (props: Props): ReactElement => {
	const classes = useStyles();
	const [addImageKey, setAddImageKey] = useState<'image_url' | 'secondary_image_url' | undefined>(undefined);
	const [enabled, setEnabled] = useState<boolean>(props.enabled);

	const handleUpdate = (option: Partial<StoreProductHasOptionValueFragment>) => {
		props.updateProductOption({
			id: props.id,
			label_override: props.label_override,
			image_url: props.image_url,
			secondary_image_url: props.secondary_image_url,
			enabled: props.enabled,
			price_modifier_override: props.price_modifier_override,
			...option
		});
	};

	return (
		<>
			<div className={classes.optionConfigs}>
				<div className={classes.optionConfigItem}>
					{props.dragElem}
					<div className={classes.imageContainer}>
						{!props.image_url && (
							<DropImage
								id={props.id}
								saveUrlOnSuccess={async (url: string): Promise<void> => {
									handleUpdate({
										image_url: url
									});
								}}
							/>
						)}
						{props.image_url && (
							<div>
								<ButtonBase
									className={classes.iconButton}
									onClick={(): void => {
										setAddImageKey('image_url');
									}}
								>
									<img src={props.image_url} width={100} height={100} />
								</ButtonBase>
								<ButtonBase
									className={classes.clearImageButton}
									onClick={(): void => {
										handleUpdate({
											image_url: undefined
										});
									}}
								>
									<P6>Clear Image</P6>
								</ButtonBase>
							</div>
						)}
						{props.secondary_image_url && (
							<div>
								<ButtonBase
									className={classes.iconButton}
									onClick={(): void => {
										setAddImageKey('secondary_image_url');
									}}
								>
									<img src={props.secondary_image_url} width={100} height={100} />
								</ButtonBase>
								<ButtonBase
									className={classes.clearImageButton}
									onClick={(): void => {
										handleUpdate({
											secondary_image_url: undefined
										});
									}}
								>
									<P6>Clear Image</P6>
								</ButtonBase>
							</div>
						)}
						{!props.secondary_image_url && (
							<DropImage
								id={props.id}
								saveUrlOnSuccess={async (url: string): Promise<void> => {
									handleUpdate({
										secondary_image_url: url
									});
								}}
							/>
						)}
					</div>
					<LabelOverride
						updateProductOption={(updatedLabel: string) => {
							handleUpdate({
								label_override: updatedLabel
							});
						}}
						label={props.label_override ? props.label_override : props.available_option_value!.label}
					/>

					<div className={classes.actions}>
						<PriceOverride
							price={
								props.price_modifier_override !== null && typeof props.price_modifier_override === 'number'
									? props.price_modifier_override
									: props.available_option_value?.price_modifier || 0
							}
							updateProductOption={(price) => {
								handleUpdate({
									price_modifier_override: price
								});
							}}
						/>

						<div className={classes.activeContainer}>
							<Typography variant="h6">Is Active</Typography>
							<Switch
								color="primary"
								name="is_active"
								checked={enabled}
								onChange={(e): void => {
									setEnabled(e.target.checked);
									handleUpdate({
										enabled: e.target.checked
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<AddImageDialog
				open={addImageKey !== undefined}
				handleClose={(): void => setAddImageKey(undefined)}
				id={props.id}
				saveUrlOnSuccess={async (url: string): Promise<void> => {
					props.updateProductOption({
						id: props.id,
						image_url: props.image_url,
						label_override: props.label_override,
						secondary_image_url: props.secondary_image_url,
						enabled,
						[addImageKey as string]: url
					});
					setAddImageKey(undefined);
				}}
			/>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		optionConfigs: {
			padding: 16
		},
		optionConfigItem: {
			display: 'flex',
			alignItems: 'center',
			paddingBottom: 17,
			'&:last-of-type': {
				paddingBottom: 0
			}
		},
		iconButton: {},
		emptyImageButton: {
			width: 100,
			height: 100,
			border: `1px solid ${SYSTEM_COLORS.GRAY_LIGHT}`,
			borderRadius: 8,
			'& svg': {
				color: SYSTEM_COLORS.GRAY_LIGHT
			}
		},
		uploadContainer: {
			width: 30,
			height: 30
		},
		p5: {
			flex: 1,
			color: SYSTEM_COLORS.GRASCALE_GRAY_DARK
		},
		negativeAmount: {
			color: SYSTEM_COLORS.RED_DEFAULT
		},
		positiveAmount: {
			color: SYSTEM_COLORS.GREEN_DEFAULT
		},
		icon: {
			marginLeft: 5
		},
		actions: {
			height: 100,
			flexDirection: 'column',
			justifyContent: 'space-between',
			display: 'flex',
			alignItems: 'flex-end'
		},
		altButton: {
			marginTop: 15
		},
		imageContainer: {
			display: 'flex',
			alignContent: 'center',
			justifyContent: 'space-between',
			width: 210
		},
		label: {
			height: 100,
			marginLeft: 20,
			marginRight: 20,
			flex: 1
		},
		inputElement: {
			marginRight: 20
		},
		activeContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			textAlign: 'right'
		},
		clearImageButton: {
			display: 'block',
			textAlign: 'center',
			width: '100%'
		}
	};
});

export default OptionValue;
