/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ButtonBase, Collapse, InputAdornment, Theme, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';

import NumberFormatNegative from '../../../../../../../../../../core/components/blocks/numberFormatNegative';
import PrimaryButton from '../../../../../../../../../../core/components/buttons/primaryButton';
import InputField from '../../../../../../../../../../core/components/inputs/inputField';
import { SYSTEM_COLORS } from '../../../../../../../../../../core/config/colors';
import { formatCurrencyValueFromServer } from '../../../../../../../../../../core/utilites/currenty';

interface Props {
	price: number;
	updateProductOption(price: number): void;
}

const PriceOverride = (props: Props): ReactElement => {
	const classes = useStyles();
	const [priceOverrideValue, setEditLabel] = useState<string | undefined>();

	return (
		<div className={classes.label}>
			<Collapse in={priceOverrideValue === undefined}>
				<ButtonBase
					style={{ width: '100%', justifyContent: 'flex-start' }}
					onClick={(): void => {
						setEditLabel(`${props.price / 100}` || '');
					}}
				>
					<Typography
						variant="body1"
						className={clsx((props.price || 10) >= 0 ? classes.positiveAmount : classes.negativeAmount)}
					>
						{formatCurrencyValueFromServer(props.price)}
					</Typography>
				</ButtonBase>
			</Collapse>
			<Collapse in={priceOverrideValue !== undefined} unmountOnExit>
				<InputField
					onChange={(e) => {
						setEditLabel(e.target.value);
					}}
					variant="outlined"
					label="Override Price"
					onKeyDown={(e) => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							const value = `${Math.round(parseFloat(priceOverrideValue!) * 100)}`;

							props.updateProductOption(parseInt(value, 10));
							setEditLabel(undefined);
						}
					}}
					InputProps={{
						inputComponent: NumberFormatNegative,
						startAdornment: (
							<InputAdornment position="start">
								<strong>$</strong>
							</InputAdornment>
						)
					}}
					value={priceOverrideValue}
				/>
				<PrimaryButton
					onClick={(): void => {
						const value = `${Math.round(parseFloat(priceOverrideValue!) * 100)}`;
						props.updateProductOption(parseInt(value, 10));
						setEditLabel(undefined);
					}}
				>
					Save
				</PrimaryButton>
			</Collapse>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		label: {
			height: 100,
			marginLeft: 20,
			marginRight: 20,
			flex: 1
		},
		inputElement: {
			marginRight: 20
		},
		negativeAmount: {
			color: SYSTEM_COLORS.RED_DEFAULT
		},
		positiveAmount: {
			color: SYSTEM_COLORS.GREEN_DEFAULT
		}
	};
});

export default PriceOverride;
