import { useMutation, useQuery } from '@apollo/client';
import { DialogContent, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiMagnify } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useMemo, useState } from 'react';

import { AddTeamStoreProductDocument, GetProductsAvailableToStoresDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import { formatCurrencyValueFromServer } from '../../../../../../../utilites/currenty';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import PrimaryButton from '../../../../../../buttons/primaryButton';
import InputField from '../../../../../../inputs/inputField';
import P5 from '../../../../../../typography/p5';

interface Props {
	storeId: string;
	refetch(): void;
	open: boolean;
	handleClose(success?: boolean): void;
}

const AddProductDialog = (props: Props): ReactElement => {
	const classes = useStyles();

	const { data } = useQuery(GetProductsAvailableToStoresDocument);
	const [addStoreProduct, { loading }] = useMutation(AddTeamStoreProductDocument);
	const [searchTerm, setSearchTerm] = useState('');

	const products = useMemo(() => {
		if (!data?.product) {
			return [];
		}

		if (searchTerm === '') {
			return data.product;
		}
		return data.product.filter((order) => {
			return order.label.toLowerCase().includes(searchTerm.toLowerCase());
		});
	}, [data, searchTerm]);

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader
				title="Add Product"
				closeDialog={props.handleClose}
				subText="Add products to the store for individuals to purchase"
			/>
			<DialogContent>
				<div className={classes.searchContainer}>
					<InputField
						onChange={(e) => setSearchTerm(e.target.value)}
						fullWidth
						variant="outlined"
						InputProps={{
							startAdornment: (
								<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
									<path d={mdiMagnify} />
								</SvgIcon>
							)
						}}
						placeholder={`Search`}
						inputProps={{ 'aria-label': 'search' }}
					/>
				</div>
				{products.map((product) => {
					return (
						<div className={classes.row} key={product.id}>
							<div style={{ flex: 1 }}>
								<Typography variant="body2">{product.label}</Typography>
								<P5>{product.fabric}</P5>
							</div>
							<Typography variant="body2" className={classes.price}>
								{formatCurrencyValueFromServer(product.base_price || 0)}
							</Typography>
							<PrimaryButton
								disabled={loading}
								onClick={async (): Promise<void> => {
									await addStoreProduct({
										variables: {
											storeId: props.storeId,
											productId: product.id
										}
									});
									props.refetch();
								}}
							>
								Add
							</PrimaryButton>
						</div>
					);
				})}
			</DialogContent>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {},
		row: {
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingTop: 10,
			paddingBottom: 10,
			display: 'flex',
			borderBottom: '1px solid #DFE0EB',
			'&:last-of-type': {
				borderBottom: 'none'
			}
		},
		searchContainer: {
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.GRAYSCALE_GRAY_LIGHTEST,
			padding: '10px 15px'
		},
		price: {
			marginRight: 25
		},
		dialogIcons: {
			color: SYSTEM_COLORS.GRAY
		},
		magnify: {
			marginTop: 2
		}
	};
});

export default AddProductDialog;
