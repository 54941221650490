import { Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { formatCurrencyValueFromServer } from '../../../../core/utilites/currenty';

interface Props {
	c?: any;
}

export const mapLabelToReadable = (label: string): string => {
	switch (label) {
		case 'collegeOrders':
			return 'College';
		case 'highSchoolOrders':
			return 'High School';
		case 'middleSchoolOrders':
			return 'Middle School';
		case 'clubOrders':
			return 'Club';
		case 'noneOrders':
			return 'None';
		case 'ultimateOrders':
			return 'Ultimate';
		case 'maccabiOrders':
			return 'Maccabi';
		case 'basketballOrders':
			return 'Basketball';
		case 'volleyballOrders':
			return 'Volleyball';
		case 'quadballOrders':
			return 'Quad Ball';
		case 'otherOrders':
			return 'Other';
		default:
			return label;
	}
};

const CustomTooltip = ({
	active,
	payload,
	isCurrency,
	total
}: {
	active?: boolean;
	payload?: any[];
	isCurrency?: boolean;
	total: number;
}) => {
	if (active && payload && payload.length) {
		const name = payload[0].name;
		const value = payload[0].value;
		const percentage = ((value / total) * 100).toFixed(2);

		const formattedValue = isCurrency ? formatCurrencyValueFromServer(value) : value.toLocaleString();

		return (
			<div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc', borderRadius: 4 }}>
				<p>{`${mapLabelToReadable(name)}: ${formattedValue}`}</p>
				<p>{`Percentage: ${percentage}%`}</p>
			</div>
		);
	}

	return null;
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {}
	};
});

export default CustomTooltip;
