import { useEffect, useState } from 'react';

/**
 * const debouncedSearchTerm = useDebounce(searchTerm, 250);
	useEffect(() => {
		// Make sure we have a value (user has entered something in input)
		if (debouncedSearchTerm && debouncedSearchTerm !== '' && debouncedSearchTerm.length > 3) {
			getCharities({ variables: { name: debouncedSearchTerm } });
		}
	}, [debouncedSearchTerm, getCharities]);
	
 * @param value string value you're looking to debounce
 * @param delay delay you're up for denouncing
 */
function useDebounce<T>(value: T, delay: number): T {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		// Set debouncedValue to value (passed in) after the specified delay
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return (): void => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

export default useDebounce;
