import { SvgIcon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { mdiChevronLeft, mdiChevronRight, mdiSkipNextOutline, mdiSkipPreviousOutline } from '@mdi/js';
import React, { ReactElement } from 'react';

interface Props {
	count: number;
	onChangePage(event: any, page: number): void;
	page: number;
	rowsPerPage: number;
}

const TablePaginationActions = (props: Props): ReactElement => {
	const classes = useStyles();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event: any): void => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event: any): void => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event: any): void => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event: any): void => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === 'rtl' ? (
					<SvgIcon>
						<path d={mdiSkipNextOutline} />
					</SvgIcon>
				) : (
					<SvgIcon>
						<path d={mdiSkipPreviousOutline} />
					</SvgIcon>
				)}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? (
					<SvgIcon>
						<path d={mdiChevronRight} />
					</SvgIcon>
				) : (
					<SvgIcon>
						<path d={mdiChevronLeft} />
					</SvgIcon>
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? (
					<SvgIcon>
						<path d={mdiChevronLeft} />
					</SvgIcon>
				) : (
					<SvgIcon>
						<path d={mdiChevronRight} />
					</SvgIcon>
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? (
					<SvgIcon>
						<path d={mdiSkipPreviousOutline} />
					</SvgIcon>
				) : (
					<SvgIcon>
						<path d={mdiSkipNextOutline} />
					</SvgIcon>
				)}
			</IconButton>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5)
	}
}));

export default TablePaginationActions;
