import { useMutation } from '@apollo/client';
import { Collapse, IconButton, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiChevronUp, mdiTrashCan } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';

import P5 from '../../../../../../core/components/typography/p5';
import { SYSTEM_COLORS } from '../../../../../../core/config/colors';
import { ProductOptionBasicsFragment } from '../../../../../../generated/graphql';
import AvailableOptionValues from '../availableOptionValues';

interface Props {
	deleteOption(): void;
	dragHandle: ReactElement;
	option: ProductOptionBasicsFragment;
}

interface OptionConfig {
	name: string;
	price: string;
	optionalUrl?: string;
}

const ProductOption = (props: Props): ReactElement => {
	const { option } = props;
	const [open, setOpen] = useState(false);

	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.labelContainer}>
				{props.dragHandle}
				<div className={classes.context}>
					<Typography variant="body2" className={classes.label}>
						{option.available_option.name}
					</Typography>
					<P5 className={classes.description}>{option.available_option.description}</P5>
				</div>
				<div>
					<IconButton onClick={props.deleteOption}>
						<SvgIcon className={classes.deleteIcon}>
							<path d={mdiTrashCan} />
						</SvgIcon>
					</IconButton>
					<IconButton
						onClick={(): void => {
							setOpen(!open);
						}}
					>
						<SvgIcon className={clsx(classes.chevron, open ? classes.chevronFlip : null)}>
							<path d={mdiChevronUp} />
						</SvgIcon>
					</IconButton>
				</div>
			</div>
			<Collapse in={open} unmountOnExit>
				<AvailableOptionValues availableOptionId={props.option.available_option_id} />
			</Collapse>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			backgroundColor: '#FFF',
			borderRadius: 8,
			padding: '15px',
			margin: '14px 0'
		},
		labelContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		context: {
			flex: 1,
			paddingLeft: 10
		},
		label: {
			fontWeight: 600,
			color: SYSTEM_COLORS.GRASCALE_BLACK
		},
		description: {
			color: SYSTEM_COLORS.GRASCALE_GRAY_DARK
		},
		chevron: {
			color: SYSTEM_COLORS.GRAY,
			transition: 'all .3s'
		},
		chevronFlip: {
			'-webkit-transform': 'rotate(180deg)',
			'-moz-transform': 'rotate(180deg)',
			'-ms-transform': 'rotate(180deg)',
			'-o-transform': 'rotate(180deg)',
			transform: 'rotate(180deg)'
		},
		contentContainer: {
			marginTop: 15,
			backgroundColor: '#F7F8FF',
			borderRadius: 8
		},
		optionConfigs: {
			padding: 16
		},
		deleteIcon: {
			color: SYSTEM_COLORS.RED_DEFAULT
		}
	};
});

export default ProductOption;
