import { useMutation, useQuery } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import {
	Collapse,
	FormControl,
	InputLabel,
	Select,
	SvgIcon,
	TextField,
	Theme,
	Tooltip,
	Typography,
	makeStyles
} from '@material-ui/core';
import { DesktopDateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { mdiContentCopy, mdiSendCheck, mdiSendVariantClockOutline } from '@mdi/js';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';

import {
	CreateTeamStoreEmailDocument,
	GetTeamStoreLogByIdDocument,
	TeamStoreBasicsFragment,
	UpdateTeamStoreByIdDocument,
	UpdateTeamStoreLogDocument
} from '../../../../../../generated/graphql';
import ImageContainer from '../../../../../../page/group/components/imageContainer';
import { SYSTEM_COLORS } from '../../../../../config/colors';
import { useCopy } from '../../../../../hooks/useCopy';
import { findEnvAttr } from '../../../../../utilites/process';
import RowActionWithButton from '../../../../blocks/rowActionWithButton';
import RowToggleWithSwitch from '../../../../blocks/rowToggleWithSwitch';
import PrimaryButton from '../../../../buttons/primaryButton';
import { MarkdownEditor } from '../../../../editor/MarkdownEditor';
import AutoSave from '../../../../inputs/autosave';
import InputField from '../../../../inputs/inputField';
import ButtonToDialogCommit from '../../../buttonToDialogCommit';
import DialogAttributionLabel from './components/dialogAttributionLabel';
import DialogFriendlyKey from './components/dialogFriendlyKey';

interface Props {
	teamStoreInfo: TeamStoreBasicsFragment;

	refetch(): void;
}

const StoreInfo = (props: Props): ReactElement | null => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { data, loading, refetch } = useQuery(GetTeamStoreLogByIdDocument, { variables: { storeId: props.teamStoreInfo.id } });
	const [submit] = useMutation(UpdateTeamStoreByIdDocument);
	const [updateStoreLog] = useMutation(UpdateTeamStoreLogDocument);
	const [changeFriendlyUrlKey, setChangeFriendlyUrlKey] = useState(false);
	const copy = useCopy({
		message: 'Successfully copied the Store URL',
		stringToCopy: `${findEnvAttr('REACT_APP_PUBLIC_URL')}store/${
			props.teamStoreInfo.friendly_url_key ? props.teamStoreInfo.friendly_url_key : props.teamStoreInfo.id
		}`
	});

	const [showAttritbutionDialog, setAttributionDialog] = useState(false);

	const handleLogChange = async (id: string, date: Date | null) => {
		try {
			await updateStoreLog({ variables: { id: id, scheduled_at: date } });
			enqueueSnackbar('Scheduled Date Updated', {
				variant: 'success'
			});
			props.refetch();
		} catch (e) {
			// console.error(e);
			enqueueSnackbar('Scheduled Date Update Failed', {
				variant: 'error'
			});
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					name: props.teamStoreInfo.name || '', // Null items can throw; make things not null.
					notes: props.teamStoreInfo.notes || '',
					status: props.teamStoreInfo.status || '',
					store_url: props.teamStoreInfo.store_url || '',
					rep: props.teamStoreInfo.rep || '',
					welcome_message: props.teamStoreInfo.welcome_message,
					enable_credit_card_payment: props.teamStoreInfo.enable_credit_card_payment,
					enable_check_payment: props.teamStoreInfo.enable_check_payment,
					enable_free_shipping: props.teamStoreInfo.enable_free_shipping,
					enable_shipping_options: props.teamStoreInfo.enable_shipping_options,
					create_shipstation_label_on_order: props.teamStoreInfo.create_shipstation_label_on_order,
					enable_ship_to_store: props.teamStoreInfo.enable_ship_to_store,
					collect_addresses: props.teamStoreInfo.collect_addresses,
					banner_url: props.teamStoreInfo.banner_url || '',
					attribution_list: props.teamStoreInfo.attribution_list || [],
					enable_tax_collection: props.teamStoreInfo.enable_tax_collection,
					store_contact: props.teamStoreInfo.store_contact || '',
					store_contact_email: props.teamStoreInfo.store_contact_email || '',
					store_contact_address: props.teamStoreInfo.store_contact_address || '',
					store_contact_address2: props.teamStoreInfo.store_contact_address2 || '',
					store_contact_city: props.teamStoreInfo.store_contact_city || '',
					store_contact_state: props.teamStoreInfo.store_contact_state || '',
					store_contact_postal: props.teamStoreInfo.store_contact_postal || '',
					store_tracking_number: props.teamStoreInfo.store_tracking_number || ''
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					await submit({
						variables: {
							...values,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							id: props.teamStoreInfo!.id
						}
					});
					props.refetch();
					setSubmitting(false);
				}}
			>
				{({ isSubmitting, values, handleChange, setFieldValue, submitForm }) => {
					return (
						<Form className={classes.formContainer}>
							<AutoSave />
							<div className={classes.gridTwo}>
								<InputField
									name="name"
									value={values.name}
									variant="outlined"
									fullWidth
									label="Store Name"
									onChange={handleChange}
								/>
								<div style={{ display: 'flex' }}>
									<InputField
										disabled
										value={`${findEnvAttr('REACT_APP_PUBLIC_URL')}store/${
											props.teamStoreInfo.friendly_url_key
												? props.teamStoreInfo.friendly_url_key
												: props.teamStoreInfo.id
										}`}
										variant="outlined"
										fullWidth
										label="Store URL"
										InputProps={{
											endAdornment: (
												<SvgIcon className={classes.icon} onClick={copy}>
													<path d={mdiContentCopy} />
												</SvgIcon>
											)
										}}
									/>
									<PrimaryButton
										style={{ minWidth: 90, marginLeft: 15 }}
										onClick={(): void => {
											setChangeFriendlyUrlKey(true);
										}}
									>
										Add URL
									</PrimaryButton>
								</div>
							</div>
							<div className={classes.gridTwo}>
								<InputField
									name="rep"
									value={values.rep}
									variant="outlined"
									fullWidth
									label="Store Representative"
									onChange={handleChange}
								/>
								<FormControl>
									<InputLabel htmlFor="age-native-simple" className={classes.formControl}>
										Store Mode
									</InputLabel>
									<Select
										classes={{ root: classes.root }}
										className={classes.selectEmpty}
										variant="outlined"
										native
										value={values.status}
										onChange={handleChange}
										inputProps={{
											name: 'status',
											id: 'age-native-simple'
										}}
									>
										<option aria-label="None" value="" />
										<option value={'staging'}>Staging</option>
										<option value={'open'}>Open</option>
										<option value={'production'}>Production</option>
										<option value={'closed'}>Closed</option>
									</Select>
								</FormControl>
							</div>
							<div className={classes.section} style={{ marginBottom: 15 }}>
								<Typography className={classes.sectionLabel} variant="h4" style={{ marginBottom: 15 }}>
									Store Contact
								</Typography>
								<div className={classes.gridTwo}>
									<InputField
										name="store_contact"
										value={values.store_contact}
										variant="outlined"
										fullWidth
										label="Contact Name"
										onChange={handleChange}
									/>
									<InputField
										name="store_contact_email"
										value={values.store_contact_email}
										variant="outlined"
										fullWidth
										label="Contact Email"
										onChange={handleChange}
									/>
								</div>
								<div className={classes.gridTwo}>
									<InputField
										name="store_contact_address"
										value={values.store_contact_address}
										variant="outlined"
										fullWidth
										label="Address line 1"
										onChange={handleChange}
									/>
									<InputField
										name="store_contact_address2"
										value={values.store_contact_address2}
										variant="outlined"
										fullWidth
										label="Address line 2"
										onChange={handleChange}
									/>
								</div>
								<div className={classes.gridThree}>
									<InputField
										name="store_contact_city"
										value={values.store_contact_city}
										variant="outlined"
										fullWidth
										label="City"
										onChange={handleChange}
									/>
									<InputField
										name="store_contact_state"
										value={values.store_contact_state}
										variant="outlined"
										fullWidth
										label="State"
										onChange={handleChange}
									/>
									<InputField
										name="store_contact_postal"
										value={values.store_contact_postal}
										variant="outlined"
										fullWidth
										label="Zip"
										onChange={handleChange}
									/>
								</div>
							</div>
							<Collapse in={values.status !== 'closed'}>
								<div className={classes.sections}>
									<div className={classes.section}>
										<Typography className={classes.sectionLabel} variant="h4">
											Payment Types
										</Typography>

										<div className={clsx(classes.paymentOptions)}>
											<RowToggleWithSwitch
												className={classes.rowToggle}
												title={'Accept Credit Cards'}
												handleUpdate={(v) => {
													setFieldValue('enable_credit_card_payment', v);
												}}
												switchState={values.enable_credit_card_payment}
												description="Allow customers to pay with a credit card"
											/>
											<RowToggleWithSwitch
												className={classes.rowToggle}
												title={'Accept Checks'}
												handleUpdate={(v) => {
													setFieldValue('enable_check_payment', v);
												}}
												switchState={values.enable_check_payment}
												description="Allow customers to checkout without paying."
											/>
											<RowToggleWithSwitch
												className={classes.rowToggle}
												title={'Add Taxes'}
												handleUpdate={(v) => {
													setFieldValue('enable_tax_collection', v);
												}}
												switchState={values.enable_tax_collection}
												description="Have stripe collect taxes on this traction"
											/>
											<Collapse in={!!values.enable_tax_collection}>
												<div style={{ paddingLeft: 15 }}>
													<RowToggleWithSwitch
														className={classes.rowToggle}
														title={'Ship directly to Team Store'}
														handleUpdate={(v) => {
															setFieldValue('enable_ship_to_store', v);
														}}
														switchState={!!values.enable_ship_to_store}
														description="Tax will be determined based on the shipping address provided above. You will not be able to enable this IF no address is provided above."
														disabled={
															!values.store_contact_address ||
															!values.store_contact_city ||
															!values.store_contact_state ||
															!values.store_contact_postal ||
															values.store_contact_address === '' ||
															values.store_contact_city === '' ||
															values.store_contact_state === '' ||
															values.store_contact_postal === ''
														}
													/>
												</div>
											</Collapse>
										</div>
									</div>

									<div className={classes.section}>
										<Typography className={classes.sectionLabel} variant="h4">
											Shipping Options
										</Typography>
										<div className={classes.paymentOptions}>
											<RowToggleWithSwitch
												className={classes.rowToggle}
												title={'Create Shipstation Labels'}
												handleUpdate={(v) => {
													setFieldValue('create_shipstation_label_on_order', v);
												}}
												switchState={!!values.create_shipstation_label_on_order}
												description="After a successful checkout, automatically create a shipstation label"
											/>
											<RowToggleWithSwitch
												className={classes.rowToggle}
												title={'Enable Shipping Options'}
												handleUpdate={(v) => {
													setFieldValue('enable_shipping_options', v);
												}}
												switchState={!!values.enable_shipping_options}
												description="Show shipping options to user on checkout screen."
											/>
											<Collapse in={!!values.enable_shipping_options}>
												<RowToggleWithSwitch
													className={classes.rowToggle}
													title={'Enable Free Shipping as Option'}
													handleUpdate={(v) => {
														setFieldValue('enable_free_shipping', v);
													}}
													switchState={!!values.enable_free_shipping}
													description="Shipping option will be displayed as 'Free'."
												/>
											</Collapse>
											<RowActionWithButton
												className={classes.rowToggle}
												title={'Add Attribution Values'}
												description="If sales are going towards something, you can add values here for individuals to select during time of checkout."
												buttonLabel={
													values.attribution_list.length
														? `Update ${values.attribution_list.length} items`
														: 'Add Items'
												}
												onClick={(): void => {
													setAttributionDialog(true);
												}}
											/>
										</div>
									</div>
									<div className={classes.section}>
										<Typography className={classes.sectionLabel} variant="h4">
											Storefront Config
										</Typography>

										<div className={classes.gridOne}>
											<ImageContainer
												width={'99%'}
												height={'auto'}
												label={'Drop Banner Image Here'}
												image_url={values.banner_url}
												onImageUpdate={(url) => {
													setFieldValue('banner_url', url);
													handleChange(url);
												}}
											/>
										</div>
										<div className={classes.gridOne}>
											<MarkdownEditor
												content={props.teamStoreInfo.welcome_message}
												onChange={(md) => setFieldValue('welcome_message', md)}
												placeholder={'Welcome Message'}
											/>
										</div>
									</div>
									<div className={classes.section}>
										<Typography className={classes.sectionLabel} variant="h4">
											Internal Options
										</Typography>
										<div className={classes.gridOne}>
											<InputField
												fullWidth
												multiline
												rows={6}
												label="Notes"
												variant="outlined"
												value={values.notes}
												onChange={handleChange}
												name="notes"
											/>
										</div>
									</div>
								</div>
							</Collapse>
							<Collapse in={values.status === 'closed'}>
								<LocalizationProvider dateAdapter={DateFnsUtils}>
									{data && data.team_store_email_log && data.team_store_email_log.length > 0 ? (
										<div className={classes.gridTwo}>
											<div>
												<h5>Scheduled Emails</h5>
												{data.team_store_email_log.map((log) => (
													<div key={log.id}>
														{log.status === 'scheduled' ? (
															<div className={classes.row}>
																<div className={classes.leadingIcon}>
																	<Tooltip title={'Scheduled Emails'}>
																		<SvgIcon>
																			<path
																				d={mdiSendVariantClockOutline}
																				color={'silver'}
																			/>
																		</SvgIcon>
																	</Tooltip>
																</div>
																<DesktopDateTimePicker
																	inputFormat="MM/dd/yyyy hh:mm a"
																	label={log.template_name}
																	value={log.scheduled_at}
																	onChange={(d) => handleLogChange(log.id, d)}
																	allowSameDateSelection={false}
																	disablePast={true}
																	renderInput={(props) => (
																		<TextField {...props} helperText={''} />
																	)}
																/>
															</div>
														) : null}
														{log.status === 'success' ? (
															<div className={classes.row}>
																<div className={classes.leadingIcon}>
																	<Tooltip title={'Emails Sent Successfully'}>
																		<SvgIcon>
																			<path d={mdiSendCheck} color={'darkGreen'} />
																		</SvgIcon>
																	</Tooltip>
																</div>
																<DesktopDateTimePicker
																	inputFormat="MM/dd/yyyy"
																	disabled={true}
																	label={log.template_name}
																	value={log.sent_at || log.updated_at}
																	onChange={(e) => {
																		return e;
																	}}
																	renderInput={(props) => (
																		<TextField {...props} helperText={''} />
																	)}
																/>
															</div>
														) : null}
													</div>
												))}
											</div>
										</div>
									) : (
										<div className={classes.gridTwo}>
											<div></div>
											<ButtonToDialogCommit
												keyName="createTeamStoreEmail"
												title="Initiate Scheduling of Email Workflow for All Store Participants"
												buttonLabel="Schedule Store Closing Emails"
												description="This will create a schedule of emails to be sent to all the individuals that ordered through the store letting them know the store is complete and orders are now going into production."
												dialogMutationOnConfirmation={CreateTeamStoreEmailDocument}
												dialogMutationVariables={{
													storeId: props.teamStoreInfo.id,
													workflowId: 'productionOrder'
												}}
												onChange={refetch}
											/>
											{/*<ButtonToDialogCommit*/}
											{/*	keyName="createTeamStoreEmail"*/}
											{/*	title="WUL 3rd EMAIL ONLY"*/}
											{/*	buttonLabel="3rd WUL Email about store closing *FOR WUL ONLY*"*/}
											{/*	description="This email is the 3rd email for WUL only"*/}
											{/*	dialogMutationOnConfirmation={CreateTeamStoreEmailDocument}*/}
											{/*	dialogMutationVariables={{*/}
											{/*		storeId: props.teamStoreInfo.id,*/}
											{/*		templateName: 'wul-prod-order-2'*/}
											{/*	}}*/}
											{/*/>*/}
										</div>
									)}
								</LocalizationProvider>
							</Collapse>
							<DialogAttributionLabel
								incomingTerms={values.attribution_list}
								open={showAttritbutionDialog}
								handleClose={(updatedAttributionItems) => {
									setFieldValue('attribution_list', updatedAttributionItems ? updatedAttributionItems : []);
									setAttributionDialog(false);
								}}
							/>
						</Form>
					);
				}}
			</Formik>
			<DialogFriendlyKey
				handleClose={(): void => {
					setChangeFriendlyUrlKey(false);
				}}
				open={changeFriendlyUrlKey}
				refetch={props.refetch}
				teamStoreId={props.teamStoreInfo.id}
				hasKey={Boolean(props.teamStoreInfo.friendly_url_key)}
			/>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		activeContainer: {
			marginRight: 30,
			textAlign: 'center'
		},
		label: {
			color: SYSTEM_COLORS.GRAY_LIGHT,
			marginBottom: 5
		},
		formContainer: {
			paddingTop: 15,
			backgroundColor: '#FFF'
		},
		paymentOptions: {
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			borderRadius: 8
		},
		withFlex: {
			display: 'flex'
		},
		gridOne: {
			// marginBottom: 35
		},
		gridTwo: {
			marginBottom: 35,
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
			gap: '50px'
		},
		gridThree: {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 1fr)',
			gap: '50px'
		},
		formControl: {
			borderRadius: 8,
			marginTop: -9,
			zIndex: 10,
			marginLeft: 8,
			backgroundColor: '#FFF',
			padding: '0 5px'
		},
		selectEmpty: {},
		root: {
			padding: 10,
			borderRadius: 8,
			backgroundColor: '#fff',
			'&:hover': {
				backgroundColor: '#fff'
			},
			'& .MuiInputBase-input': {
				borderRadius: 8
			},
			'& .MuiOutlinedInput-root': {
				borderRadius: 8
			},
			'& .MuiOutlinedInput-multiline': {
				padding: 0
			}
		},
		rowToggle: {
			border: 'none',
			padding: '13px 15px !important',
			'&:hover': {
				borderRadius: 8
			}
		},
		actionStrip: {
			flex: 1,
			display: 'flex',
			justifyContent: 'flex-end'
		},
		buttonViewSource: {
			padding: 5
		},
		sections: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '15px'
		},
		sectionLabel: {
			paddingBottom: 10
		},
		section: {
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: 15,
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.BACKGROUND
		},
		row: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			marginBottom: 15
		},
		leadingIcon: {
			marginRight: 8
		},
		icon: {
			cursor: 'pointer',
			marginLeft: 15
		}
	};
});

export default StoreInfo;
