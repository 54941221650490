import { useQuery } from '@apollo/client';
import { Paper, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiMagnify, mdiPlusCircle } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';

import PaperHeaderContainer from '../../core/components/blocks/paperHeaderContainer';
import PrimaryButton from '../../core/components/buttons/primaryButton';
// import SegmentedButtonControl from '../../core/components/buttons/segmentedButtonControl';
import InputField from '../../core/components/inputs/inputField';
import { SYSTEM_COLORS } from '../../core/config/colors';
import useDebounce from '../../core/hooks/useDebounce';
import { GetTeamsDocument } from '../../generated/graphql';
import DialogAddTeam from './components/dialogAddTeam';
import TeamTable from './components/teamTable';

interface Props {
	c?: any;
}

const Teams = (props: Props): ReactElement => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState('');

	const debouncedValue = useDebounce<string>(searchTerm, 250);
	const [dialogAdd, setDialogAdd] = useState(false);
	const { data, refetch } = useQuery(GetTeamsDocument, {
		variables: {
			_eq: false,
			searchTerm: `%${debouncedValue}%`
		}
	});

	const handleClose = (success?: boolean): void => {
		setDialogAdd(false);
		if (success) {
			refetch();
		}
	};
	const handleOpen = (): void => {
		setDialogAdd(true);
	};

	const teams = data?.team;

	return (
		<>
			<Paper>
				<PaperHeaderContainer>
					<Typography variant="h3">All teams</Typography>
				</PaperHeaderContainer>
				<div className={classes.content}>
					<div className={classes.headerContent}>
						<div className={classes.withShadow}>
							{/* <SegmentedButtonControl
								buttonContainerClass={classes.segmentedButtonControlContainer}
								buttonsConfig={options}
								customButtonClass={classes.customButton}
								selectedIndex={selectedOption}
								setSelectedIndex={setSelectedOption}
							/> */}
						</div>
						<div>
							<InputField
								value={searchTerm}
								variant="outlined"
								InputProps={{
									startAdornment: (
										<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
											<path d={mdiMagnify} />
										</SvgIcon>
									)
								}}
								onChange={(e): void => {
									setSearchTerm(e.target.value || '');
								}}
								placeholder={`Search`}
								inputProps={{ 'aria-label': 'search' }}
							/>
							<PrimaryButton
								onClick={handleOpen}
								startIcon={
									<SvgIcon>
										<path d={mdiPlusCircle} />
									</SvgIcon>
								}
								className={classes.newButton}
							>
								New Team
							</PrimaryButton>
						</div>
					</div>

					{teams && (
						<div className={classes.tableContainer}>
							<TeamTable refetchTeams={refetch} teamList={teams} />
						</div>
					)}
				</div>
			</Paper>
			<DialogAddTeam handleClose={handleClose} open={dialogAdd} />
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		dialogIcons: {
			color: SYSTEM_COLORS.GRAY
		},
		magnify: {
			marginTop: 2
		},
		newButton: {
			marginLeft: 20
		},
		tableContainer: {
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.WHITE
		},
		primaryIcon: {
			color: SYSTEM_COLORS.PRIMARY
		},
		warningIcon: {
			color: SYSTEM_COLORS.WARNING
		},
		withShadow: {
			boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.03)',
			borderRadius: 16,
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},

		segmentedButtonControlContainer: {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				display: 'flex'
			}
		},
		customButton: {
			margin: 5,
			padding: '5px 12px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				padding: '10px 12px'
			}
		},
		headerContent: {
			paddingRight: 0,
			paddingBottom: 15,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		content: {
			borderRadius: 16,
			padding: 15,
			backgroundColor: SYSTEM_COLORS.BACKGROUND
		}
	};
});

export default Teams;
