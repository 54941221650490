/* eslint-disable react/prop-types */
import { makeStyles } from '@material-ui/core';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useBlockLayout, useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { SYSTEM_COLORS } from '../../../config/colors';
import P3 from '../../typography/p3';
import TablePaginationActions from './tablePacingActions';

const labelDisplayedRows = ({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`;
const ROWS_PER_PAGE = 50;

const TableComponent = (props) => {
	const {
		getTableProps,
		headerGroups,
		rows,
		prepareRow,
		setGlobalFilter,
		page,
		gotoPage,
		visibleColumns,
		state: { globalFilter, pageIndex }
	} = useTable(
		{
			initialState: {
				pageSize: ROWS_PER_PAGE
			},
			columns: props.columns,
			data: props.data || []
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination
	);

	const classes = useStyles();

	useEffect(() => {
		if (props.globalFilter !== globalFilter) {
			setGlobalFilter(props.globalFilter);
		}
	}, [props.globalFilter, setGlobalFilter, globalFilter]);

	if (!props.data) {
		return null;
	}
	const handleChangePage = (event, newPage) => {
		gotoPage(newPage);
	};

	return (
		<>
			<MaUTable {...getTableProps()}>
				<TableHead>
					{headerGroups.map((headerGroup, i) => {
						return (
							<TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
								{headerGroup.headers.map((column, t) => (
									<TableCell
										className={clsx(classes.headerColumn, column.className)}
										key={t}
										{...column.getHeaderProps([
											{
												style: column.style
											},
											column.getSortByToggleProps()
										])}
									>
										{column.render('Header')}
										<span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
									</TableCell>
								))}
							</TableRow>
						);
					})}
				</TableHead>
				<TableBody>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<React.Fragment key={i}>
								<TableRow {...row.getRowProps()}>
									{row.cells.map((cell, x) => {
										return (
											<TableCell key={x} {...cell.getCellProps()} className={cell.column.className}>
												{cell.render('Cell')}
											</TableCell>
										);
									})}
								</TableRow>
								{row.isExpanded ? (
									<TableRow>
										<TableCell colSpan={visibleColumns.length}>
											{/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
											{props.renderRowSubComponent({ row })}
										</TableCell>
									</TableRow>
								) : null}
							</React.Fragment>
						);
					})}
				</TableBody>
			</MaUTable>
			<div className={classes.paging}>
				<P3>
					{labelDisplayedRows({
						from: rows.length === 0 ? 0 : pageIndex * ROWS_PER_PAGE + 1,
						to:
							rows.length !== -1
								? Math.min(rows.length, (pageIndex + 1) * ROWS_PER_PAGE)
								: (pageIndex + 1) * ROWS_PER_PAGE,
						count: rows.length === -1 ? -1 : rows.length
					})}
				</P3>
				<TablePaginationActions
					count={rows.length}
					onChangePage={handleChangePage}
					page={pageIndex}
					rowsPerPage={ROWS_PER_PAGE}
				/>
			</div>
		</>
	);
};

const useStyles = makeStyles((theme) => {
	return {
		headerColumn: {
			fontSize: 14,
			fontWeight: 'bold',
			color: SYSTEM_COLORS.GRAY
		},
		paging: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: 10
		}
	};
});

export default TableComponent;
