import { useQuery } from '@apollo/client';
import { Paper, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumb from '../../../../core/components/blocks/breadcrumb';
import PaperHeaderContainer from '../../../../core/components/blocks/paperHeaderContainer';
import { GetTeamStoreProductByIdDocument } from '../../../../generated/graphql';
import Manage from './components/manage';
import StoreProductOptions from './components/storeProductOptions';
import StoreProductImages from "./components/storeProductImages";

const Product = (): ReactElement => {
	const { productId } = useParams<'productId'>();

	const classes = useStyles();
	const { data, refetch } = useQuery(GetTeamStoreProductByIdDocument, {
		variables: {
			id: productId
		}
	});

	const wrappedRefetch = (): void => {
		refetch({
			id: productId
		});
	};

	const dataItem = data?.store_product_by_pk;

	if (!dataItem || !productId) {
		return <div>loading</div>;
	}

	return (
		<>
			<Breadcrumb label="Back to Store" route={'../products'} />
			<div className={classes.container}>
				<div className={classes.product}>
					<Paper className={classes.paper}>
						<PaperHeaderContainer>
							<Typography variant="h3">Editing Product</Typography>
						</PaperHeaderContainer>
						<StoreProductImages dataItem={dataItem} productId={productId}  />
						<Manage dataItem={dataItem} productId={productId} />
						{dataItem.store_product_has_options && dataItem.store_product_has_options.length > 0 && (
							<Typography variant="h3" className={classes.options}>
								Options
							</Typography>
						)}
						<StoreProductOptions
							storeProductId={dataItem.id}
							refetch={refetch}
							productAvailableOptions={dataItem.store_product_has_options}
						/>
					</Paper>
				</div>
			</div>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		wrapper: {
			width: '100%',
			margin: '0 auto',
			padding: '10px 30px',
			maxWidth: '1100px'
		},
		dialogContainer: {
			width: '100%'
		},
		paper: {
			backgroundColor: '#fff'
		},
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'baseline'
		},
		product: {
			width: '100%'
		},
		options: {
			padding: 12,
			backgroundColor: '#FFF'
		}
	};
});

export default Product;
