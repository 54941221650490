import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import PrimaryButton from '../../buttons/primaryButton';
import P3 from '../../typography/p3';

interface Props {
	disableHover?: boolean;
	className?: string;
	blockElements?: boolean;
	children?: ReactElement;
	description?: string;
	title: string;
	onClick?(): void;
	buttonLabel: string;
}

const RowActionWithButton = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<div
			className={clsx(
				classes.container,
				props.className,
				props.blockElements ? classes.blockContainer : classes.flexContainer
			)}
		>
			<div className={classes.content}>
				<div className={classes.navTitle}>{props.title}</div>
				{props.description && <P3 className={classes.p3}>{props.description}</P3>}
			</div>
			<div className={clsx(props.blockElements ? classes.blockChildPadding : null)}>
				<PrimaryButton onClick={props.onClick}>{props.buttonLabel}</PrimaryButton>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		content: {
			[theme.breakpoints.down('sm')]: {
				marginRight: 30
			}
		},
		container: {
			borderBottom: '1px solid rgba(48, 46, 40, 0.1);',
			'&:last-of-type': {
				borderBottom: 'none'
			}
		},
		blockContainer: {
			display: 'block'
		},
		blockChildPadding: {
			paddingTop: 15
		},
		flexContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		navTitle: {
			color: SYSTEM_COLORS.GRAY_SHADE,
			fontSize: 12,
			lineHeight: '18px'
		},
		p3: {
			color: SYSTEM_COLORS.GRAY
		}
	};
});

export default RowActionWithButton;
