import { Theme, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

import { getRemFromPx } from '../../../config/theme';

interface Props {
	className?: string;
	children: ReactNode;
}

const P6 = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<Typography component="p" className={clsx(classes.P6, props.className ? props.className : null)}>
			{props.children}
		</Typography>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		P6: {
			textShadow: 'none',
			fontSize: getRemFromPx(12),
			lineHeight: getRemFromPx(16),
			[theme.breakpoints.up('md')]: {
				fontSize: getRemFromPx(12),
				lineHeight: getRemFromPx(16)
			}
		}
	};
});

export default P6;
