import { useMutation, useQuery } from '@apollo/client';
import { DialogActions, DialogContent, InputAdornment, Theme, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React, { ReactElement } from 'react';

import { GetTeamStoreProductsDocument, InsertStorePackageDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import { cleanNumberFormat } from '../../../../../../../utilites/currenty';
import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import NumberFormatCustom from '../../../../../../blocks/numberFormatCustom';
import InputField from '../../../../../../inputs/inputField';
import ProductWrapper from '../productWrapper';

interface Props {
	storeId: string;
	open: boolean;
	handleClose(): void;
	handleSuccessfulClose(): void;
}

const productIds: string[] = [];

const AddPackage = (props: Props): ReactElement | null => {
	const classes = useStyles();
	const { loading, error, data } = useQuery(GetTeamStoreProductsDocument, {
		variables: {
			team_store_id: props.storeId
		}
	});
	const [savePackage] = useMutation(InsertStorePackageDocument);

	const products = data?.store_product;

	if (props.open === false) {
		return null;
	}

	if (products && products.length > 0) {
		return (
			<Formik
				initialValues={{
					label: '',
					description: '',
					price: undefined,
					productIds: productIds
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					const items = {
						label: values.label,
						description: values.description,
						store_id: props.storeId,
						product_ids: values.productIds,
						custom_price: Math.round(parseFloat(cleanNumberFormat(values.price || '0')) * 100)
					};
					await savePackage({
						variables: items
					});
					setSubmitting(false);
					props.handleSuccessfulClose();
				}}
			>
				{({ isSubmitting, values, handleChange, submitForm, setFieldValue }) => {
					return (
						<DialogBase containerClass={classes.dialogEdit} open={props.open}>
							<DialogTitleHeader title="Create Bundle" closeDialog={props.handleClose} />
							<DialogContent>
								<InputField
									className={classes.input}
									variant="outlined"
									label="Name"
									fullWidth
									name="label"
									onChange={handleChange}
									value={values.label}
								/>
								<InputField
									multiline
									className={classes.input}
									rows={4}
									variant="outlined"
									label="Description"
									fullWidth
									name="description"
									onChange={handleChange}
									value={values.description}
								/>
								<InputField
									variant="outlined"
									fullWidth
									label="Price"
									onChange={(e): void => {
										setFieldValue('price', e.target.value);
									}}
									name="price"
									value={values.price}
									InputProps={{
										inputComponent: NumberFormatCustom,
										startAdornment: (
											<InputAdornment position="start" className={classes.adornment}>
												<strong>$</strong>
											</InputAdornment>
										)
									}}
								/>
								<div className={classes.productContainer}>
									{products.map((product, index) => (
										<ProductWrapper
											index={values.productIds.indexOf(product.id)}
											key={product.id}
											product={product}
											isAdded={values.productIds.includes(product.id)}
											handleAdd={(): void => {
												setFieldValue('productIds', [...values.productIds, product.id]);
											}}
											handleRemove={(): void => {
												setFieldValue(
													'productIds',
													values.productIds.filter((id) => id !== product.id)
												);
											}}
										/>
									))}
								</div>
							</DialogContent>
							<DialogActionBar
								cancelAction={props.handleClose}
								saveAction={submitForm}
								disableSave={isSubmitting || loading}
							/>
						</DialogBase>
					);
				}}
			</Formik>
		);
	}

	return (
		<DialogBase open={props.open}>
			<DialogTitleHeader title="Create Bundle" closeDialog={props.handleClose} />
			<div>No products created! Please go create products first.</div>
			<DialogActionBar cancelAction={props.handleClose} />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		form: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1
		},
		input: {
			marginBottom: 15
		},
		adornment: {
			color: SYSTEM_COLORS.PRIMARY
		},
		productContainer: {
			margin: 15,
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 1fr)',
			gap: '15px'
		},
		dialogEdit: {
			minHeight: 700,
			maxHeight: 700
		}
	};
});

export default AddPackage;
