import { useMutation } from '@apollo/client';
import { Avatar, DialogContent, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiDelete } from '@mdi/js';
import React, { ReactElement } from 'react';

import { CancelOrderItemDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../../../../../../config/colors';
import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import P3 from '../../../../../../typography/p3';

interface Props {
	orderItemType: 'product' | 'package';
	orderId: string;
	orderItemLabel: string;
	orderItemId: string;
	handleClose(): void;
	open: boolean;
}

const DialogCancelOrderItem = (props: Props): ReactElement => {
	const classes = useStyles();
	const [cancelOrder, { loading }] = useMutation(CancelOrderItemDocument);

	const handleSave = async () => {
		if (loading) {
			return;
		}
		await cancelOrder({
			variables: {
				orderId: props.orderId,
				orderItemId: props.orderItemId,
				orderItemType: props.orderItemType
			}
		});
		props.handleClose();
	};

	return (
		<DialogBase open={props.open}>
			<DialogTitleHeader closeDialog={props.handleClose} title="Are you sure?" />
			<DialogContent className={classes.dialogContent}>
				<Avatar className={classes.avatar}>
					<SvgIcon>
						<path d={mdiDelete} />
					</SvgIcon>
				</Avatar>
				<Typography variant="h5" style={{ fontWeight: 'normal' }}>
					Remove <strong>{props.orderItemLabel}</strong> from the order?
				</Typography>
				<P3 className={classes.p3}>
					By removing it, it will not appear on the exported spreadsheets or in store totals. NOTE: This will NOT
					auto-initiate any kind of refunds through stripe. You will need to do that yourself.
				</P3>
			</DialogContent>
			<DialogActionBar
				cancelLabel="No, Don't Remove it"
				cancelAction={props.handleClose}
				disableSave={loading}
				saveAction={handleSave}
				saveLabel="Yes, Remove It"
			/>
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		avatar: {
			width: 128,
			height: 128,
			backgroundColor: getRGBAFromHex(SYSTEM_COLORS.RED_DEFAULT, 0.15),
			'& svg': {
				width: 78,
				height: 78,
				color: SYSTEM_COLORS.RED_DEFAULT
			},
			marginBottom: 50
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			maxWidth: '500px',
			marginLeft: 'auto',
			marginRight: 'auto',
			justifyContent: 'center',
			textAlign: 'center'
		},
		p3: {
			paddingTop: 15
		}
	};
});

export default DialogCancelOrderItem;
