import { DialogActions, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';

import { SYSTEM_COLORS } from '../../../config/colors';
import PrimaryButton from '../../buttons/primaryButton';

interface Props {
	cancelLabel?: string;
	cancelAction?(): void;
	saveAction?(): void;
	disableSave?: boolean;
	saveLabel?: string;
	children?: ReactNode;
}

const DialogActionBar = (props: Props): ReactElement => {
	const classes = useStyles();

	return (
		<DialogActions
			classes={{
				root: classes.containerRoot
			}}
		>
			{props.children}
			{props.cancelAction && (
				<PrimaryButton variant="outlined" onClick={props.cancelAction} className={classes.cancel}>
					{props.cancelLabel ? props.cancelLabel : 'Cancel'}
				</PrimaryButton>
			)}
			{props.saveAction && (
				<PrimaryButton
					variant="contained"
					onClick={props.saveAction}
					className={classes.button}
					disabled={props.disableSave}
				>
					{props.saveLabel ? props.saveLabel : 'Save'}
				</PrimaryButton>
			)}
		</DialogActions>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		containerRoot: {
			borderTop: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: '21px 24px'
		},
		button: {
			[theme.breakpoints.down('md')]: {
				width: '100%'
			}
		},
		cancel: {
			[theme.breakpoints.down('md')]: {
				display: 'none'
			}
		}
	};
});

export default DialogActionBar;
