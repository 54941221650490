import { useMutation } from '@apollo/client';
import { Avatar, DialogContent, InputAdornment, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiDelete } from '@mdi/js';
import React, { ReactElement, useState } from 'react';
import NumberFormat from 'react-number-format';

import { UpdateOrderTotalByOrderPkDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../../../../../../config/colors';
import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import NumberFormatCustom from '../../../../../../blocks/numberFormatCustom';
import InputField from '../../../../../../inputs/inputField';
import P3 from '../../../../../../typography/p3';

interface Props {
	orderId: string;
	handleClose(): void;
	open: boolean;
	price: string;
}

const DialogAdjustOrderTotal = (props: Props): ReactElement => {
	const classes = useStyles();
	const [price, setPrice] = useState(props.price);
	const [saveUpdate, { loading }] = useMutation(UpdateOrderTotalByOrderPkDocument);

	const handleSave = async () => {
		if (loading) {
			return;
		}
		await saveUpdate({
			variables: {
				id: props.orderId,
				total: Math.round(parseFloat(price) * 100)
			}
		});
		props.handleClose();
	};

	const handleInputNumber = (e: any): void => {
		setPrice(e.target.value);
	};

	return (
		<DialogBase open={props.open} containerClass={classes.paperContainer}>
			<DialogTitleHeader closeDialog={props.handleClose} title="Adjust Order total" />
			<DialogContent className={classes.dialogContent}>
				<P3 className={classes.p3}>Manually adjust the order total for metrics.</P3>
				<InputField
					fullWidth
					onChange={handleInputNumber}
					variant="outlined"
					label="Adjust Price"
					onKeyDown={(e) => {
						if (e.keyCode === 13 || e.key === 'Enter') {
							handleSave();
							e.preventDefault();
						}
					}}
					InputProps={{
						inputComponent: NumberFormatCustom,
						startAdornment: (
							<InputAdornment position="start">
								<strong>$</strong>
							</InputAdornment>
						)
					}}
					value={price}
				/>
			</DialogContent>
			<DialogActionBar cancelAction={props.handleClose} disableSave={loading} saveAction={handleSave} saveLabel="Save" />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
			paddingLeft: 25,
			paddingRight: 25,
			marginLeft: 'auto',
			marginRight: 'auto',
			justifyContent: 'center',
			textAlign: 'center'
		},
		p3: {
			paddingBottom: 15
		},
		paperContainer: {
			minHeight: 'auto'
		}
	};
});

export default DialogAdjustOrderTotal;
