import { useApolloClient, useMutation } from '@apollo/client';
import { SvgIcon, Theme, makeStyles } from '@material-ui/core';
import { mdiDotsVertical } from '@mdi/js';
import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { useDragReorderEnd } from '../../../../core/utilites/dragReorder';
import { ProductOptionBasicsFragment, RemoveOptionFromProductDocument } from '../../../../generated/graphql';
import ProductOption from './components/productOption';

interface Props {
	refetch(): void;
	productAvailableOptions: ProductOptionBasicsFragment[];
}

const AvailableOptions = (props: Props): ReactElement => {
	const classes = useStyles();
	const { productAvailableOptions } = props;
	const [options, setProductOptions] = useState(productAvailableOptions);

	const [deleteOption] = useMutation(RemoveOptionFromProductDocument);
	const client = useApolloClient();

	useEffect(() => {
		setProductOptions(productAvailableOptions);
	}, [productAvailableOptions]);

	const handleDeleteOption = async (optionId: string) => {
		await deleteOption({
			variables: {
				_eq: optionId
			}
		});
		props.refetch();
	};

	const dragOnEnd = useDragReorderEnd<ProductOptionBasicsFragment>('update_product_has_available_option_by_pk');

	const handleDragEnd = (result: any) => {
		const updatedValues = dragOnEnd(result, options);
		setProductOptions(updatedValues);
	};

	return (
		<div className={classes.optionContainer}>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="product-list">
					{(provided): ReactElement => {
						return (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{options.map((d, i): ReactElement => {
									return (
										<Draggable key={d.id} index={i} draggableId={d.id}>
											{(p) => {
												const elem = (
													<div {...p.dragHandleProps}>
														<SvgIcon className={classes.dragIcon}>
															<path d={mdiDotsVertical} />
														</SvgIcon>
													</div>
												);
												return (
													<div {...p.draggableProps} ref={p.innerRef}>
														<ProductOption
															dragHandle={elem}
															option={d}
															deleteOption={(): void => {
																handleDeleteOption(d.id);
															}}
														/>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		dragIcon: {
			color: SYSTEM_COLORS.GRAY_LIGHT,
			width: 24,
			height: 24
		},
		optionContainer: {
			padding: 15
		}
	};
});

export default AvailableOptions;
