import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import useDebounce from '../../../hooks/useDebounce';

const AutoSave = ({ debounceMs = 400 }) => {
	const formik = useFormikContext();
	const debouncedObj = useDebounce(formik.values, debounceMs);

	useEffect(() => {
		formik.submitForm();
	}, [debouncedObj]);

	return null;
};

export default AutoSave;
