import { useAuth0 } from '@auth0/auth0-react';
import {
	AppBar,
	Divider,
	Drawer,
	Hidden,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	SvgIcon,
	Theme,
	Toolbar,
	Typography,
	makeStyles,
	useTheme
} from '@material-ui/core';
import { mdiLogout, mdiMenu } from '@mdi/js';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { ReactElement } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useAuth } from '../../../providers/authProvider/authProvider';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../config/colors';
import { IterableLinkableEls } from '../../routes/dashboard';
import { ReactComponent as BreakmarkLogo } from './logo.svg';

interface Props {
	c?: any;
}

const drawerWidth = 255;

const Menu = (props: Props): ReactElement => {
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const { user, setToken, isAdmin } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const handleLogout = () => {
		setToken(null);
		enqueueSnackbar('You have been signed out.', { variant: 'info' });
		navigate('/');
	};

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<div>
			<div className={classes.toolbar}>
				<BreakmarkLogo style={{ width: '80%', marginLeft: 18, marginTop: 10 }} />
			</div>
			<Divider />
			{user && isAdmin && (
				<>
					<List>
						{IterableLinkableEls.map((route, index) => (
							<NavLink
								key={route.to}
								to={route.to}
								className={({ isActive }) =>
									isActive ? clsx(classes.active, classes.activeCustomSvg, classes.linkItem) : classes.linkItem
								}
							>
								<ListItem className={clsx(classes.listItem, classes.listItemSvg)}>
									<ListItemIcon>
										<SvgIcon>
											<path d={route.routeIconPath} />
										</SvgIcon>
									</ListItemIcon>
									<ListItemText primary={route.label} />
								</ListItem>
							</NavLink>
						))}
					</List>
					<Divider />
				</>
			)}

			<List>
				<ListItem button className={clsx(classes.listItem, classes.listItemSvg)} onClick={handleLogout}>
					<ListItemIcon>
						<SvgIcon>
							<path d={mdiLogout} />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText primary="Log Out" />
				</ListItem>
			</List>
		</div>
	);

	return (
		<>
			<Hidden smUp implementation="css">
				<AppBar position="fixed" classes={{ root: classes.appbarRoot }} className={classes.appBar}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							className={classes.menuButton}
						>
							<SvgIcon>
								<path d={mdiMenu} />
							</SvgIcon>
						</IconButton>
					</Toolbar>
				</AppBar>
			</Hidden>
			<nav className={classes.drawer} aria-label="Navigation">
				<Hidden smUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0
			}
		},
		appBar: {
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: drawerWidth
			}
		},
		appbarRoot: {
			backgroundColor: 'none'
		},
		menuButton: {
			color: SYSTEM_COLORS.BLACK_SHADE,
			marginRight: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				display: 'none'
			}
		},
		// necessary for content to be below app bar
		toolbar: {
			...theme.mixins.toolbar
		},
		drawerPaper: {
			backgroundColor: SYSTEM_COLORS.GRAY_SHADE,
			width: drawerWidth
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		},
		listItem: {
			'& span': {
				fontSize: 16,
				lineHeight: '20px',
				color: '#A4A6B3'
			},
			'& p': {
				color: '#A4A6B3'
			}
		},
		listItemSvg: {
			'& svg': {
				color: getRGBAFromHex(SYSTEM_COLORS.GRAY, 0.4)
			}
		},
		active: {
			backgroundColor: getRGBAFromHex(SYSTEM_COLORS.GRAY, 0.08),
			'& span': {
				color: SYSTEM_COLORS.SECONDARY
			},
			'& p': {
				color: SYSTEM_COLORS.SECONDARY
			}
		},
		activeCustomSvg: {
			'& svg': {
				color: SYSTEM_COLORS.SECONDARY
			}
		},
		h6: {
			color: SYSTEM_COLORS.BLACK_SHADE
		},
		linkItem: {
			textDecoration: 'none'
		}
	};
});

export default Menu;
