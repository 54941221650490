import { useMutation } from '@apollo/client';
import { ButtonBase, DialogContent, DialogTitle, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiCircle, mdiCircleOutline } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';

import { UpdateTeamStoreDiscountByIdDocument } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import DialogActionBar from '../../../../../../blocks/dialogActionBar';
import DialogBase from '../../../../../../blocks/dialogBase';
import DialogTitleHeader from '../../../../../../blocks/dialogTitleHeader';
import InputField from '../../../../../../inputs/inputField';
import P5 from '../../../../../../typography/p5';

interface Props {
	open: boolean;
	storeId: string;
	handleClose(success?: boolean): void;
	discountType?: 'percent' | 'dollar' | 'none';
	discountValue?: string;
}

export interface DiscountOption {
	type: 'percent' | 'dollar' | 'none';
	value: number;
}

const AddDiscount = (props: Props): ReactElement => {
	const classes = useStyles();
	const [selected, setSelected] = useState<'percent' | 'dollar' | 'none'>(props.discountType || 'none');
	const [value, setValue] = useState(props.discountValue || '');

	const [submit] = useMutation(UpdateTeamStoreDiscountByIdDocument);

	const handleSave = async () => {
		let savedValue = 0;
		if (selected === 'dollar') {
			savedValue = parseInt(`${parseFloat(value) * 100}`, 10);
		} else if (selected === 'percent') {
			savedValue = parseInt(value, 10);
		}

		await submit({
			variables: {
				id: props.storeId,
				store_discount_rate: savedValue,
				store_discount_type: selected
			}
		});
		props.handleClose();
	};

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader
				title="Apply Discount"
				subText={'Discount will be applied to entire store. Packages will not receive discount, only products.'}
				closeDialog={props.handleClose}
			/>
			<DialogContent style={{ padding: '15px 0' }}>
				<ButtonBase
					onClick={(): void => setSelected('percent')}
					disableRipple
					className={clsx(classes.content, {
						[classes.contentSelected]: selected === 'percent'
					})}
				>
					<SvgIcon>
						<path d={selected === 'percent' ? mdiCircle : mdiCircleOutline} />
					</SvgIcon>
					<Typography variant="body2" className={classes.label}>
						% Discount
					</Typography>
					<InputField
						className={classes.input}
						variant="outlined"
						label="Percent discount in the store."
						fullWidth
						onChange={(e) => {
							setValue(e.target.value);
						}}
					/>
				</ButtonBase>
				<ButtonBase
					onClick={(): void => setSelected('dollar')}
					disableRipple
					className={clsx(classes.content, {
						[classes.contentSelected]: selected === 'dollar'
					})}
				>
					<SvgIcon>
						<path d={selected === 'dollar' ? mdiCircle : mdiCircleOutline} />
					</SvgIcon>
					<Typography variant="body2" className={classes.label}>
						$ Discount
					</Typography>
					<InputField
						className={classes.input}
						variant="outlined"
						label="Dollar value discounted."
						fullWidth
						onChange={(e) => {
							setValue(e.target.value);
						}}
					/>
				</ButtonBase>
				<ButtonBase
					onClick={(): void => setSelected('none')}
					disableRipple
					className={clsx(classes.content, {
						[classes.contentSelected]: selected === 'none'
					})}
				>
					<SvgIcon>
						<path d={selected === 'none' ? mdiCircle : mdiCircleOutline} />
					</SvgIcon>
					<Typography variant="body2" className={classes.label}>
						None
					</Typography>
					<div className={classes.fullFlex}>
						<P5>No discount will be given to this store.</P5>
					</div>
				</ButtonBase>
			</DialogContent>
			<DialogActionBar cancelAction={props.handleClose} saveAction={handleSave} />
		</DialogBase>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		content: {
			width: '100%',
			flex: 1,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '15px 30px',
			borderTop: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			'&:last-of-type': {
				borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`
			}
		},
		contentSelected: {
			backgroundColor: 'rgba(55, 81, 255, .04)',
			'& svg': {
				color: SYSTEM_COLORS.PRIMARY
			}
		},
		input: {},
		fullFlex: { flex: 1 },
		label: {
			minWidth: 140,
			padding: '15px'
		}
	};
});

export default AddDiscount;
