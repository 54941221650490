import { IconButton, SvgIcon, Theme, Tooltip, makeStyles } from '@material-ui/core';
import { mdiEyeOutline, mdiMagnify, mdiPackage, mdiPackageDown, mdiPackageUp, mdiPlus, mdiTshirtCrewOutline } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ContentSection from '../../core/components/blocks/contentSection';
import SecondaryButton from '../../core/components/buttons/secondaryButton';
import SegmentedButtonControl from '../../core/components/buttons/segmentedButtonControl';
import InputField from '../../core/components/inputs/inputField';
import { SYSTEM_COLORS } from '../../core/config/colors';
import CatalogList from './components/catalogList';

interface Props {
	c?: any;
}

const Catalog = (props: Props): ReactElement => {
	const classes = useStyles();
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedOption, setSelectedOption] = useState(0);
	const [publicOnly, setPublicOnly] = useState(false);
	const [isBundle, setIsBundle] = useState(false);
	const [dialogAdd, setDialogAdd] = useState(false);
	const handleClose = (): void => {
		setDialogAdd(false);
	};
	const handleOpen = (): void => {
		setDialogAdd(true);
	};

	const options = useMemo(
		() => [
			{
				startIcon: (
					<SvgIcon className={classes.primaryIcon}>
						<path d={mdiPackageUp} />
					</SvgIcon>
				),
				label: 'Active'
			},
			{
				startIcon: (
					<SvgIcon className={classes.warningIcon}>
						<path d={mdiPackageDown} />
					</SvgIcon>
				),
				label: 'Archived'
			}
		],
		[classes]
	);

	return (
		<ContentSection iconPath={mdiTshirtCrewOutline} iconColor={SYSTEM_COLORS.GRAY_LIGHT} title={`Breakmark Catalog`}>
			<section className={classes.sectionContainer}>
				<div className={classes.headerContent}>
					<div className={classes.withShadow}>
						<SegmentedButtonControl
							buttonContainerClass={classes.segmentedButtonControlContainer}
							buttonsConfig={options}
							customButtonClass={classes.customButton}
							selectedIndex={selectedOption}
							setSelectedIndex={setSelectedOption}
						/>
					</div>
					<div className={clsx(classes.withShadow, classes.noMobile, classes.flexIt)}>
						<Tooltip title="Show bundle items only">
							<IconButton
								onClick={(): void => setIsBundle(!isBundle)}
								className={clsx(classes.iconButton, { [classes.iconButtonActive]: isBundle })}
							>
								<SvgIcon>
									<path d={mdiPackage} />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<Tooltip title="Show public items only">
							<IconButton
								onClick={(): void => setPublicOnly(!publicOnly)}
								className={clsx(classes.iconButton, { [classes.iconButtonActive]: publicOnly })}
							>
								<SvgIcon>
									<path d={mdiEyeOutline} />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<InputField
							variant="outlined"
							InputProps={{
								startAdornment: (
									<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
										<path d={mdiMagnify} />
									</SvgIcon>
								)
							}}
							onChange={(e): void => {
								setSearchQuery(e.target.value || '');
							}}
							placeholder={`Search`}
							inputProps={{ 'aria-label': 'search' }}
						/>
						<SecondaryButton
							startIcon={
								<SvgIcon>
									<path d={mdiPlus} />
								</SvgIcon>
							}
							onClick={handleOpen}
						>
							New Product
						</SecondaryButton>
					</div>
				</div>
				<div>
					{selectedOption === 0 && (
						<CatalogList
							handleClose={handleClose}
							dialogOpen={dialogAdd}
							searchQuery={searchQuery}
							public_only={publicOnly}
							isBundle={isBundle}
						/>
					)}
					{selectedOption === 1 && (
						<CatalogList
							handleClose={handleClose}
							dialogOpen={dialogAdd}
							searchQuery={searchQuery}
							isArchived
							public_only={publicOnly}
							isBundle={isBundle}
						/>
					)}
				</div>
			</section>
		</ContentSection>

		// <Paper>
		// 	<PaperHeaderContainer>
		// 		<Typography variant="h3">All Products</Typography>
		// 		<div>
		// 			<InputField
		// 				variant="outlined"
		// 				InputProps={{
		// 					startAdornment: (
		// 						<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
		// 							<path d={mdiMagnify} />
		// 						</SvgIcon>
		// 					)
		// 				}}
		// 				onChange={(e): void => {
		// 					setSearchQuery(e.target.value || '');
		// 				}}
		// 				placeholder={`Search`}
		// 				inputProps={{ 'aria-label': 'search' }}
		// 			/>
		// 			<PrimaryButton
		// 				onClick={handleOpen}
		// 				startIcon={
		// 					<SvgIcon>
		// 						<path d={mdiPlusCircle} />
		// 					</SvgIcon>
		// 				}
		// 				className={classes.newButton}
		// 			>
		// 				New Product
		// 			</PrimaryButton>
		// 		</div>
		// 	</PaperHeaderContainer>
		// 	<div className={classes.tableContainer}>

		// 	</div>
		// </Paper>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		dialogIcons: {
			color: SYSTEM_COLORS.GRAY
		},
		magnify: {
			marginTop: 2
		},
		//
		sectionContainer: {
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.BACKGROUND,
			padding: 15,
			[theme.breakpoints.down('sm')]: {
				padding: 10
			}
		},
		headerContent: {
			paddingRight: 0,
			paddingBottom: 15,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		noMobile: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		withShadow: {
			boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.03)',
			borderRadius: 16,
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		flexIt: {
			display: 'flex'
		},
		segmentedButtonControlContainer: {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				display: 'flex'
			}
		},
		customButton: {
			margin: 5,
			padding: '5px 12px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				padding: '10px 12px'
			}
		},

		primaryIcon: {
			color: SYSTEM_COLORS.PRIMARY
		},
		warningIcon: {
			color: SYSTEM_COLORS.WARNING
		},
		iconButton: {
			color: SYSTEM_COLORS.GRAY,
			padding: 8,
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.04)'
			}
		},
		iconButtonActive: {
			color: SYSTEM_COLORS.PRIMARY
		}
	};
});

export default Catalog;
